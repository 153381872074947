import React, { Component } from "react";
import UndoRoundedIcon from "@material-ui/icons/UndoRounded";
import "./../css/bookingpage.css";
import DatePicker from "./datepicker";
import TimePicker from "./timepicker";
import NumPad from "./numpad";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import {
  getPublicBookings,
  getPublicPacings,
  getPublicRestaurant,
  getPublicSchedules,
  getPublicTables,
  getPublicStatuses,
  postPublicBooking,
} from "../api/public";

import logo from "../images/restaurants/The-Nut-Tree.jpg";

import FlashOnIcon from "@material-ui/icons/FlashOn";
import { PauseCircleOutlineTwoTone } from "@material-ui/icons";

const slug = window.location.pathname.split("/")[2];

if (slug === "tarantella" || slug === "nicks-restaurant")
  import("./../css/restaurants/tarantella.css");
if (slug === "the-nut-tree") import("./../css/restaurants/the-nut-tree.css");

const clone = require("rfdc")();

// the nut tree
// header: {
//   height: "112px",
//   background: "white",
//   boxShadow: "1px 3px 11px #666",
//   zIndex: "100",
// },
// widget_holder: {
//   backgroundColor: "#202224",
//   color: "#aaa",
//   fontSize: "20px",
//   fontFamily:
//     "Roboto Condensed, Helvetica neue, Helvetica, Arial, sans-serif",
//   fontWeight: "400",
//   boxShadow: "none",
// },
// widget_section: { background: "#f5f5f5" },
// input: {
//   color: "#c2ad6e",
//   border: "2px solid rgb(238, 238, 238)",
//   background: "#202224",
//   font: "20px Open Sans, Helvetica neue, Helvetica, Arial, sans-serif",
//   fontWeight: "bold",
//   boxShadow: "none",
// },
// subtitle: {
//   textTransform: "uppercase",
//   letterSpacing: "4px",
//   fontSize: "36px",
//   fontWeight: "600",
//   color: "rgb(238, 238, 238)",
//   marginBottom: "50px",
// },
// calendarColors: {
//   text: "rgb(238, 238, 238)",
//   accent: "rgb(194, 173, 110)",
//   background: "rgb(32, 34, 36)",
//   cell: "rgb(73,73,73)",
//   cellFaded: "rgb(73 73 73 / 30%)",
// },

// back: { color: "#c2ad6e" },
// option_no: { color: "#c2ad6e" },
// option_yes: { color: "#c2ad6e" },
// submit: { background: "#c2ad6e", borderColor: "#c2ad6e", color: "#202225" },
// nav: (
//   <div className="back" style={this.state.back}>
//     <UndoRoundedIcon />
//   </div>
// ),
// logo: "https://www.thenuttreeworle.co.uk/wp-content/uploads/2018/02/The-Nut-Tree.jpg",

class BookingPage extends Component {
  state = {
    allBookings: [],
    allPacings: [],
    allSchedules: [],
    allTables: [],
    allStatuses: [],
    bookings: [],
    pacings: [],
    schedule: [],
    tables: [],
    statuses: [],
    header: {
      display: "none",
      height: "150px",
      background: "black",
      zIndex: "100",
      borderBottom: "1px solid #e1e1e1",
      padding: "0 50px",
      lineHeight: "150px",
    },
    widget_holder: {
      backgroundColor: "#202224",
      color: "#aaa",
      fontSize: "20px",
      fontFamily:
        "Roboto Condensed, Helvetica neue, Helvetica, Arial, sans-serif",
      fontWeight: "400",
      boxShadow: "none",
    },
    widget_section: { background: "#f5f5f5" },
    input: {
      color: "#c2ad6e",
      border: "2px solid rgb(238, 238, 238)",
      background: "#202224",
      font: "20px Open Sans, Helvetica neue, Helvetica, Arial, sans-serif",
      fontWeight: "bold",
      boxShadow: "none",
    },
    subtitle: {
      textTransform: "uppercase",
      letterSpacing: "4px",
      fontSize: "36px",
      fontWeight: "600",
      color: "rgb(238, 238, 238)",
      marginBottom: "50px",
    },
    calendarColors: {
      text: "rgb(238, 238, 238)",
      accent: "rgb(194, 173, 110)",
      background: "rgb(32, 34, 36)",
      cell: "rgb(73,73,73)",
      cellFaded: "rgb(73 73 73 / 30%)",
    },

    back: { color: "#c2ad6e" },
    nav: (
      <div className="back" style={{ color: "#c2ad6e" }}>
        <UndoRoundedIcon />
      </div>
    ) || (
      <div className="back" style={{ color: "#c2ad6e" }}>
        <a href="http://www.tarantellaristorante.co.uk/">Home</a>
        <a href="http://www.tarantellaristorante.co.uk/menu/">Menu</a>
        <a href="http://www.tarantellaristorante.co.uk/feedback/">Feedback</a>
        <a href="http://www.tarantellaristorante.co.uk/gallery/">Gallery</a>
        <a href="http://www.tarantellaristorante.co.uk/contact/">Contact</a>
        <a href="https://book-gfqg.onrender.com/book/tarantella">Book</a>
      </div>
    ),
    option_no: { color: "#c2ad6e" },
    option_yes: { color: "#c2ad6e" },
    submit: {
      background: "#c2ad6e",
      borderColor: "#c2ad6e",
      color: "#202225",
    },

    logo,
    date_picker: false,
    time_picker: false,
    covers_picker: false,
    date: new Date(new Date().setHours(0, 0, 0, 0)),
    time: 1200,
    otherTime: null,
    hours: 12,
    mins: 0,
    covers: 2,
    stage: 0,
    loaded: false,
    name: "",
    phone: "",
    email: "",
    loading: false,
    errorMessage: "",
  };

  // get rest from id
  // get all schedules, table plans, bookings, pacings from id. No PII.

  componentDidMount = async () => {
    const slug = window.location.pathname.split("/")[2];

    const restaurantId = await getPublicRestaurant(slug);

    const results = await Promise.all([
      getPublicSchedules(restaurantId),
      getPublicTables(restaurantId),
      getPublicBookings(restaurantId),
      getPublicPacings(restaurantId),
      getPublicStatuses(restaurantId),
    ]);

    const allSchedules = results[0];
    const allTables = results[1];
    const allBookings = results[2];
    const allPacings = results[3];
    const allStatuses = results[4];
    console.log(
      "ad",
      allSchedules,
      allTables,
      allBookings,
      allPacings,
      allStatuses
    );
    this.setState(
      {
        restaurantId,
        allSchedules,
        allTables,
        allBookings,
        allPacings,
        allStatuses,
      },
      () => this.setDate()
    );
  };

  setOtherTime = (time) => {
    this.setState({
      stage: this.state.stage + 1,
      otherTime: time,
    });
  };

  onChangeName = (e) => {
    this.setState({ name: e.target.value });
  };
  onChangePhone = (e) => {
    this.setState({ phone: e.target.value });
  };
  onChangeEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  submitBooking = async () => {
    if (this.state.loading) return;

    this.setState({ loading: true });
    let usable_end_time =
      parseInt(this.state.time / 100) * 60 +
      (this.state.time % 100) +
      this.state.turn_time;
    usable_end_time =
      parseInt(usable_end_time / 60) * 100 + (usable_end_time % 60);
    let time;
    this.state.otherTime
      ? (time = this.state.otherTime)
      : (time = this.state.time);

    let booking = {
      restaurant: this.state.restaurantId,
      time,
      phone: this.state.phone,
      email: this.state.email,
      name: this.state.name,
      covers: this.state.covers,
      date: this.state.date,
      turn_time: this.state.turn_time,
      usable_end_time: usable_end_time,
      statusesId: this.state.statuses._id,
      statusId: this.state.statuses.list[0]._id,
      history: [
        {
          statusId: this.state.statuses.list[0]._id,
          date: new Date(),
          phase: 1,
        },
      ],
    };

    const response = await postPublicBooking(booking);
    if (response.status === 200) return this.changeStage(this.state.stage + 1);

    response
      .text()
      .then((errorMessage) => this.setState({ errorMessage, loading: false }));
    return false;
  };

  setDate = (date = new Date().setHours(0, 0, 0, 0)) => {
    this.setSchedule(date);
  };

  setBookings = (date = this.state.date) => {
    const allBookings = clone(this.state.allBookings);
    let bookings = allBookings.filter(
      (booking) => booking.date.valueOf() === date.valueOf()
    );
    const opBookings = this.optimiseTables(clone(bookings));

    this.setState({ bookings: opBookings }, () =>
      this.handleRecalculatePacings()
    );
  };

  setSchedule = (date) => {
    const allSchedules = this.state.allSchedules;
    const availableSchedules = allSchedules.filter(
      (schedule) => schedule.startDate <= date && schedule.lastDate >= date
    );
    let schedule;
    if (availableSchedules.length > 0) {
      schedule = availableSchedules.reduce((saved, next) =>
        next.length < saved.length ? next : saved
      );
    } else {
      schedule = allSchedules[0];
    }
    let day = new Date(date).getDay();
    day = schedule.days.find((d) => d.day === day);

    // const tables = this.state.allTables.find(
    //   (tables) => tables._id === day.tablesIds[0].tablesId
    // );

    const tables = [];

    day.tablesIds.forEach((tablesId) => {
      const obj = this.state.allTables.find(
        (tables) => tables._id === tablesId.tablesId
      );
      obj.time = tablesId.time;
      tables.push(obj);
    });

    console.log("EPE", tables);
    console.log("EPE", day);

    const pacings = this.state.allPacings.find(
      (pacings) => pacings._id === day.pacingsId
    );
    const statuses = this.state.allStatuses.find(
      (statuses) => statuses._id === day.statusesId
    );
    this.setState({ schedule, pacings, tables, statuses }, () =>
      this.setTurnTime()
    );
  };

  setTurnTime = () => {
    const statuses = this.state.statuses;
    let turn_time = statuses.turnTimeTotal.filter(
      (tableSizes) => tableSizes.tableSize <= this.state.covers
    );
    turn_time = turn_time[turn_time.length - 1].time;
    this.setState({ turn_time }, () => this.setBookings());
  };

  optimiseTables = (b = this.state.bookings) => {
    //const clone = require("rfdc")();
    // 1. get all tables for date. Filter out offline tables.
    // 2. get all bookings for date
    // 3. strip all tables from bookings if not manually assigned (booking.table_assigned === false) and must be phase 1.
    // 4. for each booking:
    // 4a. get tables scores from this.scoreTables
    // 4b. assign to highest point value table
    // 1
    let tables = this.state.tables;

    //let tables = this.state.tables.tables;
    //tables = tables.filter((table) => table.online);

    // 2
    const stripBookings = clone(b);
    // 3

    for (let i = 0; stripBookings.length > i; i++) {
      if (!stripBookings[i].table_assigned) {
        stripBookings[i].table = [];
      }
    }

    // 4
    //let tableScores = this.scoreTables(tables, bookings, bookings[1]);

    const assignBookings = clone(stripBookings);

    for (let i = 0; assignBookings[i]; i++) {
      if (assignBookings[i].table.length === 0) {
        assignBookings[i].table.push(
          this.props.onScoreTables(tables, assignBookings, assignBookings[i])
            .name
        );
      }
    }
    return assignBookings;
  };

  handleRecalculatePacings = () => {
    // when this.state.bookings changes, pacings need to recalculate. Perhaps will be backend and become redundant?
    //for every pacing, sum it's bookings`
    // get schedule
    // 1. Grab all schedules that apply to today. Select the one with shortest length. If none, select the default one.

    //build pacings
    // grab the pacingsSchedules [{id, name, services, pacings[]}] using schedule.pacingsSchedulesId

    let pacings = this.state.pacings;
    let bookings = this.state.bookings;
    // set the maxPacing of today as defined in the schedule refactored to the bottom

    //build table plan

    bookings.forEach((booking) => {
      let p = pacings.pacings.find(
        (pacing) =>
          pacing.time === booking.time || pacing.time === booking.time - 15
      );
      p.booked = p.booked + booking.covers;
    });
    this.setState({ pacings, loaded: true });
  };

  autoAvailable = (time = this.state.time) => {
    //function is copy+pasted from addbooking.jsx - first section is modifying it to work on the booking page

    let turn_time = this.state.turn_time;
    turn_time = { mins: turn_time % 60, hours: parseInt(turn_time / 60) };
    // Is there pacing limit?
    const pacings = this.state.pacings.pacings;
    if (!pacings) return;
    const pacing = pacings.find(
      (pacing) => pacing.time === time || pacing.time === time - 15
    );

    if (!pacing) {
      return { score: -9999, value: "closed" };
    }

    const converted_time = parseInt(time / 100) * 60 + (time % 100);
    let usable_end_time =
      converted_time + turn_time.mins + turn_time.hours * 60;

    usable_end_time =
      parseInt(usable_end_time / 60) * 100 + (usable_end_time % 60);

    // bookings needs an _id or it will get filtered out by onScoreTables. Just set to anything invalid.

    let booking = {
      covers: this.state.covers,
      time: time,
      usable_end_time: usable_end_time,
      _id: 1,
    };

    let day = new Date(this.state.date).getDay();
    day = this.state.schedule.days.find((d) => d.day === day);

    const tablesIds = day.tablesIds;
    let tables = [];
    console.log("here", tablesIds, this.state.allTables);
    tablesIds.forEach((obj) => {
      let useTables = this.state.allTables.find(
        (tables) => tables._id === obj.tablesId
      ).tables;
      useTables = useTables.filter(
        (table) => table.online === true && table.covers < this.state.covers + 2
      );
      tables.push({ time: obj.time, tables: useTables });
    });

    let best_table = this.props.onScoreTables(
      tables,
      this.state.bookings,
      booking
    );
    //returns best_table.name & best_table.score

    if (best_table === undefined) {
      return { score: -9999, value: "table" };
    }
    if (pacing.booked + this.state.covers > pacing.max) {
      return { score: -9999, value: "pacing" };
    }

    if (best_table.score > 0) {
      return { score: best_table.score, value: "ideal" };
    }

    return { score: best_table.score, value: "available" };
  };

  createTimeChanger = () => {
    // copy pasted from addbooking.jsx stripped all formatting to just return array of objects

    const time = this.state.time;

    const time_mins = parseInt(time / 100) * 60 + (time % 100);

    let times = [-90, -75, -60, -45, -30, -15, 0, 15, 30, 45, 60, 75, 90];
    let detailed_times = [];

    let i = 0;
    while (i < times.length) {
      let new_time = times[i] + time_mins;

      new_time = parseInt(new_time / 60) * 100 + (new_time % 60);

      const score_value = this.autoAvailable(new_time);
      if (!score_value) return;
      let row_css = "time-row";
      score_value.score >= 0 && (row_css += " available");
      score_value.score > 0 && (row_css += " ideal");
      score_value.value === "available" &&
        score_value.score < 0 &&
        (row_css += " available bad-option");

      detailed_times.push({
        difference: times[i],
        time: new_time,
        score: score_value.score,
        value: score_value.value,
        css: row_css,
      });

      i++;
    }

    return detailed_times;
  };
  setTime = (hours, mins) => {
    this.setState({ time: hours * 100 + mins, hours, mins });
  };
  changeStage = (stage) => {
    this.setState({ stage });
  };
  changeBookingDate = (date) => {
    this.setState({ date, date_picker: false, otherTime: null }, () =>
      this.setDate(date)
    );
  };
  changeBookingTime = (time) => {
    this.setState({ time, time_picker: false, otherTime: null });
  };
  changeBookingCovers = (covers) => {
    this.setState({ covers, covers_picker: false });
  };

  showDatePicker = () => {
    this.setState({ date_picker: true });
  };
  showTimePicker = () => {
    this.setState({ time_picker: true });
  };
  showCoversPicker = () => {
    this.setState({ covers_picker: true });
  };
  render() {
    if (this.state.loaded === false) return <div>{console.log("loading")}</div>;
    const hours = [];
    this.state.pacings.pacings.forEach((pacing) => {
      const newHour = parseInt(pacing.time / 100);
      !hours.find((hour) => hour === newHour) && hours.push(newHour);
    });
    const mins = [0, 15, 30, 45];
    const check_availability = (
      <div className="widget-holder" style={this.state.widget_holder}>
        <div className="subtitle" style={this.state.subtitle}>
          Booking
        </div>
        <div className="date">
          When would you like to join us?
          <input
            style={this.state.input}
            value={this.state.date.toDateString()}
            onClick={this.showDatePicker}
          ></input>
        </div>
        <div className="time">
          At what time?
          <div
            className="eui-time-picker"
            style={this.state.input}
            onClick={() => this.setState({ selectTime: true })}
          >
            <div className="hour">
              <p>{this.state.hours}</p>
            </div>
            <div className="min">
              <p>{this.state.mins === 0 ? ":00" : ":" + this.state.mins}</p>
            </div>
            <div
              className="selector-wrapper"
              style={
                this.state.selectTime
                  ? { display: "flex" }
                  : { display: "none" }
              }
            >
              <div className="selector">
                {hours.map((hour) => (
                  <div
                    className={
                      this.state.hours === hour ? "selected" : "available"
                    }
                    key={hour}
                    onClick={(e) => {
                      e.stopPropagation();
                      this.setTime(hour, this.state.mins);
                    }}
                  >
                    {hour}
                  </div>
                ))}
              </div>
              <div className="selector min" id="selector-min">
                {mins.map((min) => (
                  <div
                    className={
                      this.state.mins === min ? "selected" : "available"
                    }
                    key={min}
                    onClick={(e) => {
                      e.stopPropagation();
                      this.setTime(this.state.hours, min);
                    }}
                  >
                    {min === 0 ? "00" : min}
                  </div>
                ))}
              </div>
              <div
                className="done"
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({ selectTime: false });
                }}
              >
                Done
              </div>
            </div>
          </div>
          <div
            className="blackout"
            style={
              this.state.selectTime
                ? { display: "unset", zIndex: "90" }
                : { display: "none", zIndex: "90" }
            }
            onClick={(e) => {
              e.stopPropagation();
              this.setState({ selectTime: false });
            }}
          ></div>
        </div>
        <div className="covers">
          And for how many people?
          <input
            style={this.state.input}
            defaultValue="2"
            type="number"
            min="1"
            max="100"
            onChange={(e) =>
              this.setState({ covers: parseInt(e.target.value) })
            }
            onSelect={(e) => (e.target.value = "")}
            onBlur={(e) => e.target.value === "" && (e.target.value = 2)}
          ></input>
        </div>
        <div className="button-holder">
          <div
            className="submit-bp"
            style={this.state.submit}
            onClick={() => this.changeStage(this.state.stage + 1)}
          >
            Next
          </div>
        </div>
      </div>
    );

    const confirmation = (
      <div className="widget-holder" style={this.state.widget_holder}>
        <div className="subtitle" style={this.state.subtitle}>
          Confirmed
        </div>
        <div className="options">
          <p>You're all booked in!</p>
          <p>
            Please do check your email for confirmation and guidance on how you
            can modify your booking.
          </p>
          <p>We look forward to seeing you soon.</p>
          <p></p>
        </div>
        <div className="button-holder">
          <div className="submit-bp" style={this.state.submit}>
            Chris' Restaurant
          </div>
        </div>
      </div>
    );

    let stages = [0, 1, 2, 3];
    let auto_available = this.autoAvailable();
    let time_changer = this.createTimeChanger();
    if (!time_changer) return "Loading";
    //console.log("tc", time_changer);
    //console.log("av", auto_available);
    let tc_ideal = time_changer.filter((time) => time.value === "ideal");
    let tc_available = time_changer.filter(
      (time) => time.value === "available"
    );
    let next = [];
    let prev = [];
    let nextOption = "";
    let prevOption = "";
    if (auto_available.value === "ideal") {
      // if the requested booking is ideal
      stages = [check_availability, "booking_details", confirmation];
    } else if (
      auto_available.value === "available" &&
      tc_ideal.length > 0 &&
      tc_ideal.filter((time) => time.difference * time.difference <= 3600)
        .length > 0
    ) {
      // if requested booking is available but there IS better options LESS THAN OR EQUAL TO 60 minutes
      stages = [
        check_availability,
        "availability_options",
        "booking_details",
        confirmation,
      ];
      next = tc_ideal.filter((option) => option.time > this.state.time);
      prev = tc_ideal.filter((option) => option.time < this.state.time);

      if (prev) prev = prev[prev.length - 1];
      if (next) next = next[0];
    } else if (auto_available.value === "available") {
      // by elimination, if booking is available and there are no better options
      stages = [check_availability, "booking_details", confirmation];
    } else if (
      auto_available.value !== "ideal" &&
      auto_available.value !== "available" &&
      (tc_ideal.length > 0 || tc_available.length > 0)
    ) {
      // if booking is unavailable but there are alternatives
      stages = [
        check_availability,
        "availability_options",
        "booking_details",
        confirmation,
      ];

      if (tc_ideal.length > 0) {
        next = tc_ideal.filter((option) => option.time > this.state.time);
        prev = tc_ideal.filter((option) => option.time < this.state.time);
      }
      if (tc_available.length > 0) {
        if (next.length === 0)
          next = tc_available.filter((option) => option.time > this.state.time);
        if (prev.length === 0)
          prev = tc_available.filter((option) => option.time < this.state.time);
      }
      if (prev.length > 0) prev = prev[prev.length - 1];
      if (next.length > 0) next = next[0];
    } else {
      //nothing to offer, phone them
      //booking details if current time is unavailable prompts them to call the restaurant.
      stages = [check_availability, "booking_details"];
    }
    if (next && next.time) {
      nextOption = (
        <div
          className="option-yes"
          style={this.state.option_yes}
          onClick={() => this.setOtherTime(next.time)}
        >
          Yes, {next.time}
        </div>
      );
    }
    if (prev && prev.time) {
      prevOption = (
        <div
          className="option-yes"
          style={this.state.option_yes}
          onClick={() => this.setOtherTime(prev.time)}
        >
          Yes, {prev.time}
        </div>
      );
    }

    const availability_options = (
      <div className="widget-holder" style={this.state.widget_holder}>
        <div className="subtitle" style={this.state.subtitle}>
          Choices
        </div>
        <div className="options">
          We're looking a little busy at {this.state.time}. Could you come at
          another time?
          {prevOption}
          {nextOption}
        </div>
        <div
          className="option-no"
          style={this.state.option_no}
          onClick={() => this.changeStage(this.state.stage + 1)}
        >
          No, I need {this.state.time} <ArrowRightAltIcon />
        </div>
        <div className="button-holder">
          <div
            className="submit-bp back"
            style={this.state.submit}
            onClick={() => this.changeStage(this.state.stage - 1)}
          >
            Back
          </div>
        </div>
      </div>
    );
    let booking_details = (
      <div className="widget-holder" style={this.state.widget_holder}>
        <div className="subtitle message" style={this.state.subtitle}>
          Your Details
        </div>
        <div className="errorMessage">{this.state.errorMessage}</div>
        <div className="name">
          Name
          <input
            style={this.state.input}
            value={this.state.name}
            onChange={(e) => this.onChangeName(e)}
          ></input>
        </div>
        <div className="phone">
          Phone number
          <input
            style={this.state.input}
            value={this.state.phone}
            onChange={(e) => this.onChangePhone(e)}
          ></input>
        </div>
        <div className="email">
          Email
          <input
            style={this.state.input}
            value={this.state.email}
            onChange={(e) => this.onChangeEmail(e)}
          ></input>
        </div>
        <div className="button-holder">
          <div
            className="submit-bp back"
            style={this.state.submit}
            onClick={() => this.changeStage(this.state.stage - 1)}
          >
            Back
          </div>
          <div
            className="submit-bp"
            style={this.state.submit}
            onClick={() => this.submitBooking()}
          >
            {this.state.loading ? "Booking..." : "Book now"}
          </div>
        </div>
      </div>
    );
    if (
      auto_available.value !== "ideal" &&
      auto_available.value !== "available" &&
      this.state.otherTime === null
    ) {
      booking_details = (
        <div className="widget-holder" style={this.state.widget_holder}>
          <div className="subtitle" style={this.state.subtitle}>
            Give us a call!
          </div>
          <div className="message">
            <i>*beep boop*</i>
            <br />
            <FlashOnIcon />
            <br /> Unfortunately I can't book you in at{" "}
            {this.state.otherTime ? this.state.otherTime : this.state.time}, but
            I'm just a bot. We may well have space, so give us a call and talk
            to a human.
            <a href="tel:01934620701">01934 620701</a>
          </div>
          <div className="button-holder">
            <div
              className="submit-bp back"
              style={this.state.submit}
              onClick={() => this.changeStage(this.state.stage - 1)}
            >
              Back
            </div>
          </div>
        </div>
      );
    }
    for (let i = 0; stages.length > i; i++) {
      stages[i] === "availability_options" &&
        (stages[i] = availability_options);

      stages[i] === "booking_details" && (stages[i] = booking_details);
    }

    const widget_window_width = 440 * stages.length + "px";
    const scroll = -440 * this.state.stage + "px";
    return (
      <div className="booking-page-wrapper">
        <div className="header" style={this.state.header}>
          <div className="logo">
            <img
              src={this.state.logo}
              alt="logo"
              style={{ maxHeight: this.state.header.height }}
            />
          </div>
          {this.state.nav}
        </div>
        <div className="widget-section" style={this.state.widget_section}>
          <div className="widget-window-holder">
            <div
              className="widget-window"
              style={{ width: widget_window_width, marginLeft: scroll }}
            >
              {stages.map((stage) => stage)}
            </div>
          </div>
        </div>
        <div className="footer">footer</div>
        {this.state.date_picker && (
          <DatePicker
            selectedDate={this.state.date}
            onNewDate={this.changeBookingDate}
            style={this.state.calendarColors}
          />
        )}
        {this.state.time_picker && (
          <TimePicker
            time={this.state.time}
            onSubmit={this.changeBookingTime}
          />
        )}
        {this.state.covers_picker && (
          <NumPad
            maxDigits={2}
            number={this.state.covers}
            onNumber={this.changeBookingCovers}
          />
        )}
      </div>
    );
  }
}

export default BookingPage;
