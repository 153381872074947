import React, { Component } from "react";

import UndoIcon from "@material-ui/icons/Undo";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

class StatusSelector extends Component {
  state = {};

  deleteEvent = (e) => {
    e.stopPropagation();
  };

  undoStatus = (booking) => {
    let history = booking.history;
    history = history.filter((status) => status.phase < booking.phase);
    this.props.onUndoStatus(history[history.length - 1].statusId, booking._id);
  };

  statusChange = (newStatus) => {
    this.props.onStatusChange(newStatus);
    this.props.onToggleStatusSelector();
  };
  render() {
    const booking = this.props.state.allBookings.find(
      (booking) => booking._id === this.props.state.targetBooking
    );

    const statusList = this.props.state.statuses.find(
      (statuses) => statuses._id === booking.statusesId
    ).list;

    let phaseOneButtons = statusList
      .filter((status) => status.phase === 1)
      .map((status) => (
        <div className="status-row">
          <div
            key={status._id}
            className={
              booking.statusId === status._id
                ? "status-button selected"
                : "status-button"
            }
            onClick={() => this.props.onChangeStatus(status._id)}
          >
            {status.icon}
          </div>
          <div className="status-name">{status.name}</div>
        </div>
      ));
    if (booking.phase === 2) {
      phaseOneButtons = (
        <div
          className="seat-button"
          onClick={(e) => {
            e.stopPropagation();
            this.undoStatus(booking);
          }}
        >
          Unseat <UndoIcon />
        </div>
      );
    }

    phaseOneButtons = (
      <div className="status-button-row">{phaseOneButtons}</div>
    );

    if (booking.phase === 3) phaseOneButtons = "";

    let phaseTwoButtons = statusList
      .filter((status) => status.phase === 2 || status.name === "left")
      .map((status) => (
        <div className="status-row">
          <div
            key={status._id}
            className={
              booking.statusId === status._id
                ? "status-button selected"
                : "status-button"
            }
            onClick={() => this.props.onChangeStatus(status._id)}
          >
            {status.icon}
          </div>
          <div className="status-name">{status.name}</div>
        </div>
      ));

    if (booking.phase === 1) {
      phaseTwoButtons = (
        <div
          className="seat-button"
          onClick={(e) => {
            e.stopPropagation();
            this.props.onToggleSeatBooking(booking._id);
          }}
        >
          Seat <ArrowRightAltIcon />
        </div>
      );
    }

    if (booking.phase === 3) {
      phaseTwoButtons = (
        <div
          className="seat-button"
          onClick={(e) => {
            e.stopPropagation();
            this.undoStatus(booking);
          }}
        >
          Return <UndoIcon />
        </div>
      );
    }

    phaseTwoButtons = (
      <div className="status-button-row">{phaseTwoButtons}</div>
    );

    let phaseThreeButtons = statusList
      .filter(
        (status) =>
          status.phase === 3 && (status.name !== "left" || booking.phase === 3)
      )
      .map((status) => (
        <div className="status-row">
          <div
            key={status._id}
            className={
              booking.statusId === status._id
                ? "status-button selected"
                : "status-button"
            }
            onClick={() => this.props.onChangeStatus(status._id)}
          >
            {status.icon}
          </div>
          <div className="status-name">{status.name}</div>
        </div>
      ));

    phaseThreeButtons = (
      <div className="status-button-row">{phaseThreeButtons}</div>
    );

    if (booking.phase === 2) {
      phaseThreeButtons = "";
    }

    return (
      <div className="blackout" onClick={this.props.onToggleStatusSelector}>
        <div className="window-bg status-selector" onClick={this.deleteEvent}>
          <div className="status-button-container">
            {phaseOneButtons}
            {phaseTwoButtons}
            {phaseThreeButtons}
          </div>
        </div>
      </div>
    );
  }
}

export default StatusSelector;
