import React from "react";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import AttachMoneyOutlinedIcon from "@material-ui/icons/AttachMoneyOutlined";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import Check from "@material-ui/icons/Check";

async function getAllStatuses(token) {
  const requestOptions = {
    method: "GET",
    headers: { "x-auth-token": token },
  };

  let data = await fetch(
    "https://evening-eyrie-53360.herokuapp.com/api/statuses",
    requestOptions
  );

  data = JSON.parse(await data.text());

  for (let i = 0; i < data.length; i++) {
    for (let x = 0; x < data[i].list.length; x++) {
      data[i].list[x].icon === "<ImportContactsIcon />" &&
        (data[i].list[x].icon = <ImportContactsIcon />);
      data[i].list[x].icon === "<MenuBookIcon />" &&
        (data[i].list[x].icon = <MenuBookIcon />);
      data[i].list[x].icon === "<PersonPinIcon />" &&
        (data[i].list[x].icon = <PersonPinIcon />);
      data[i].list[x].icon === "<ReceiptOutlinedIcon />" &&
        (data[i].list[x].icon = <ReceiptOutlinedIcon />);
      data[i].list[x].icon === "<AttachMoneyOutlinedIcon />" &&
        (data[i].list[x].icon = <AttachMoneyOutlinedIcon />);
      data[i].list[x].icon === "<ClearRoundedIcon />" &&
        (data[i].list[x].icon = <ClearRoundedIcon />);
      data[i].list[x].icon === "<Check />" &&
        (data[i].list[x].icon = <Check />);
    }
  }
  return data;
}

const _getAllStatuses = getAllStatuses;
export { _getAllStatuses as getAllStatuses };

// statuses: [
//   {
//     _id: 1,
//     name: "default",
//     useAdvancedTurns: true,
//     turnTimeTotal: [
//       { tableSize: 1, time: 90 },
//       { tableSize: 6, time: 120 },
//       { tableSize: 10, time: 180 },
//     ],
//     list: [
//       {
//         _id: 1,
//         phase: 1,
//         active: 1,
//         name: "booked",
//         icon: <ImportContactsIcon />,
