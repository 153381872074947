import React, { Component } from 'react';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import CreateIcon from '@material-ui/icons/Create';
import StatusButton from './statusbutton';
import NotesIcon from '@material-ui/icons/Notes';
const clone = require('rfdc')();

class TableBookings extends Component {
  state = { turn_time: 'default' };

  deleteEvent = (e) => {
    e.stopPropagation();
  };

  handleDisplayTurntime = (start, end) => {
    //start end = 1200 1330 output 1hr 30min
    const start_hours = parseInt(start / 100);
    const end_hours = parseInt(end / 100);

    const end_mins = end % 100;
    const start_mins = start % 100;

    const turn_mins =
      end_hours * 60 + end_mins - (start_hours * 60 + start_mins);

    const output_mins = turn_mins % 60 === 0 ? '' : (turn_mins % 60) + 'm';

    const output = (
      <div className="string-time">
        {parseInt(turn_mins / 60) + 'hr ' + output_mins}
      </div>
    );
    return output;
  };

  render() {
    let bookings = this.props.state.bookings
      .filter(
        (booking) =>
          new Set(booking.table.concat(this.props.table)).size !==
            booking.table.concat(this.props.table).length && booking.phase !== 3
      )
      .sort((a, b) => (a.time > b.time ? 1 : -1));

    let expanded_booking_id = this.props.booking_id;

    let expanded_booking = '';
    let expanded_booking_view = '';
    let this_tables_bookings_view = (
      <div className="no-bookings">No bookings on {this.props.table}</div>
    );

    if (bookings.length > 0) {
      if (!bookings.find((booking) => booking._id === expanded_booking_id)) {
        expanded_booking_id = bookings[0]._id;
      }

      expanded_booking = bookings.find(
        (booking) => booking._id === expanded_booking_id
      );

      let turn_time = 'default';
      if (expanded_booking.projected_end_time < expanded_booking.end_time) {
        turn_time = 'smart';
      }
      if (expanded_booking.manual_end_time !== false) {
        turn_time = 'manual';
      }

      let notes = expanded_booking.description;

      var dateFormat = require('dateformat');
      const expanded_history = expanded_booking.history.map((status) => (
        <div key={status._id}>
          <span className="label-text">
            {
              this.props.state.statuses
                .find(
                  (globalStatuses) =>
                    globalStatuses._id === expanded_booking.statusesId
                )
                .list.find(
                  (globalStatus) => globalStatus._id === status.statusId
                ).icon
            }
          </span>
          {dateFormat(status.date, 'mmm d, HH:MM')}
          <b style={{ marginLeft: 5 }}>{expanded_booking.initials}</b>
        </div>
      ));
      const tags = expanded_booking.tags.map((tagIds) => (
        <div className="detail tag" key={tagIds}>
          <div
            className="circle"
            style={{
              backgroundColor: this.props.state.tags.find(
                (tag) => tag._id === tagIds
              ).color,
            }}
          ></div>
          {this.props.state.tags.find((tag) => tag._id === tagIds).text}
        </div>
      ));
      expanded_booking_view = (
        <div className="expanded-booking-view">
          <div className="details">
            <div className="detail">
              <PhoneIcon />
              {expanded_booking.phone}
            </div>
            <div className="detail">
              <EmailIcon />
              {expanded_booking.email}
            </div>

            <div className="detail">
              <NotesIcon />
              {notes}
            </div>
            <div className="tags">{tags}</div>
            <div className="turn-options">
              <div
                style={{ display: 'none' }}
                className={
                  turn_time === 'default' ? 'default selected' : 'default'
                }
                onClick={
                  turn_time === 'manual'
                    ? () =>
                        this.props.onToggleManualTurnTime(expanded_booking._id)
                    : null
                }
              >
                Default
                {this.handleDisplayTurntime(
                  expanded_booking.time,
                  expanded_booking.end_time
                )}
                {expanded_booking.end_time}
              </div>
              <div
                style={{ display: 'none' }}
                className={turn_time === 'smart' ? 'smart selected' : 'smart'}
                onClick={
                  turn_time === 'manual'
                    ? () =>
                        this.props.onToggleManualTurnTime(expanded_booking._id)
                    : null
                }
              >
                Smart
                {this.handleDisplayTurntime(
                  expanded_booking.time,
                  expanded_booking.projected_end_time
                )}
                {expanded_booking.projected_end_time}
              </div>
              <div
                className={
                  turn_time === 'manual' ? 'manual selected' : 'manual'
                }
                onClick={
                  turn_time !== 'manual'
                    ? () => {
                        return null;
                        this.props.onToggleManualTurnTime(expanded_booking._id);
                      }
                    : null
                }
              >
                Turn-time
                {expanded_booking.manual_end_time
                  ? this.handleDisplayTurntime(
                      expanded_booking.time,
                      expanded_booking.manual_end_time
                    )
                  : this.handleDisplayTurntime(
                      expanded_booking.time,
                      expanded_booking.end_time
                    )}
                {expanded_booking.manual_end_time
                  ? expanded_booking.manual_end_time
                  : expanded_booking.end_time}
              </div>
              <div className="plus-minus">
                <div
                  onClick={() =>
                    this.props.onAdjustManualTurnTime(expanded_booking._id, 15)
                  }
                >
                  +15min
                </div>
                <div
                  onClick={() =>
                    this.props.onAdjustManualTurnTime(expanded_booking._id, -15)
                  }
                >
                  -15min
                </div>
              </div>
            </div>
          </div>
          <div className="history-wrapper">
            <div className="history">
              <h3>History</h3>
              {expanded_history}
            </div>
          </div>
        </div>
      );
      this_tables_bookings_view = bookings.map((booking) => (
        <div
          className={
            booking._id === expanded_booking_id
              ? 'tablebookings-booking-row selected'
              : 'tablebookings-booking-row'
          }
          key={booking._id}
        >
          <div
            className="tablebookings-booking"
            onClick={() =>
              this.props.onChangeTarget(this.props.table, booking._id)
            }
          >
            <div className="tablebookings-time">
              {booking.time} x {booking.covers}
            </div>
            <div className="tablebookings-name">{booking.name}</div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                this.props.onTableViewState(booking._id);
              }}
              className={
                booking.table_assigned
                  ? 'tablebookings-table manual'
                  : 'tablebookings-table'
              }
            >
              {booking.table.join(' ')}
            </div>
            <StatusButton
              bookingId={booking._id}
              onChangeStatus={this.props.onChangeStatus}
              state={this.props.state}
            />
            <div
              className="status-button"
              onClick={() => {
                this.props.onEditBooking(clone(booking));
                this.props.onToggleTableBookingView();
              }}
            >
              <CreateIcon />
            </div>
          </div>
          {booking._id === expanded_booking_id && expanded_booking_view}
        </div>
      ));
    }
    //<div className="tablebookings-status">
    //  {
    //    this.props.state.statuses
    //      .find(statuses => statuses._id === booking.statusesId)
    //      .list.find(status => status._id === booking.statusId).icon
    //  }
    //</div>;
    return (
      <div className="blackout" onClick={this.props.onToggleTableBookingView}>
        <div className="window-bg table-bookings" onClick={this.deleteEvent}>
          <div className="tablebookings-container">
            <div className="tables-title">
              Showing all bookings on {this.props.table.join(', ')}
            </div>
            {this_tables_bookings_view}
          </div>
        </div>
      </div>
    );
  }
}

export default TableBookings;
