import React, { Component } from "react";
class TableSelectControls extends Component {
  state = {};

  componentWillUnmount() {
    this.props.onClearSelectedTables();
  }
  componentWillMount() {
    let fullTargetBooking = this.props.fullTargetBooking;

    if (this.props.state.addBooking) {
      fullTargetBooking = {
        table_assigned: true,
        table: [],
        covers: this.props.state.newBooking.covers,
        time: this.props.state.newBooking.time,
      };
    }

    let selectedButton = fullTargetBooking.table_assigned ? "Manual" : "Auto";
    let persistentButton = "Manual";

    if (fullTargetBooking.table.length > 1) {
      selectedButton = "Multi";
      persistentButton = "Multi";
    }

    const setSelectedTables = fullTargetBooking.table;

    const schedule = this.props.onGetSchedule(this.state.displayDate);
    const display_day = this.props.state.displayDate.getDay();
    let tablesIds = schedule.days.find(
      (day) => day.day === display_day
    ).tablesIds;

    let tablesId = tablesIds.filter(
      (obj) => obj.time <= fullTargetBooking.time
    );
    tablesId = tablesId[tablesId.length - 1].tablesId;

    const tables = this.props.state.tablesSchedules.find(
      (tables) => tables._id === tablesId
    ).tables;

    setSelectedTables.forEach(
      (selectedTable) =>
        tables.find((table) => table.name === selectedTable) === undefined &&
        (selectedButton = "Custom") &&
        (persistentButton = "Custom")
    );

    this.props.onInitializeTableSelectButtons(
      selectedButton,
      persistentButton,
      fullTargetBooking,
      setSelectedTables
    );
  }

  deleteEvent = (e) => {
    e.stopPropagation();
  };
  render() {
    let fullTargetBooking = this.props.fullTargetBooking;
    if (this.props.state.addBooking) {
      fullTargetBooking = {
        table_assigned: true,
        table: [],
        covers: this.props.state.newBooking.covers,
        time: this.props.state.newBooking.time,
        name: "New Booking",
      };
    }

    let selectedTablesLabel =
      this.props.state.selectedTables.length === 0
        ? "Auto"
        : this.props.state.selectedTables.join(", ");

    if (this.props.state.persistentButton === "Custom") {
      selectedTablesLabel === "Auto" && (selectedTablesLabel = "");
      selectedTablesLabel = (
        <input
          value={selectedTablesLabel}
          onChange={this.props.onCustomChange}
        ></input>
      );
    }

    return (
      <div className="tables-top-panel" onClick={this.deleteEvent}>
        <span className="panel-booking">
          Where would you like to seat&nbsp;<b>{fullTargetBooking.name}</b>
          ,&nbsp;
          <b>{fullTargetBooking.covers}</b>&nbsp;@&nbsp;
          <b>{fullTargetBooking.time}</b>?
        </span>
        <div className="panel-buttons">
          <div
            className={
              this.props.state.selectedButton === "Auto" ? "selected" : ""
            }
            onClick={() => this.props.onChangePersistentButton("Auto")}
          >
            Auto
          </div>
          <div
            className={
              this.props.state.selectedButton === "Manual" ? "selected" : ""
            }
            onClick={() => this.props.onChangePersistentButton("Manual")}
          >
            Manual
          </div>
          <div
            className={
              this.props.state.selectedButton === "Multi" ? "selected" : ""
            }
            onClick={() => this.props.onChangePersistentButton("Multi")}
          >
            Multi
          </div>
          <div
            className={
              this.props.state.selectedButton === "Custom" ? "selected" : ""
            }
            onClick={() => this.props.onChangePersistentButton("Custom")}
          >
            Custom
          </div>
          <div className="save">
            <span className="table-list">{selectedTablesLabel}</span>
            <span className="submit" onClick={this.props.onBookingTable}>
              Save
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default TableSelectControls;
