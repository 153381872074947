import React, { Component } from "react";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";

class Calendar extends Component {
  state = {
    date: new Date(),
    // display is a list (the month) of lists (the weeks) of objects (the days). Days id = numerical day of year.
    display: [],
  };

  componentDidMount() {
    let today = new Date();
    today = new Date(today.setHours(0, 0, 0, 0));
    this.buildCalendar(today);
  }

  buildCalendar = (givenDate) => {
    //givenDate is mandatory and should be a *new Date(date.sethours(0,0,0,0)*)

    let date = new Date(givenDate);

    //grab the month
    const month = date.getMonth();
    // get the first & lastday of the month
    let firstDayOfMonth = new Date(date);
    firstDayOfMonth = new Date(
      firstDayOfMonth.getFullYear(),
      firstDayOfMonth.getMonth(),
      1
    );
    let lastDayOfMonth = new Date(date);
    lastDayOfMonth = new Date(
      lastDayOfMonth.getFullYear(),
      lastDayOfMonth.getMonth() + 1,
      0
    );
    //get the day of the week that is the first day of the month (0-6), and grab the Monday from that week. This Monday is the startDate of the calendar.
    let firstDayOfWeek = new Date(firstDayOfMonth);
    firstDayOfWeek = firstDayOfWeek.getDay();

    let stepsBack = firstDayOfWeek;
    stepsBack === 0 && (stepsBack = 7);

    let startDate = new Date(firstDayOfMonth);
    startDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      2 - stepsBack
    );
    // create an array (the month to display) of arrays (the weeks) of objects (the days).
    const allDates = [];
    let currentDate = new Date(startDate);
    // x is the id of the individual calendar dates to display. Will be used as the key. i is to make lists of 7 days.
    let x = 0;
    // while we havent reached the end of the month, create a new week for the calendar to display
    while (currentDate < lastDayOfMonth) {
      let i = 0;
      let newWeek = [];
      //while we havent made 7 days, create day objects. id, date, differentMonth & today (for styling). To add optionally: schedules (all); or schedule & covers by service (break lunch dinner)
      while (i < 7) {
        let differentMonth = false;
        if (currentDate.getMonth() !== month) {
          differentMonth = true;
        }
        let today = false;
        let todayDate = new Date();
        todayDate = new Date(todayDate.setHours(0, 0, 0, 0));
        if (currentDate.valueOf() === todayDate.valueOf()) {
          today = true;
        }
        //grab schedule info for the day (first/last for styling)
        const schedule = this.props.onGetSchedule(currentDate);
        const scheduleName = schedule.name;
        let scheduleFirst = false;
        // schedule.startDate && returns false if it's null - stops it erroring, and will never be a start date
        if (
          schedule.startDate &&
          currentDate.valueOf() === schedule.startDate.valueOf()
        ) {
          scheduleFirst = true;
        }
        let scheduleLast = false;
        if (
          schedule.lastDate &&
          currentDate.valueOf() === schedule.lastDate.valueOf()
        ) {
          scheduleLast = true;
        }
        // cant use currentDate as the object stores a reference to the date, which is incremented. At the end all dates will be the same.
        let bookingDate = new Date(currentDate);
        const day = {
          id: x,
          date: bookingDate,
          differentMonth,
          today,
          scheduleName,
          scheduleFirst,
          scheduleLast,
        };
        newWeek.push(day);
        currentDate.setDate(currentDate.getDate() + 1);
        i++;
        x++;
      }
      allDates.push(newWeek);
    }
    this.setState({ display: allDates, date });
  };
  deleteEvent = (e) => {
    e.stopPropagation();
  };
  forwardMonth = () => {
    //grab current date and add a month. Must change current date to first day of the month first or 2 months could get jumped (30 Jan +1 month = 30 Feb = 2 March)
    let date = new Date(this.state.date);
    date = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    this.setState({ date });
    this.buildCalendar(date);
  };
  backMonth = () => {
    //grab current date and add a month. Must change current date to first day of the month first or 2 months could get jumped (30 Jan +1 month = 30 Feb = 2 March)
    let date = new Date(this.state.date);
    date = new Date(
      this.state.date.getFullYear(),
      this.state.date.getMonth() - 1,
      1
    );
    this.setState({ date });
    this.buildCalendar(date);
  };
  dateChange = (day) => {
    this.props.onDateChange(day);
    this.props.onToggleCalendar();
  };
  render() {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let overlayClasses = "calendar-overlay blackout ";
    overlayClasses += this.props.state.calendar === 0 && "display-none";

    return (
      <div className={overlayClasses} onClick={this.props.onToggleCalendar}>
        <div className="window-bg" onClick={this.deleteEvent}>
          <table>
            <thead>
              <tr>
                <th colSpan="7">
                  <h2>
                    <ArrowBackIosRoundedIcon onClick={this.backMonth} />
                  </h2>
                  <h2 className="h2-month">
                    {monthNames[this.state.date.getMonth()]}
                    &nbsp;{this.state.date.getFullYear()}
                  </h2>
                  <h2>
                    <ArrowForwardIosRoundedIcon onClick={this.forwardMonth} />
                  </h2>
                </th>
              </tr>
              <tr>
                <th>Mon</th>
                <th>Tue</th>
                <th>Wed</th>
                <th>Thu</th>
                <th>Fri</th>
                <th>Sat</th>
                <th>Sun</th>
              </tr>
            </thead>
            <tbody>
              {/* Iterate through weeks for rows, then days for cells */}
              {this.state.display.map((week) => (
                <tr key={week[0].id}>
                  {week.map((day) => (
                    <td
                      key={day.id}
                      className={
                        (day.differentMonth ? "different-month " : "") +
                        (day.today ? "today" : "")
                      }
                      onClick={this.dateChange.bind(null, day.date)}
                    >
                      <h3>{new Date(day.date).getDate()}</h3>
                      <span
                        className={
                          (day.scheduleFirst ? "schedule-first " : "") +
                          (day.scheduleLast ? "schedule-last" : "") +
                          (day.scheduleName === "Default" ? "display-none" : "")
                        }
                      >
                        {day.scheduleName}
                      </span>
                      <p>
                        {this.props
                          .onGetCoversPerService(day.date)
                          .map((service) => (
                            <React.Fragment key={service.name}>
                              {service.name.substring(0, 1)}{" "}
                              {service.covers === 0 ? "-" : service.covers}{" "}
                              <br />
                            </React.Fragment>
                          ))}
                      </p>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Calendar;
