import React, { Component } from "react";
import DatePicker from "./datepicker";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";

const clone = require("rfdc")();

class EditSchedule extends Component {
  constructor(props) {
    super(props);
    let state = {
      display: [],
      days: null,
      date: null,
      name: "",
      start: null,
      last: null,
      showStartDatePicker: false,
      showLastDatePicker: false,
      selectNewPacings: null,
      selectNewTables: null,
      selectAllPacings: false,
      selectAllTables: false,
      greySave: "schedule-new-button grey-out",
    };

    const statusesId = this.props.state.statuses[0]._id;
    if (this.props.schedule._id === "new") {
      Object.assign(state, {
        _id: "new",
        name: "Schedule Name",
        start: null,
        last: null,
        length: null,
        days: [
          { day: 1, tablesIds: [], pacingsId: null, statusesId },
          { day: 2, tablesIds: [], pacingsId: null, statusesId },
          { day: 3, tablesIds: [], pacingsId: null, statusesId },
          { day: 4, tablesIds: [], pacingsId: null, statusesId },
          { day: 5, tablesIds: [], pacingsId: null, statusesId },
          { day: 6, tablesIds: [], pacingsId: null, statusesId },
          { day: 0, tablesIds: [], pacingsId: null, statusesId },
        ],
      });
    } else if (this.props.schedule._id === "duplicate") {
      let copyOfSchedule = clone(this.props.schedule);
      copyOfSchedule.name = "Copy of " + copyOfSchedule.name;
      let amends = this.setSchedule(copyOfSchedule);
      Object.assign(state, amends);
    } else {
      let amends = this.setSchedule(this.props.schedule);
      Object.assign(state, amends);
    }
    this.state = state;
  }
  componentDidUpdate = () => {
    if (this.state.greySave !== "schedule-new-button") {
      this.setState({ greySave: "schedule-new-button" });
    }
  };

  preparePacingChange = (day) => {
    this.setState({ selectNewPacings: day });
  };
  prepareTablesChange = (day) => {
    this.setState({ selectNewTables: day });
  };
  setSchedule = (schedule) => {
    return {
      days: schedule.days,
      _id: schedule._id,
      name: schedule.name,
      start: schedule.startDate,
      last: schedule.lastDate,
    };
  };
  //<DatePicker selectedDate={new Date()} />
  handlePacingChange = (pacing, e) => {
    let newDays = clone(this.state.days);
    newDays.find((x) => x.day === this.state.selectNewPacings).pacingsId =
      pacing;
    this.setState({ days: newDays, selectNewPacings: null });
    e.stopPropagation();
  };
  prepareAllTablesChange = () => {
    this.setState({ selectAllTables: true });
  };
  prepareAllPacingsChange = () => {
    this.setState({ selectAllPacings: true });
  };
  handleTablesChange = (tables, e) => {
    let newDays = clone(this.state.days);
    newDays.find((x) => x.day === this.state.selectNewTables).tablesIds = [
      { time: 0, tablesId: tables },
    ];
    this.setState({ days: newDays, selectNewTables: null });
    e.stopPropagation();
  };
  handleSetAllTables = (tables, e) => {
    let newDays = clone(this.state.days);
    newDays[0].tablesIds =
      newDays[1].tablesIds =
      newDays[2].tablesIds =
      newDays[3].tablesIds =
      newDays[4].tablesIds =
      newDays[5].tablesIds =
      newDays[6].tablesIds =
        [{ time: 0, tablesId: tables }];
    this.setState({ days: newDays, selectAllTables: false });
    e.stopPropagation();
  };
  handleSetAllPacings = (pacings, e) => {
    let newDays = clone(this.state.days);
    newDays[0].pacingsId =
      newDays[1].pacingsId =
      newDays[2].pacingsId =
      newDays[3].pacingsId =
      newDays[4].pacingsId =
      newDays[5].pacingsId =
      newDays[6].pacingsId =
        pacings;
    this.setState({ days: newDays, selectAllPacings: false });
    e.stopPropagation();
  };
  handleNameChange = (e) => {
    this.setState({ name: e.target.value });
  };
  handleStartChange = (newDate, e) => {
    this.setState({ start: newDate, showStartDatePicker: false });
    if (newDate > this.state.last) {
      this.setState({ last: newDate });
    }
    e.stopPropagation();
  };
  handleLastChange = (newDate, e) => {
    this.setState({ last: newDate, showLastDatePicker: false });
    if (newDate < this.state.start) {
      this.setState({ start: newDate });
    }
    e.stopPropagation();
  };

  handleSubmit = () => {
    return null;
  };
  handleShowStartDatePicker = () => {
    if (this.state.showStartDatePicker) {
      this.setState({ showStartDatePicker: false });
    } else {
      this.setState({ showStartDatePicker: true });
    }
  };
  handleShowLastDatePicker = () => {
    if (this.state.showLastDatePicker) {
      this.setState({ showLastDatePicker: false });
    } else {
      this.setState({ showLastDatePicker: true });
    }
  };

  dateChange = (day) => {
    this.props.onDateChange(day);
    this.props.onToggleCalendar();
  };

  render() {
    let length = null;
    if (this.state.start !== null) {
      length =
        (this.state.last.getTime() -
          this.state.start.getTime() +
          1000 * 60 * 60 * 24) /
        (1000 * 60 * 60 * 24);
    }
    const newSchedule = {
      _id: this.state._id,
      name: this.state.name,
      startDate: this.state.start,
      lastDate: this.state.last,
      length: length,
      days: this.state.days,
    };
    let startDatePicker = "";
    if (this.state.showStartDatePicker) {
      startDatePicker = (
        <DatePicker
          selectedDate={this.state.start}
          onNewDate={this.handleStartChange}
        />
      );
    }
    let lastDatePicker = "";
    if (this.state.showLastDatePicker) {
      lastDatePicker = (
        <DatePicker
          selectedDate={this.state.last}
          onNewDate={this.handleLastChange}
        />
      );
    }
    const dateFormat = require("dateformat");
    const pacingSelector = ["", "", "", "", "", "", ""];
    if (this.state.selectNewPacings !== null) {
      pacingSelector[this.state.selectNewPacings] = (
        <React.Fragment>
          <div className="blackout"></div>
          <div className="select-dropdown">
            {this.props.state.pacingsSchedules.map((pacing) => (
              <h3 onClick={this.handlePacingChange.bind(this, pacing._id)}>
                {pacing.name}
              </h3>
            ))}
          </div>
        </React.Fragment>
      );
    }

    const tablesSelector = ["", "", "", "", "", "", ""];
    if (this.state.selectNewTables !== null) {
      tablesSelector[this.state.selectNewTables] = (
        <React.Fragment>
          <div className="blackout"></div>
          <div className="select-dropdown right">
            {this.props.state.tablesSchedules.map((tables) => (
              <h3 onClick={this.handleTablesChange.bind(this, tables._id)}>
                {tables.name}
              </h3>
            ))}
          </div>
        </React.Fragment>
      );
    }

    let setAllPacings = "";
    let setAllTables = "";
    if (this.state.selectAllPacings) {
      setAllPacings = (
        <React.Fragment>
          <div className="blackout"></div>
          <div className="select-dropdown">
            {this.props.state.pacingsSchedules.map((pacings) => (
              <h3 onClick={this.handleSetAllPacings.bind(this, pacings._id)}>
                {pacings.name}
              </h3>
            ))}
          </div>
        </React.Fragment>
      );
    }
    if (this.state.selectAllTables) {
      setAllTables = (
        <React.Fragment>
          <div className="blackout"></div>
          <div className="select-dropdown right">
            {this.props.state.tablesSchedules.map((tables) => (
              <h3 onClick={this.handleSetAllTables.bind(this, tables._id)}>
                {tables.name}
              </h3>
            ))}
          </div>
        </React.Fragment>
      );
    }

    const order = [1, 2, 3, 4, 5, 6, 0];
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    let pacingsAndTablePlans = [
      <div className="double-row labeled">
        <p></p>
        <input
          type="text"
          value="All Hours"
          onClick={this.prepareAllPacingsChange}
          readOnly
        />
        {setAllPacings}
        <input
          type="text"
          value="All Tables"
          onClick={this.prepareAllTablesChange}
          readOnly
        />
        {setAllTables}
      </div>,
    ];
    if (this.state.days !== null) {
      pacingsAndTablePlans[1] = order.map((day) => (
        <div className="double-row labeled">
          <p>{days[day]}</p>
          <input
            type="text"
            value={
              this.state.days.find((x) => x.day === day).pacingsId
                ? this.props.state.pacingsSchedules.find(
                    (x) =>
                      x._id ===
                      this.state.days.find((x) => x.day === day).pacingsId
                  ).name
                : "select hours"
            }
            onClick={this.preparePacingChange.bind(null, day)}
            readOnly
          />
          {pacingSelector[day]}
          <input
            type="text"
            value={
              this.state.days.find((x) => x.day === day).tablesIds.length === 1
                ? this.props.state.tablesSchedules.find(
                    (x) =>
                      x._id ===
                      this.state.days.find((x) => x.day === day).tablesIds[0]
                        .tablesId
                  ).name
                : this.state.days.find((x) => x.day === day).tablesIds.length >
                  1
                ? "Advanced"
                : "select table plan"
            }
            onClick={this.prepareTablesChange.bind(null, day)}
            readOnly
          />
          {tablesSelector[day]}
        </div>
      ));
    } else {
      pacingsAndTablePlans[1] = order.map((day) => (
        <div className="double-row labeled">
          <p>{days[day]}</p>
          <input
            type="text"
            onClick={this.preparePacingChange.bind(null, day)}
            readOnly
          />
          {pacingSelector[day]}
          <input
            type="text"
            onClick={this.prepareTablesChange.bind(null, day)}
            readOnly
          />
          {tablesSelector[day]}
        </div>
      ));
    }

    return (
      <div className="edit-schedule">
        {startDatePicker}
        {lastDatePicker}
        <div className="options-title">Edit Schedule</div>
        <div>
          <div
            className={this.state.greySave}
            onClick={this.props.onSaveScheduleEdit.bind(null, newSchedule)}
          >
            <CheckCircleRoundedIcon />
            Save
          </div>
          <div className="schedule-new-button" onClick={this.props.onCancel}>
            <CancelRoundedIcon />
            Cancel
          </div>
        </div>
        <form onSubmit={this.handleSubmit}>
          <input
            type="text"
            value={this.state.name}
            onChange={this.handleNameChange}
          />
          <div className="double-row">
            <input
              type="text"
              value={
                this.state.start
                  ? dateFormat(this.state.start, "dd mmm yy")
                  : "start date"
              }
              onClick={this.handleShowStartDatePicker}
              readOnly
            />
            <input
              type="text"
              value={
                this.state.last
                  ? dateFormat(this.state.last, "dd mmm yy")
                  : "last day"
              }
              onClick={this.handleShowLastDatePicker}
              readOnly
            />
          </div>
          <div className="edit-schedule-pt">{pacingsAndTablePlans}</div>
        </form>
      </div>
    );
  }
}

export default EditSchedule;
