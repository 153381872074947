import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import NumPad from './numpad';
import TimePicker from './timepicker';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import BookingDetails from './bookingdetails';

const clone = require('rfdc')();
class AddBooking extends Component {
  state = {
    show_numpad: false,
    show_timepicker: false,
    turn_time: { hours: 1, mins: 30 },
    schedule_day: null,
    show_time_changer: false,
    focus: '',

    quick_overwrite: false,
    time_overwrite: false,

    best_auto: -9999,
    best_quick: -9999,
    best_time: -9999,
    part: 1,
    booking: {
      covers: false,
      time: false,
      table: false,
      name: '',
      phone: '',
      email: '',
      tags: [],
      description: '',
      default_turntime: true,
      statusesId: 0,
      statusId: null,
      phase: 1,
      statusesDefault: true,
      status_changed: null,
      table_assigned: null,
      table: [],
      history: [{ statusId: 1, date: new Date(), phase: 1 }],
      // **** turntime: 90,
      // **** end_time: 1400,
      // **** projected_end_time: 1400,
      // **** usable_end_time: 1415,
      // **** manual_end_time: 1415,
    },
  };

  // let actual_turntime = booking.turntime;

  // booking.date = this.state.displayDate;

  // let statuses = this.state.statuses.find(
  //   (statuses) => statuses._id === booking.statusesId
  // );

  // booking.statusId = statuses.list[0]._id;
  // // booking.turntime = this.state.statuses.find(
  // //   (statuses) => statuses._id === booking.statusesId
  // // ).turnTimeTotal;
  // booking.turntime = statuses.turnTimeTotal.filter(
  //   (turnTimeTotal) => turnTimeTotal.tableSize <= booking.covers
  // );
  // booking.turntime = booking.turntime[booking.turntime.length - 1].time;

  // /*
  //     turnTimeTotal: [
  //       { tableSize: 1, time: 90 },
  //       { tableSize: 6, time: 120 },
  //       { tableSize: 10, time: 180 }
  //     ],*/

  // let default_end_time =
  //   parseInt(booking.time / 100) * 60 +
  //   (booking.time % 100) +
  //   booking.turntime;
  // default_end_time =
  //   parseInt(default_end_time / 60) * 100 + (default_end_time % 60);

  // booking.end_time = default_end_time;
  // booking.projected_end_time = default_end_time;
  // booking.usable_end_time = default_end_time;

  // if (booking.manual_end_time) {
  //   actual_turntime = actual_turntime.hours * 60 + actual_turntime.mins;

  //   let manual_end_time =
  //     parseInt(booking.time / 100) * 60 +
  //     (booking.time % 100) +
  //     actual_turntime;
  //   manual_end_time =
  //     parseInt(manual_end_time / 60) * 100 + (manual_end_time % 60);

  //   booking.usable_end_time = manual_end_time;

  //   booking.manual_end_time = manual_end_time;
  // }

  // booking.history = [{ statusId: booking.statusId, date: new Date() }];

  /*
  a booking needs to be an object like this: (everything passed to onAddBooking except **** items are calculated by the function)
  {
    **** id: 1,
    time: 1200,
    table: ["T8", "T6"],
    phone: "07875292131",
    email: "christopherhasalongemailaddress@gmail.com",
    name: "Laura1",
    covers: 6,
    **** date: "",
    default_turntime: true,
    **** turntime: 90,
    **** end_time: 1400,
    **** projected_end_time: 1400,
    **** usable_end_time: 1415,
    **** manual_end_time: 1415,
    table_assigned: true,
    statusesId: 1,
    statusId: 1,
    phase: 1,
    statusesDefault: true,
    status_changed: false,
    description:
      "Can I grab a table by the window? It's my birthday. I'm allergic to fish.",
    tags: [1, 2],
    **** history: [{ statusId: 1, date: new Date() - 1 }]
  }*/

  deleteEvent = (e) => {
    e.stopPropagation();
  };

  componentWillMount = () => {
    const date = this.props.state.displayDate;
    const schedule = this.props.onGetSchedule(date);
    const schedule_day = schedule.days.find((day) => day.day === date.getDay());
    let booking = this.state.booking;
    booking.statusesId = schedule_day.statusesId;
    booking.date = date;
    if (this.props.state.editBooking) booking = this.props.state.editBooking;
    this.setState({ schedule_day, booking });
  };

  componentDidMount = () => {
    if (!this.props.state.editBooking) return;
    const booking = this.props.state.editBooking;
    const turn_time = {
      hours: parseInt(booking.turntime / 60),
      mins: booking.turntime % 60,
    };
    this.setState({ booking, turn_time });
  };

  handleQuickOverwrite = (turn_time) => {
    let quick_overwrite = true;
    turn_time = { hours: turn_time.hours, mins: turn_time.mins };
    const booking = this.state.booking;
    booking.turntime = turn_time.hours * 60 + turn_time.mins;
    booking.default_turntime = false;
    booking.manual_end_time =
      booking.time + turn_time.hours * 100 + turn_time.mins;
    booking.usable_end_time = booking.manual_end_time;
    this.setState({ turn_time, quick_overwrite, booking });
  };
  handleTimeOverwrite = (time) => {
    let time_overwrite = true;
    const booking = this.state.booking;
    booking.time = time;
    const turn_time = this.state.turn_time;
    const end_time = booking.time + turn_time.hours * 100 + turn_time.mins;

    booking.end_time = end_time;
    booking.projected_end_time = end_time;
    booking.usable_end_time = end_time;
    booking.manual_end_time = false;

    this.setState({ booking, time_overwrite, show_time_changer: false });
  };

  toggleTimeChanger = () => {
    let show_time_changer = true;
    if (this.state.show_time_changer) {
      show_time_changer = false;
    }
    this.setState({ show_time_changer });
  };
  handleSelectAuto = () => {
    const booking = this.state.booking;
    booking.table_assigned = false;
    this.setState({ booking });
  };
  handleSelectManual = (table) => {
    this.props.onTableViewState('new');
    const booking = this.state.booking;
    booking.table_assigned = true;
    this.setState({ booking });
  };
  handleCovers = (covers) => {
    const booking = this.state.booking;
    booking.covers = covers;
    booking.default_turntime = true;
    const date = this.props.state.displayDate;

    const schedule_day = this.props
      .onGetSchedule(date)
      .days.find((day) => day.day === date.getDay());

    let statuses = this.props.state.statuses.find(
      (status) => status._id === schedule_day.statusesId
    );

    booking.statusId = statuses.list[0]._id;
    if (!this.props.state.editBooking)
      booking.history[0].statusId = statuses.list[0]._id;

    let turn_time = statuses.turnTimeTotal
      .filter((total) => total.tableSize <= covers)
      .sort((a, b) => (a.tableSize > b.tableSize ? -1 : 1))[0].time;

    booking.turntime = turn_time;
    turn_time = { hours: parseInt(turn_time / 60), mins: turn_time % 60 };

    // duplicated logic on covers and time select

    const end_time = booking.time + turn_time.hours * 100 + turn_time.mins;

    booking.end_time = end_time;
    booking.projected_end_time = end_time;
    booking.usable_end_time = end_time;
    booking.manual_end_time = false;

    // let statuses = this.props.state.statuses.find(
    //   (statuses) => statuses._id === booking.statusesId
    // );

    // booking.statusId = statuses.list[0]._id;
    // // booking.turntime = this.state.statuses.find(
    // //   (statuses) => statuses._id === booking.statusesId
    // // ).turnTimeTotal;
    // booking.turntime = statuses.turnTimeTotal.filter(
    //   (turnTimeTotal) => turnTimeTotal.tableSize <= booking.covers
    // );
    // booking.turntime = booking.turntime[booking.turntime.length - 1].time;

    // /*
    //     turnTimeTotal: [
    //       { tableSize: 1, time: 90 },
    //       { tableSize: 6, time: 120 },
    //       { tableSize: 10, time: 180 }
    //     ],*/

    // let default_end_time =
    //   parseInt(booking.time / 100) * 60 +
    //   (booking.time % 100) +
    //   booking.turntime;
    // default_end_time =
    //   parseInt(default_end_time / 60) * 100 + (default_end_time % 60);

    // booking.end_time = default_end_time;
    // booking.projected_end_time = default_end_time;
    // booking.usable_end_time = default_end_time;

    let newBooking = this.props.state.newBooking;
    newBooking.covers = covers;
    this.props.onNewBookingDetails(newBooking);

    this.setState({
      booking,
      show_numpad: false,
      turn_time,
      schedule_day,
      quick_overwrite: false,
    });
  };
  handleNumpad = () => {
    this.setState({ show_numpad: true });
  };
  handleTime = (time) => {
    let newBooking = this.props.state.newBooking;
    newBooking.time = time;
    this.props.onNewBookingDetails(newBooking);

    const booking = this.state.booking;
    booking.time = time;
    const turn_time = this.state.turn_time;
    let end_time = booking.time + turn_time.hours * 100 + turn_time.mins;

    end_time = parseInt(end_time / 100) * 60 + (end_time % 100);
    end_time = parseInt(end_time / 60) * 100 + (end_time % 60);

    booking.end_time = end_time;
    booking.projected_end_time = end_time;
    booking.usable_end_time = end_time;
    booking.manual_end_time = false;

    this.setState({ booking, show_timepicker: false, time_overwrite: false });
  };
  handleTimepicker = () => {
    this.setState({ show_timepicker: true });
  };

  quickAvailable = (auto_score) => {
    let quick_times = [];
    let current_mins =
      this.state.turn_time.hours * 60 + this.state.turn_time.mins;

    while (current_mins > 30) {
      current_mins = current_mins - 15;
      const next_best_turn_time = {
        hours: parseInt(current_mins / 60),
        mins: current_mins % 60,
      };
      quick_times.push(next_best_turn_time);
    }

    quick_times.forEach((turn_time) => {
      turn_time.score = this.autoAvailable(
        turn_time,
        this.state.booking.time
      ).score;
    });

    const best_quick = Math.max(...quick_times.map((o) => o.score));

    let best = quick_times.find((time) => time.score > 0);
    if (best && best_quick > auto_score) {
      best.css = 'highlight';
      return best;
    }
    let good = quick_times.find((time) => time.score === 0);
    if (good && best_quick > auto_score) {
      good.css = '';
      return good;
    }
    return { hours: 0, mins: 0, css: 'grey' };
  };

  autoAvailable = (
    turn_time = this.state.turn_time,
    time = this.state.booking.time
  ) => {
    // Is there pacing limit?
    const pacings = clone(this.props.state.pacings);

    let pacing = pacings.find(
      (pacing) => pacing.time === time || pacing.time === time - 15
    );

    const pacingOverrides = this.props.state.pacingOverrides;
    if (pacingOverrides)
      pacingOverrides.forEach((po) => {
        try {
          pacings.find((pacing) => pacing.time === po.time).max = po.max;
        } catch (e) {
          console.log(e);
        }
      });

    if (!pacing) {
      return { score: -9999, value: 'closed' };
    }

    const converted_time = parseInt(time / 100) * 60 + (time % 100);
    let usable_end_time =
      converted_time + turn_time.mins + turn_time.hours * 60;

    usable_end_time =
      parseInt(usable_end_time / 60) * 100 + (usable_end_time % 60);

    let booking = clone(this.state.booking);
    booking.usable_end_time = usable_end_time;
    booking.time = time;
    const tablesIds = this.state.schedule_day.tablesIds;

    let tables = [];

    tablesIds.forEach((obj) => {
      const useTables = this.props.state.tablesSchedules
        .find((tables) => tables._id === obj.tablesId)
        .tables.filter(
          (table) =>
            table.online === true &&
            table.covers < this.state.booking.covers + 2
        );
      tables.push({ time: obj.time, tables: useTables });
    });

    let best_table = this.props.onScoreTables(
      tables,
      this.props.state.bookings,
      booking
    );

    //returns best_table.name & best_table.score
    if (best_table === undefined) {
      return { score: -9999, value: 'table' };
    }
    if (pacing.booked + this.state.booking.covers > pacing.max) {
      return { score: -9999, value: 'pacing' };
    }

    if (best_table.score > 0) {
      return { score: best_table.score, value: <CheckCircleIcon /> };
    }

    return { score: best_table.score, value: 'available' };
  };

  togglePart = () => {
    let part = 1;
    if (this.state.part === 1) {
      part = 2;
    }
    this.setState({ part });
  };

  toggleTag = (tag) => {
    const booking = this.state.booking;
    let tags = booking.tags;
    if (tags.includes(tag)) {
      tags = tags.filter((i) => i !== tag);
    } else {
      tags.push(tag);
    }
    booking.tags = tags;
    this.setState({ booking });
  };

  setFocus = (focus) => {
    this.setState({ focus });
  };

  changeName = (e) => {
    const booking = this.state.booking;
    booking.name = e.target.value;
    this.setState({ booking });
  };

  changePhone = (e) => {
    const booking = this.state.booking;
    booking.phone = e.target.value;
    this.setState({ booking });
  };

  changeNotes = (e) => {
    const booking = this.state.booking;
    booking.description = e.target.value;
    this.setState({ booking });
  };

  changeEmail = (e) => {
    const booking = this.state.booking;
    booking.email = e.target.value;
    this.setState({ booking });
  };

  createTimeChanger = () => {
    const time = this.state.booking.time;

    const time_mins = parseInt(time / 100) * 60 + (time % 100);

    let times = [-90, -75, -60, -45, -30, -15, 0, 15, 30, 45, 60, 75, 90];
    let detailed_times = [];

    let i = 0;
    while (i < times.length) {
      let new_time = times[i] + time_mins;

      new_time = parseInt(new_time / 60) * 100 + (new_time % 60);

      const score_value = this.autoAvailable(this.state.turn_time, new_time);

      let row_css = 'time-row';
      score_value.score >= 0 && (row_css += ' available');
      score_value.score > 0 && (row_css += ' recommended');
      score_value.value === 'available' &&
        score_value.score < 0 &&
        (row_css += ' available bad-option');

      detailed_times.push({
        difference: times[i],
        time: new_time,
        score: score_value.score,
        value: score_value.value,
        css: row_css,
      });

      i++;
    }

    const divs = detailed_times.map((time) => (
      <div
        key={time.time}
        className={time.css || undefined}
        onClick={() => this.handleTimeOverwrite(time.time)}
      >
        <div className="difference">
          {time.difference > 0 && '+' + time.difference}
          {time.difference < 0 && time.difference}
        </div>
        <div className="time">{time.time}</div>
        <div className="result">
          {time.value !== 'available' && time.value}
          {time.row_css === 'time-row available warning' && <WarningIcon />}
        </div>
      </div>
    ));

    let visible = 'none';
    if (this.state.show_time_changer) {
      visible = 'flex';
    }

    const ui = (
      <div
        className="blackout"
        style={{ display: visible }}
        onClick={this.toggleTimeChanger}
      >
        <div className="window-bg time-changer" onClick={this.deleteEvent}>
          <div className="time-options-container">{divs}</div>
        </div>
      </div>
    );
    const best_time = detailed_times.sort((a, b) =>
      a.score > b.score ? -1 : 1
    )[0];
    let result = { ui: ui, best_score: best_time };

    return result;
  };

  createBookingDetails = () => {
    let name_value = this.state.booking.name;
    if (name_value === '' && this.state.focus !== 'name') {
      name_value = 'Name*';
    }

    let phone_value = this.state.booking.phone;
    if (phone_value === '' && this.state.focus !== 'phone') {
      phone_value = 'Phone Number*';
    }

    let email_value = this.state.booking.email;
    if (email_value === '' && this.state.focus !== 'email') {
      email_value = 'Email Address';
    }

    let notes_value = this.state.booking.description;
    if (notes_value === '' && this.state.focus !== 'notes') {
      notes_value = 'Additional Notes';
    }

    let tags = [];
    this.props.state.tags.forEach((tag) =>
      tags.push({
        tag: tag,
        selected: this.state.booking.tags.includes(tag._id) ? true : false,
      })
    );

    tags = tags.map((tag) => (
      <div
        className={tag.selected ? 'detail tag' : 'detail tag deselected'}
        key={tag.tag._id}
        onClick={() => this.toggleTag(tag.tag._id)}
      >
        <div
          className="circle"
          style={{
            backgroundColor: tag.tag.color,
          }}
        ></div>
        {tag.tag.text}
      </div>
    ));

    this.state.booking.table_assigned === true &&
      (this.state.booking.table = this.props.state.newBooking.table);

    this.state.booking.table_assigned === true &&
      this.props.state.tablesViewState[0] === 'tablescontainer' &&
      this.state.booking.table.length === 0 &&
      (this.state.booking.table_assigned = false);

    let booking_submit = {
      time: this.state.booking.time,
      table: this.props.state.newBooking.table,
      phone: this.state.booking.phone,
      email: this.state.booking.email,
      name: this.state.booking.name,
      covers: this.state.booking.covers,
      default_turntime: this.state.booking.default_turntime,
      table_assigned: this.state.booking.table_assigned,
      statusesId: this.state.booking.statusesId,
      statusId: this.state.booking.statusId,
      phase: this.state.booking.phase,
      statusesDefault: this.state.booking.statusesDefault,
      status_changed: this.state.booking.status_changed,
      description: this.state.booking.description,
      tags: this.state.booking.tags,
      turntime: this.state.turn_time,
      manual_end_time: this.state.quick_overwrite,
    };

    let details = (
      <React.Fragment>
        <div
          className={
            this.state.booking.name && this.state.booking.phone
              ? 'basics : selected'
              : 'basics'
          }
        >
          <input
            name="name"
            value={name_value}
            onFocus={() => this.setFocus('name')}
            onBlur={() => this.setFocus('')}
            onChange={(name) => this.changeName(name)}
            className={
              this.state.booking.name === '' ? 'mandatory italic' : 'filled'
            }
          ></input>
          <input
            name="phone"
            value={phone_value}
            onFocus={() => this.setFocus('phone')}
            onBlur={() => this.setFocus('')}
            onChange={(phone) => this.changePhone(phone)}
            className={
              this.state.booking.phone === '' ? 'mandatory italic' : 'filled'
            }
          ></input>
          <input
            name="email"
            value={email_value}
            onFocus={() => this.setFocus('email')}
            onBlur={() => this.setFocus('')}
            onChange={(email) => this.changeEmail(email)}
            className={this.state.booking.email === '' ? 'italic' : 'filled'}
          ></input>
        </div>
        <div className="notes selected">
          <textarea
            name="notes"
            value={notes_value}
            onFocus={() => this.setFocus('notes')}
            onBlur={() => this.setFocus('')}
            onChange={(e) => this.changeNotes(e)}
            className={
              this.state.booking.description === '' ? 'italic' : 'filled'
            }
          ></textarea>
        </div>
        <div className="tags selected">{tags}</div>
        <div className="next">
          <div className="summary" onClick={this.togglePart}>
            {this.state.booking.covers} @ {this.state.booking.time}
          </div>
          <div
            className={
              !this.state.booking.name || !this.state.booking.phone
                ? 'grey'
                : undefined
            }
            onClick={() => this.props.onAddBooking(this.state.booking)}
          >
            {this.props.state.editBooking ? 'Save Changes' : 'Add Booking'}
          </div>
        </div>
      </React.Fragment>
    );
    return details;
  };

  render() {
    const modalRoot = document.getElementById('root');
    let covers = <GroupAddIcon />;
    if (this.state.booking.covers > 0) {
      covers = (
        <div className={this.state.quick_overwrite ? 'covers quick' : 'covers'}>
          {this.state.booking.covers}
          <p>
            {this.state.turn_time.hours +
              'hr ' +
              this.state.turn_time.mins +
              'm'}
          </p>
        </div>
      );
    }
    let time = <AccessTimeIcon />;
    if (this.state.booking.time > 0) {
      time = this.state.booking.time;
    }
    let table = false;
    this.state.booking.table_assigned !== null && (table = true);

    let numpad = '';
    if (this.state.show_numpad) {
      numpad = <NumPad maxDigits={2} number={1} onNumber={this.handleCovers} />;
    }

    let timepicker = '';
    if (this.state.show_timepicker) {
      numpad = (
        <TimePicker time={this.state.booking.time} onSubmit={this.handleTime} />
      );
    }

    const auto_label_result = this.autoAvailable();

    let auto_label = '';
    if (
      this.state.booking.covers &&
      this.state.booking.time &&
      auto_label_result.value !== 'available'
    ) {
      auto_label = <div className="label">{auto_label_result.value}</div>;
    }
    let auto_label_class = '';
    if (auto_label_result.score > 0) {
      auto_label_class = 'highlight';
    } else if (auto_label_result.value !== 'available') {
      auto_label_class = 'grey';
    }

    let quick_label = this.quickAvailable(auto_label_result.score);
    quick_label.label = '';

    if (quick_label.css !== 'grey') {
      let hours = '';
      let mins = '';

      quick_label.hours > 0 && (hours = quick_label.hours + 'hr ');
      quick_label.mins > 0 && (mins = quick_label.mins + 'm');

      quick_label.label = <div className="label">{hours + mins}</div>;
    }

    let time_changer = this.createTimeChanger(auto_label_result.score);

    if (time_changer.best_score.score > 0 && auto_label_class !== 'highlight') {
      time_changer.css = 'highlight';
    } else if (
      time_changer.best_score.css.includes('available') &&
      auto_label_class !== ('highlight' || 'available')
    ) {
      time_changer.css = 'available';
    } else {
      time_changer.css = 'grey';
    }

    let add_details = this.createBookingDetails();

    return (
      <React.Fragment>
        {ReactDOM.createPortal(
          <BookingDetails
            togglePart={this.togglePart}
            booking={this.state.booking}
            onAddBooking={this.props.onAddBooking}
            part={this.state.part}
            tags={this.props.state.tags}
            templateInitials={this.props.state.restaurant.initials}
            displayDate={this.props.state.displayDate}
          />,
          modalRoot
        )}
        <div
          className={
            this.state.part === 1 ? 'add-booking-holder' : 'add-booking-holder'
          }
        >
          <div className="wrapper">
            <div
              className={
                this.state.booking.covers ? 'covers selected' : 'covers'
              }
            >
              <div className="content" onClick={this.handleNumpad}>
                {covers}
              </div>
            </div>
            <div className={this.state.booking.time ? 'time selected' : 'time'}>
              <div
                className={
                  this.state.booking.time_overwrite
                    ? 'content overwrite'
                    : 'content'
                }
                onClick={this.handleTimepicker}
              >
                {time}
              </div>
            </div>
            <div
              className={
                this.state.booking.covers && this.state.booking.time
                  ? 'table-options'
                  : 'table-options grey'
              }
            >
              <div className="content">
                <div
                  className={
                    this.state.booking.table_assigned === false
                      ? auto_label_class + ' selected'
                      : auto_label_class
                  }
                  style={{ pointerEvents: 'all' }}
                  onClick={this.handleSelectAuto}
                >
                  Auto{auto_label_result !== 'available' && auto_label}
                </div>
                <div
                  className={
                    this.state.booking.table_assigned === true
                      ? 'manual selected'
                      : 'manual'
                  }
                  onClick={this.handleSelectManual}
                >
                  {this.state.booking.table_assigned &&
                  this.state.booking.table.length > 0
                    ? this.state.booking.table[0] + '+'
                    : 'Manual'}
                </div>
                <div
                  className={'quick ' + quick_label.css}
                  onClick={() => this.handleQuickOverwrite(quick_label)}
                >
                  Quick{quick_label.label}
                </div>
                <div
                  className={'time ' + time_changer.css}
                  onClick={this.toggleTimeChanger}
                >
                  Time
                </div>
              </div>
            </div>
            <div className="next">
              <div
                className={covers && time && table ? undefined : 'grey'}
                onClick={this.togglePart}
              >
                Continue
              </div>
              <div onClick={this.props.onToggleAddBooking}>Cancel</div>
            </div>
          </div>
          <div className="wrapper details">{add_details}</div>
        </div>
        {numpad}
        {timepicker}
        {time_changer.ui}
      </React.Fragment>
    );
  }
}

export default AddBooking;
