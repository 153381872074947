import { FormatColorResetSharp } from '@material-ui/icons';

async function getAllBookings(token) {
  const requestOptions = {
    method: 'GET',
    headers: { 'x-auth-token': token },
  };

  let data = await fetch(
    'https://evening-eyrie-53360.herokuapp.com/api/bookings',
    requestOptions
  );

  let result = JSON.parse(await data.text());

  // convert all the dates into JS Date() objects...

  for (let i = 0; i < result.length; i++) {
    result[i].date = new Date(result[i].date);
  }

  return result;
}

async function postBooking(token, listenerId, booking) {
  try {
    const requestOptions = {
      method: 'POST',
      headers: {
        'x-auth-token': token,
        'Content-Type': 'application/json',
        'x-listener-id': listenerId,
      },
      body: JSON.stringify(booking),
    };
    console.log('ro', requestOptions);
    let data = await fetch(
      'https://evening-eyrie-53360.herokuapp.com/api/bookings',
      requestOptions
    );

    if (!data.ok) {
      data.text().then((res) => {
        console.log(data.status + ' ' + data.statusText + ' ' + res);
      });
      return false;
    }

    let result = JSON.parse(await data.text());

    // convert all the dates into JS Date() objects...

    result.date = new Date(result.date);

    return result;
  } catch (err) {
    console.log(err.message);
    return false;
  }
}

async function putBooking(token, listenerId, booking) {
  try {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'x-auth-token': token,
        'Content-Type': 'application/json',
        'x-listener-id': listenerId,
      },
      body: JSON.stringify(booking),
    };

    let data = await fetch(
      'https://evening-eyrie-53360.herokuapp.com/api/bookings/' + booking.id,
      requestOptions
    );

    if (!data.ok) {
      data.text().then((res) => {
        console.log(data.status + ' ' + data.statusText + ' ' + res);
      });

      console.log('fail');
      return false;
    }

    let result = JSON.parse(await data.text());

    // convert all the dates into JS Date() objects...

    result.date = new Date(result.date);
    console.log('success!');
    return true;
  } catch (err) {
    console.log(err.message);

    console.log('fail');
    return false;
  }
}

const _getAllBookings = getAllBookings;
export { _getAllBookings as getAllBookings };
const _postBooking = postBooking;
export { _postBooking as postBooking };
const _putBooking = putBooking;
export { _putBooking as putBooking };
