import React, { Component } from "react";
import logo from "../SimplyServe.png";
import "../css/login.css";
import { attemptLogin } from "../api/auth";
import { SingleBedSharp } from "@material-ui/icons";

class LoginPage extends Component {
  // state = { email: "Email", password: "Password", errorMessage: "" };
  state = {
    email: "Email",
    password: "Password",
    errorMessage: "",
    loading: false,
  };

  clearEmail = () => {
    if (this.state.email === "Email") this.setState({ email: "" });
  };
  resetEmail = () => {
    if (this.state.email === "") this.setState({ email: "Email" });
  };
  handleNameChange = (e) => {
    this.setState({ email: e.target.value });
  };

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value });
  };
  clearPassword = () => {
    if (this.state.password === "Password") this.setState({ password: "" });
  };
  resetPassword = () => {
    if (this.state.password === "") this.setState({ password: "Password" });
  };
  handleLogIn = async (e) => {
    if (this.state.loading) return;
    e.preventDefault();

    const { email, password } = this.state;
    if (
      email === "" ||
      email === "Email" ||
      password === "" ||
      password === "Password"
    )
      return false;
    this.setState({ loading: true });

    const response = await attemptLogin(this.state.email, this.state.password);

    //it actually feels weird for the loading to popup for under 2 seconds. so put this in for a minimum.
    await new Promise((resolve) => setTimeout(resolve, 1000)).then(() => {
      return;
    });

    if (response.status === 200)
      return response.text().then((jwt) => this.props.onSetJWT(jwt));

    response
      .text()
      .then((errorMessage) => this.setState({ errorMessage, loading: false }));
    return false;
  };

  render() {
    const { email, password } = this.state;
    const displayError = this.state.errorMessage ? (
      <div className="error-message visible">{this.state.errorMessage}</div>
    ) : (
      <div className="error-message">{this.state.errorMessage}</div>
    );

    let loginButtonCss = "clickable";

    (email === "" ||
      email === "Email" ||
      password === "" ||
      password === "Password") &&
      (loginButtonCss = "unclickable");

    this.state.loading && (loginButtonCss = "unclickable loading");

    return (
      <div className="login-wrapper">
        <form onSubmit={this.handleLogIn} id="loginForm" ref="loginForm" autocomplete="on">
          <div className="login-form">
            <img src={logo} alt="SimplyServe Logo"></img>
            {displayError}
            <input
            id="username"
            name="username"
              type="email"
              value={email}
              onSelect={this.clearEmail}
              onBlur={this.resetEmail}
              onChange={this.handleNameChange}
              className={email === "Email" ? "default" : "changed"}
            />
            <input
            id="password"
            name="password"
              value={password}
              onSelect={this.clearPassword}
              onBlur={this.resetPassword}
              onChange={this.handlePasswordChange}
              type="password"
              className={password === "Password" ? "default" : "changed"}
            />
            <button type="submit" className={loginButtonCss}>
              {this.state.loading ? <div className="blob" /> : "Log in"}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default LoginPage;
