async function attemptLogin(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email: email, password: password }),
  };

  return await fetch(
    "https://evening-eyrie-53360.herokuapp.com/api/auth",
    requestOptions
  );
}

async function selectRestaurant(id, token) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", "x-auth-token": token },
  };

  return await fetch(
    "https://evening-eyrie-53360.herokuapp.com/api/auth/" + id,
    requestOptions
  );
}

const _attemptLogin = attemptLogin;
export { _attemptLogin as attemptLogin };
const _selectRestaurant = selectRestaurant;
export { _selectRestaurant as selectRestaurant };
