import React, { Component } from "react";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import TimePicker from "./timepicker";
import NumPad from "./numpad";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";

class EditOpeningHours extends Component {
  state = {
    _id: 1,
    maxpacing: 20,
    start: 1000,
    last: 2230,
    defaultpacing: 12,
    times: [], // [{time: 1200, open: true, pacing: 6}...{}...{}]
    name: "",
    services: [],
    showStartTimePicker: false,
    showLastTimePicker: false,
    showMaxNumPad: false,
  };
  constructor(props) {
    super(props);

    this.focusInput = React.createRef();
    if (this.props._id === "new") {
      //build new one
      const maxpacing = 20;
      const start = 1000;
      const last = 2230;
      const defaultpacing = 12;
      let times = [];
      for (let i = start; i <= last; ) {
        const newTime = { time: i, open: true, pacing: defaultpacing };
        times.push(newTime);
        if (String(i).charAt(2) === "3") {
          i += 70;
        } else {
          i += 30;
        }
      }

      const services = [{ name: "All Day", time: null }];
      this.state = {
        _id: this.props._id,
        maxpacing,
        start,
        last,
        defaultpacing,
        times,
        name: "Name",
        services,
        greySave: "schedule-new-button grey-out",
        showStartTimePicker: false,
        showLastTimePicker: false,
        showMaxNumPad: false,
      };
    } else {
      const statePacing = this.props.state.pacingsSchedules.find(
        (x) => x._id === this.props._id
      );
      /* [{
            id: 2,
            name: "Summer",
            servicesId: 2,
            maxPacing: 12,
            defaultPacing: 12,
            pacings: [
              { time: 1000, max: 8, booked: 0 },
              { time: 1030, max: 8, booked: 0 },
              { time: 1100, max: 8, booked: 0 }
            ]
          }] */
      // const stateServices = this.props.state.servicesPacings.find(
      //   (x) => x._id === this.props._id
      // );
      /*    servicesPacings: [
            {
              id: 1,
              name: "Default",
              services: [{ name: "All Day", startTime: null }]
            }]*/

      let times = []; // {time: 1200, open: true, pacing: 12}
      let start = statePacing.pacings[0].time;
      const last = statePacing.pacings[statePacing.pacings.length - 1].time;
      for (let i = start; i <= last; ) {
        let open = false;
        let pacing = statePacing.defaultPacing;
        if (statePacing.pacings.find((x) => x.time === i)) {
          open = true;
          pacing = statePacing.pacings.find((x) => x.time === i).max;
        }
        times.push({ time: i, open, pacing });
        if (String(i).charAt(String(i).length - 2) === "3") {
          i += 70;
        } else {
          i += 30;
        }
      }

      this.state = {
        _id: this.props._id,
        maxpacing: statePacing.maxPacing,
        start: statePacing.pacings[0].time,
        last: last,
        defaultpacing: statePacing.defaultPacing,
        times,
        name: statePacing.name,
        services: statePacing.services,
        greySave: "schedule-new-button grey-out",
        showStartTimePicker: false,
        showLastTimePicker: false,
        showMaxNumPad: false,
      };
    }
    if (this.props.duplicate) {
      this.state._id = "duplicate";
      this.state.name = "Copy of " + this.state.name;
    }
  }

  componentDidUpdate = () => {
    if (this.state.greySave !== "schedule-new-button") {
      this.setState({ greySave: "schedule-new-button" });
    }
    if (this.focusInput.current) {
      this.focusInput.current.focus();
    }
  };
  onSave = () => {
    /*
    let state = {
      id: 1,
      maxpacing: 20,
      start: 1000,
      last: 2230,
      defaultpacing: 12,
      times: [], // [{time: 1200, open: true, pacing: 6}...{}...{}]
      name: "",
      services: [],
      showStartTimePicker: false,
      showLastTimePicker: false,
      showMaxNumPad: false
    };
    let pacingSchedules = [
      {
        id: 2,
        name: "Summer",
        servicesId: 2,
        maxPacing: 12,
        defaultPacing: 12,
        pacings: [
          { time: 1000, max: 8, booked: 0 },
          { time: 1030, max: 8, booked: 0 },
          { time: 1100, max: 8, booked: 0 }
        ]
      }
    ];
    let servicePacings = [
      {
        id: 2,
        name: "Default",
        services: [{ name: "All Day", startTime: null }]
      }
    ]; */

    let pacings = [];
    for (let i = 0; i < this.state.times.length; i++) {
      if (this.state.times[i].open) {
        const obj = {
          time: this.state.times[i].time,
          max: parseInt(this.state.times[i].pacing),
          booked: 0,
        };
        pacings.push(obj);
      }
    }
    const newPacingsSchedule = {
      _id: this.state._id,
      name: this.state.name,
      services: this.state.services,
      maxPacing: this.state.maxpacing,
      defaultPacing: parseInt(this.state.defaultpacing),
      pacings,
    };
    this.props.onSave(newPacingsSchedule);
  };
  changeSpecificPacing = (time, e) => {
    let times = this.state.times;
    times.find((x) => x.time === time).pacing = e.target.value;
    this.setState({ times });
  };
  changeDefaultPacing = (e) => {
    const oldDefaultPacing = this.state.defaultpacing;
    let times = this.state.times;
    for (let i = 0; i < times.length; i++) {
      if (times[i].pacing === oldDefaultPacing) {
        times[i].pacing = e.target.value;
      }
    }

    this.setState({ defaultpacing: e.target.value, times });
  };
  toggleOpen = (time) => {
    let times = this.state.times;
    if (times.find((x) => x.time === time).open) {
      times.find((x) => x.time === time).open = false;
    } else {
      times.find((x) => x.time === time).open = true;
    }
    this.setState({ times });
  };
  selectMaxPacing = () => {
    this.setState({ showMaxNumPad: true });
  };
  setMaxPacing = (maxpacing) => {
    let defaultpacing = this.state.defaultpacing;
    if (defaultpacing > maxpacing) {
      defaultpacing = maxpacing;
    }
    let times = this.state.times;
    for (let i = 0; i < times.length; i++) {
      times[i].pacing > maxpacing && (times[i].pacing = maxpacing);
    }
    this.setState({ maxpacing, showMaxNumPad: false, defaultpacing, times });
  };

  changeServiceName = (time, e) => {
    const clone = require("rfdc")();
    let services = clone(this.state.services);
    services.find((x) => x.time === time).name = e.target.value;
    this.setState({ services });
  };
  checkServiceNames = () => {
    // null service must have a name. Others without a name are deleted.
    let services = this.state.services;
    if (services.find((x) => x.time === null).name === "") {
      services.find((x) => x.time === null).name = "Default";
    }
    services = services.filter((x) => {
      return x.name !== "";
    });
    this.setState({ services });
  };
  addService = (time) => {
    let services = this.state.services;
    services.push({ name: "", time });

    services = services.sort((a, b) => (a.time > b.time ? 1 : -1));
    this.setState({ services });
  };
  selectStartTime = () => {
    this.setState({ showStartTimePicker: true });
  };
  selectLastTime = () => {
    this.setState({ showLastTimePicker: true });
  };
  setStartTime = (time) => {
    this.setState(
      { start: time, showStartTimePicker: false },
      this.updateTimes
    );
  };
  setLastTime = (time) => {
    this.setState({ last: time, showLastTimePicker: false }, this.updateTimes);
  };
  handleNameChange = (e) => {
    this.setState({ name: e.target.value });
  };
  deleteEvent = (e) => {
    e.stopPropagation();
  };
  updateTimes = () => {
    let newTimes = this.state.times.filter((x) => {
      return x.time >= this.state.start && x.time <= this.state.last;
    });

    for (let i = this.state.start; i < newTimes[0].time; ) {
      newTimes.push({ time: i, open: true, pacing: this.state.defaultpacing });
      if (String(i).charAt(String(i).length - 2) === "3") {
        i += 70;
      } else {
        i += 30;
      }
    }
    newTimes.sort(function (a, b) {
      return a.time === b.time ? 0 : +(a.time > b.time) || -1;
    });

    for (
      let i = newTimes[newTimes.length - 1].time;
      this.state.last > newTimes[newTimes.length - 1].time;

    ) {
      if (String(i).charAt(String(i).length - 2) === "3") {
        i += 70;
      } else {
        i += 30;
      }
      newTimes.push({ time: i, open: true, pacing: this.state.defaultpacing });
    }
    this.setState({ times: newTimes });
  };

  createRow = (time) => {
    // set serviceName to be a vertical line
    // unless a service is defined to start at this time
    // OR if its the first service of the day (grab previous time back to default - null)
    // OR if a service is defined for the next time (for styling)
    let serviceName = (
      <React.Fragment>
        <hr className="continue-service" />
        <div
          className="add-service"
          onClick={this.addService.bind(null, time.time)}
        >
          +
        </div>
      </React.Fragment>
    );

    if (this.state.services.find((x) => x.time === time.time)) {
      serviceName = (
        <input
          value={this.state.services.find((x) => x.time === time.time).name}
          onBlur={this.checkServiceNames}
          onChange={this.changeServiceName.bind(this, time.time)}
          ref={
            this.state.services.find((x) => x.time === time.time).name === ""
              ? this.focusInput
              : ""
          }
        />
      );
    } else if (time.time === this.state.start) {
      const validService = this.state.services
        .filter((x) => {
          return time.time > x.time;
        })
        .reduce((a, b) => (a.time > b.time ? a : b));
      serviceName = (
        <input
          value={
            this.state.services.find((x) => x.time === validService.time).name
          }
          onBlur={this.checkServiceNames}
          onChange={this.changeServiceName.bind(this, validService.time)}
        />
      );
    } else if (
      this.state.services.find((x) => x.time === time.time + 30) ||
      this.state.services.find((x) => x.time === time.time + 70) ||
      time.time === this.state.last
    ) {
      serviceName = (
        <React.Fragment>
          <hr className="continue-service last-service" />
          <div className="end-service" />
        </React.Fragment>
      );
    }
    let usablePacing = time.pacing;
    if (!this.state.times.find((x) => x.time === time.time).open) {
      usablePacing = 0;
    }
    const row = (
      <div className="time-row">
        <div className="service-name">{serviceName}</div>
        <div className="open">
          <div
            className="checkbox"
            onClick={this.toggleOpen.bind(null, time.time)}
          >
            {(this.state.times.find((x) => x.time === time.time).open && (
              <CheckRoundedIcon />
            )) || <CheckRoundedIcon style={{ opacity: 0 }} />}
          </div>
        </div>
        <div className="time">{time.time}</div>
        <div className="time-row-slider">
          <input
            type="range"
            min="0"
            max={this.state.maxpacing}
            name="1230"
            class="slider"
            id="myRange"
            value={usablePacing}
            onChange={this.changeSpecificPacing.bind(this, time.time)}
          />
          <div
            class="sliderlabel"
            style={{
              left:
                "calc(" +
                (usablePacing / this.state.maxpacing) * 100 +
                "% - " +
                (usablePacing / this.state.maxpacing) * 40 +
                "px)",
            }}
          >
            {usablePacing}
          </div>
        </div>
      </div>
    );
    return row;
  };

  render() {
    let allRows = [];
    allRows = this.state.times.map((time) => this.createRow(time));

    let startTimePicker = "";
    if (this.state.showStartTimePicker) {
      startTimePicker = (
        <TimePicker time={this.state.start} onSubmit={this.setStartTime} />
      );
    }
    let lastTimePicker = "";
    if (this.state.showLastTimePicker) {
      lastTimePicker = (
        <TimePicker time={this.state.last} onSubmit={this.setLastTime} />
      );
    }
    let maxNumPad = "";
    if (this.state.showMaxNumPad) {
      maxNumPad = (
        <NumPad
          maxDigits={3}
          number={this.state.maxpacing}
          onNumber={this.setMaxPacing}
        />
      );
    }
    return (
      <React.Fragment>
        <div className="options-title">Edit Opening Hours</div>
        <div style={{ display: "flex" }}>
          <div className={this.state.greySave} onClick={this.onSave}>
            <CheckCircleRoundedIcon />
            Save
          </div>
          <div className="schedule-new-button" onClick={this.props.onCancel}>
            <CancelRoundedIcon />
            Cancel
          </div>
        </div>

        <div className="time-row-container">
          <div className="time-row">
            <div className="service-name settings-row">Name:</div>

            <div className="settings-field">
              <input value={this.state.name} onChange={this.handleNameChange} />
            </div>
          </div>
          <div className="time-row">
            <div className="service-name settings-row hours-settings">
              From: {startTimePicker}
              <input
                value={this.state.start}
                onClick={this.selectStartTime}
                readOnly
              />
              To: {lastTimePicker}
              <input
                value={this.state.last}
                onClick={this.selectLastTime}
                readOnly
              />
              Max Pacing:{maxNumPad}
              <input
                value={this.state.maxpacing}
                onClick={this.selectMaxPacing}
                readOnly
              />
            </div>
          </div>

          <div className="time-row">
            <div className="service-name settings-row">Default Pacing:</div>

            <div className="time-row-slider">
              <input
                type="range"
                min="0"
                max={this.state.maxpacing}
                name="1230"
                class="slider"
                id="myRange"
                value={this.state.defaultpacing}
                onChange={this.changeDefaultPacing}
              />
              <div
                class="sliderlabel"
                style={{
                  left:
                    "calc(" +
                    (this.state.defaultpacing / this.state.maxpacing) * 100 +
                    "% - " +
                    (this.state.defaultpacing / this.state.maxpacing) * 40 +
                    "px)",
                }}
              >
                {this.state.defaultpacing}
              </div>
            </div>
          </div>
        </div>
        <div className="time-row-container">{allRows}</div>
      </React.Fragment>
    );
  }
}

export default EditOpeningHours;
