import React, { Component } from "react";
import EmojiPeopleRoundedIcon from "@material-ui/icons/EmojiPeopleRounded";

class StatusButton extends Component {
  render() {
    // Pass this component 2 props. First is the bookingId to get the status, second is option, table.name as list ["T1"] if you only want results for the one table (table view)
    const this_booking = this.props.state.bookings.find(
      (booking) => booking._id === this.props.bookingId
    );

    // set the table to prop value. If undefined get a list of all tables this booking is sat on.
    let these_tables = this.props.table;

    if (these_tables === undefined) {
      these_tables = this.props.state.bookings.find(
        (booking) => booking._id === this.props.bookingId
      ).table;
    }

    // need to find the NEXT booking from any of the tables in these_tables ["T1", "T2"]
    // If any of the these_tables exist in a bookings.table then when you combine them the set length with be lower than the length.
    // first grab all future bookings...
    let next_tables_bookings = this.props.state.bookings.filter(
      (booking) => booking.phase === 1 && booking.time > this_booking.time
    );
    //.. then see if they match a table with this_booking
    next_tables_bookings = next_tables_bookings.filter(
      (booking) =>
        new Set(booking.table.concat(these_tables)).size !==
        booking.table.concat(these_tables).length
    );

    next_tables_bookings.sort((a, b) => a.time - b.time);

    let next_booking_time = 9999;
    if (next_tables_bookings.length !== 0) {
      next_booking_time = next_tables_bookings[0].time;
    }

    let usable_end_mins = this_booking.usable_end_time;

    if (!this_booking.manual_end_time) {
      usable_end_mins = this_booking.projected_end_time;
    }

    usable_end_mins =
      parseInt(usable_end_mins / 100) * 60 + (usable_end_mins % 100);
    let next_booking_mins =
      parseInt(next_booking_time / 100) * 60 + (next_booking_time % 100);

    const gap = next_booking_mins - usable_end_mins;

    let button_css = "status-button";
    if (gap < -14 && this_booking.phase !== 3) {
      button_css = "status-button red-border";
    } else if (gap < 0 && this_booking.phase !== 3) {
      button_css = "status-button orange-border";
    }
    const status = this.props.state.statuses
      .find((statuses) => statuses._id === this_booking.statusesId)
      .list.find((status) => status._id === this_booking.statusId);

    let click_event = () => {
      return;
    };
    if (this.props.onChangeStatus !== undefined) {
      click_event = this.props.onChangeStatus;
    }
    const status_button = (
      <div
        className={button_css}
        onClick={
          click_event &&
          ((e) => {
            e.stopPropagation();
            click_event(this.props.bookingId);
          })
        }
      >
        {this_booking.walkIn && this_booking.phase !== 2 ? (
          <EmojiPeopleRoundedIcon />
        ) : (
          status.icon
        )}
      </div>
    );

    return status_button;
  }
}

export default StatusButton;
