import React, { Component } from 'react';
import Schedules from './schedules';
import EditSchedule from './editschedule';
import TablePlans from './tableplans';
import EditTablePlan from './edittableplan';
import './../css/options.css';
import SettingsIcon from '@material-ui/icons/Settings';
import EditOpeningHours from './editopeninghours';
import OpeningHours from './openinghours';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
// Restaurant Info
// schedules
// tables
// pacings
// turn times
// Booking & guest status
//
class Options extends Component {
  state = {
    expand: false,
    showRestaurantInfo: false,
    showSchedules: false,
    showHours: false,
    showTablePlans: false,
    showServices: false,
    showTurnTimes: false,
    showGuestStatuses: false,
    editSchedule: null,
    editTablePlan: null,
    editOpeningHours: null, // {id: 1 (or "new"), duplicate: false}
  };
  handleSaveOpeningHours = (pacings, services) => {
    this.setState({ editOpeningHours: null });
    this.props.onSaveOpeningHours(pacings, services);
  };
  handleCancelTablePlanEdit = () => {
    this.setState({ editTablePlan: null });
  };
  handleCancelOpeningHoursEdit = () => {
    this.setState({ editOpeningHours: null });
  };
  editTablePlanComplete = (newTablePlan) => {
    this.setState({ editTablePlan: null });
    this.props.onSaveTablePlanEdit(newTablePlan);
  };
  handleShowTablePlanEdit = (tablePlan) => {
    this.setState({ editTablePlan: tablePlan });
  };
  handleShowOpeningHoursEdit = (idDuplicate) => {
    //idDuplicate is an object {id: 1 or new, duplicate: true or false}
    this.setState({ editOpeningHours: idDuplicate });
  };
  editComplete = (newSchedule) => {
    this.setState({ editSchedule: null });
    this.props.onSaveScheduleEdit(newSchedule);
  };

  handleShowScheduleEdit = (schedule) => {
    this.setState({ editSchedule: schedule });
  };
  handleCancelScheduleEdit = () => {
    this.setState({ editSchedule: null });
  };
  handleBack = () => {
    const hideAll = {
      showRestaurantInfo: false,
      showSchedules: false,
      showHours: false,
      showTablePlans: false,
      showServices: false,
      showTurnTimes: false,
      showGuestStatuses: false,
    };
    this.setState(hideAll);
  };
  onToggle = () => {
    let expand = false;
    if (this.state.expand ? (expand = false) : (expand = true));
    this.setState({ expand });

    this.handleBack();
  };
  onShow = (what, e) => {
    this.setState(what);
    e.stopPropagation();
  };
  onHide = (what, e) => {
    this.setState(what);
    e.stopPropagation();
  };
  deleteEvent = (e) => {
    e.stopPropagation();
  };
  render() {
    let optionsButtonCss = 'options-button ';
    let displayBlackout = 'blackout ';
    let restaurantInfoCss,
      schedulesCss,
      hoursCss,
      tablePlansCss,
      turnTimesCss,
      guestStatusesCss;
    restaurantInfoCss =
      schedulesCss =
      hoursCss =
      tablePlansCss =
      turnTimesCss =
      guestStatusesCss =
        'expanded-option ';
    if (this.state.expand) {
      optionsButtonCss += 'options-button-selected';
    } else {
      displayBlackout += 'display-none';
    }
    if (this.state.showRestaurantInfo) {
      restaurantInfoCss += 'option-full';
    }
    if (this.state.showSchedules) {
      schedulesCss += 'option-full';
    }
    if (this.state.showHours) {
      hoursCss += 'option-full';
    }
    if (this.state.showTablePlans) {
      tablePlansCss += 'option-full';
    }
    if (this.state.showTurnTimes) {
      turnTimesCss += 'option-full';
    }
    if (this.state.showGuestStatuses) {
      guestStatusesCss += 'option-full';
    }
    let editSchedule = '';
    if (this.state.editSchedule !== null) {
      editSchedule = (
        <div className="expanded-option option-full" onClick={this.deleteEvent}>
          <EditSchedule
            state={this.props.state}
            schedule={this.state.editSchedule}
            onCancel={this.handleCancelScheduleEdit}
            onSaveScheduleEdit={this.editComplete}
          />
        </div>
      );
    }
    let editTablePlan = '';
    if (this.state.editTablePlan !== null) {
      editTablePlan = (
        <div className="expanded-option option-full" onClick={this.deleteEvent}>
          <EditTablePlan
            state={this.props.state}
            tablePlan={this.state.editTablePlan}
            onCancel={this.handleCancelTablePlanEdit}
            onSave={this.editTablePlanComplete}
          />
        </div>
      );
    }
    let editOpeningHours = '';
    if (this.state.editOpeningHours !== null) {
      editOpeningHours = (
        <div className="expanded-option option-full" onClick={this.deleteEvent}>
          <EditOpeningHours
            _id={this.state.editOpeningHours._id}
            state={this.props.state}
            duplicate={this.state.editOpeningHours.duplicate}
            onCancel={this.handleCancelOpeningHoursEdit}
            onSave={this.handleSaveOpeningHours}
          />
        </div>
      );
    }
    let restaurantInfo = '';
    if (this.state.showRestaurantInfo) {
      restaurantInfo = (
        <div className="expanded-option option-full" onClick={this.deleteEvent}>
          Coming soon...
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className={displayBlackout} onClick={this.onToggle}></div>
        <div className={optionsButtonCss}>
          <SettingsIcon className="icon" onClick={this.onToggle} />
          <div className="options-menu">
            <div
              className="title"
              onClick={() => this.props.onChangeRestaurant()}
            >
              {this.props.state.restaurant.name}
            </div>
            <div
              className="option grey"
              onClick={this.onShow.bind(this, { showRestaurantInfo: true })}
            >
              Restaurant Info
            </div>
            <div
              className={
                this.props.state.userLevel === 'user' ? 'option grey' : 'option'
              }
              onClick={this.onShow.bind(this, { showSchedules: true })}
            >
              Schedules
            </div>
            <div
              className={
                this.props.state.userLevel === 'user' ? 'option grey' : 'option'
              }
              onClick={this.onShow.bind(this, { showHours: true })}
            >
              Opening Hours
            </div>
            <div
              className={
                this.props.state.userLevel === 'user' ? 'option grey' : 'option'
              }
              onClick={this.onShow.bind(this, { showTablePlans: true })}
            >
              Table Plans
            </div>

            <div
              className="option grey"
              onClick={this.onShow.bind(this, { showTurnTimes: true })}
            >
              Turn Times
            </div>
            <div
              className="option grey"
              onClick={this.onShow.bind(this, { showGuestStatuses: true })}
            >
              Guest Statuses
            </div>
            <div className="option grey">Tags</div>
            <div className="locked" style={{ display: 'none' }}>
              <LockOutlinedIcon />
            </div>
          </div>
          <div
            className={restaurantInfoCss}
            onClick={() => {
              this.setState({ showRestaurantInfo: false });
            }}
          >
            Coming Soon...
          </div>
          <div className={schedulesCss} onClick={this.deleteEvent}>
            <Schedules
              state={this.props.state}
              onEdit={this.handleShowScheduleEdit}
              onBack={this.handleBack}
            />
          </div>
          <div className={hoursCss}>
            <OpeningHours
              state={this.props.state}
              onEdit={this.handleShowOpeningHoursEdit}
              onBack={this.handleBack}
            />
          </div>
          <div className={tablePlansCss} onClick={this.deleteEvent}>
            <TablePlans
              state={this.props.state}
              onEdit={this.handleShowTablePlanEdit}
              onBack={this.handleBack}
            />
          </div>
          <div
            className={turnTimesCss}
            onClick={() => {
              this.setState({ showTurnTimes: false });
            }}
          >
            Your turn times are fully customisable, however this interface isn't
            quite finished yet! Please contact your Account Manager and we will
            make any changes for you.
          </div>
          <div
            className={guestStatusesCss}
            onClick={() => {
              this.setState({ showGuestStatuses: false });
            }}
          >
            Your gues status options are fully customisable, however this
            interface isn't quite finished yet! Please contact your Account
            Manager and we will make any changes for you.
          </div>
          {editOpeningHours}
          {editSchedule}
          {editTablePlan}
          <button
            className="logout"
            onClick={() => {
              this.props.logout();
            }}
          >
            Log out
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default Options;
