async function getAllTags(token) {
  const requestOptions = {
    method: "GET",
    headers: { "x-auth-token": token },
  };

  let data = await fetch(
    "https://evening-eyrie-53360.herokuapp.com/api/tags",
    requestOptions
  );

  return JSON.parse(await data.text());
}

const _getAllTags = getAllTags;
export { _getAllTags as getAllTags };
