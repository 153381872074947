async function getAllSchedules(token) {
  const requestOptions = {
    method: "GET",
    headers: { "x-auth-token": token },
  };

  let data = await fetch(
    "https://evening-eyrie-53360.herokuapp.com/api/schedules",
    requestOptions
  );

  const result = JSON.parse(await data.text());

  for (let i = 0; i < result.length; i++) {
    result[i].startDate = new Date(result[i].startDate);
    result[i].lastDate = new Date(result[i].lastDate);
  }

  return result;
}

async function postSchedule(token, schedule) {
  try {
    const requestOptions = {
      method: "POST",
      headers: { "x-auth-token": token, "Content-Type": "application/json" },
      body: JSON.stringify(schedule),
    };

    let data = await fetch(
      "https://evening-eyrie-53360.herokuapp.com/api/schedules",
      requestOptions
    );

    if (!data.ok) {
      data.text().then((res) => {
        console.log(data.status + " " + data.statusText + " " + res);
      });
      return false;
    }

    let result = JSON.parse(await data.text());

    // convert all the dates into JS Date() objects...

    result.startDate = new Date(result.startDate);
    result.lastDate = new Date(result.lastDate);

    return result;
  } catch (err) {
    console.log(err.message);
    return false;
  }
}

async function putSchedule(token, schedule) {
  try {
    const requestOptions = {
      method: "PUT",
      headers: { "x-auth-token": token, "Content-Type": "application/json" },
      body: JSON.stringify(schedule),
    };

    let data = await fetch(
      "https://evening-eyrie-53360.herokuapp.com/api/schedules/" + schedule._id,
      requestOptions
    );

    if (!data.ok) {
      data.text().then((res) => {
        console.log(data.status + " " + data.statusText + " " + res);
      });

      console.log("fail");
      return false;
    }

    let result = JSON.parse(await data.text());

    // convert all the dates into JS Date() objects...

    result.startDate = new Date(result.startDate);
    result.lastDate = new Date(result.lastDate);
    console.log("success!");
    return result;
  } catch (err) {
    console.log(err.message);

    console.log("fail");
    return false;
  }
}

const _getAllSchedules = getAllSchedules;
export { _getAllSchedules as getAllSchedules };
const _postSchedule = postSchedule;
export { _postSchedule as postSchedule };
const _putSchedule = putSchedule;
export { _putSchedule as putSchedule };
