import React, { Component } from "react";
class Refresh extends React.Component {
  render() {
    return (
      <div className="refresh">
        <button onClick={() => this.props.refresh()}>Sync</button>
      </div>
    );
  }
}

export default Refresh;
