import React, { Component } from "react";
import StatusButton from "./statusbutton";
import UndoIcon from "@material-ui/icons/Undo";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import Check from "@material-ui/icons/Check";

class Booking extends Component {
  undoStatus = (booking) => {
    let history = booking.history;
    history = history.filter((status) => status.phase < booking.phase);

    this.props.onUndoStatus(history[history.length - 1].statusId, booking._id);
  };

  render() {
    const booking = this.props.booking;
    // const statusIcon = this.props.state.statuses
    //   .find((statuses) => statuses._id === booking.statusesId)
    //   .list.find((status) => status._id === booking.statusId).icon;

    let dots = booking.tags;
    if (dots.length > 0) {
      dots = dots.map((tagId) => (
        <div
          key={tagId}
          className="circle"
          style={{
            backgroundColor: this.props.state.tags.find(
              (tag) => tag._id === tagId
            ).color,
          }}
        ></div>
      ));
    } else {
      dots = "";
    }
    return (
      <li
        className="fullWidth booking"
        onClick={() =>
          this.props.onTableBookingView(booking.table, booking._id)
        }
      >
        <table className="bookingrow">
          <tbody>
            <tr className="bookingrow">
              <td className="bookingcovers">
                {booking["covers"]} @ {booking["time"]}
              </td>
              <td className="bookingleftbuttons">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    this.props.onTableViewState(booking["id"]);
                  }}
                  className={booking.table_assigned ? "selected" : ""}
                >
                  {booking.table.length > 1
                    ? booking.table[0] + "+"
                    : booking.table[0]}
                </button>
              </td>
              <td className="bookingleftbuttons">
                <StatusButton
                  bookingId={booking["id"]}
                  onChangeStatus={this.props.onChangeStatus}
                  state={this.props.state}
                />
              </td>
              <td className="bookingdetails">
                <div className="bookingname">
                  {booking["name"]} {dots}
                </div>

                <p className="bookingnotes">{booking.description}</p>
              </td>
              {/* <td className="bookingrightbuttons">
                <PhoneButton
                  number={booking.phone}
                  onToggleButton={this.handleToggleButton}
                  state={this.state}
                />
              </td>
              <td className="bookingrightbuttons">
                <EmailButton
                  email={booking.email}
                  onToggleButton={this.handleToggleButton}
                  state={this.state}
                />
                </td> */}
              <td className="bookingrightbuttons">
                <div
                  className="seat-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (booking.phase === 1) {
                      this.props.onToggleSeatBooking(booking._id);
                    } else if (booking.phase === 2) {
                      this.props.onQuickChangeStatus(
                        this.props.state.statuses
                          .find(
                            (statuses) => statuses._id === booking.statusesId
                          )
                          .list.find((status) => status.name === "left")._id,
                        booking._id
                      );
                    } else {
                      this.undoStatus(booking);
                    }
                  }}
                >
                  {booking.phase === 1 ? (
                    <ArrowRightAltIcon />
                  ) : booking.phase === 2 ? (
                    <Check />
                  ) : (
                    <UndoIcon />
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </li>
    );
  }
}

export default Booking;
