import React, { Component } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import PostAddIcon from "@material-ui/icons/PostAdd";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";

/*
schedules: [
    {
      id: 1,
      name: "Default",
      startDate: null,
      lastDate: null,
      length: null,
      days: [
        { day: 1, tablesId: 1, pacingsId: 1 },
        { day: 2, tablesId: 1, pacingsId: 1 },
        { day: 3, tablesId: 1, pacingsId: 1 },
        { day: 4, tablesId: 1, pacingsId: 1 },
        { day: 5, tablesId: 1, pacingsId: 1 },
        { day: 6, tablesId: 1, pacingsId: 1 },
        { day: 0, tablesId: 1, pacingsId: 1 }
      ]
    },
    {
      id: 2,
      name: "Summer Hours",
      startDate: new Date(2020, 5, 1, 0, 0, 0, 0),
      lastDate: new Date(2020, 7, 31, 0, 0, 0, 0),
      length: 123,
      days: [
        { day: 1, tablesId: 2, pacingsId: 2 },
        { day: 2, tablesId: 2, pacingsId: 2 },
        { day: 3, tablesId: 2, pacingsId: 2 },
        { day: 4, tablesId: 2, pacingsId: 2 },
        { day: 5, tablesId: 2, pacingsId: 2 },
        { day: 6, tablesId: 2, pacingsId: 2 },
        { day: 0, tablesId: 2, pacingsId: 2 }
      ]
    }
]
*/
class Schedules extends Component {
  state = { selectedSchedule: null };
  setSelectedSchedule = (selectedSchedule, e) => {
    if (this.state.selectedSchedule === selectedSchedule) {
      this.setState({ selectedSchedule: null });
    } else {
      this.setState({ selectedSchedule });
    }
    e.stopPropagation();
  };
  buildSchedule = (schedule) => {
    const dateFormat = require("dateformat");
    //output should be containt > (days / opening hours / table plan) table div +

    const tables = this.props.state.tablesSchedules;
    const pacings = this.props.state.pacingsSchedules;

    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const order = [1, 2, 3, 4, 5, 6, 0];

    let swCss = "schedule-week ";
    let soCss = "schedule-overview ";
    if (schedule._id === this.state.selectedSchedule) {
      swCss += "selected";
      soCss += "selected";
    }

    let daysMapped = order.map((dow) => (
      <tr key={daysOfWeek[dow]}>
        <td className="schedule-label">{daysOfWeek[dow]}</td>
        <td>
          {
            // nested find is grabbing the pacingsId, used in the top level find to grab the name of the pacing
            pacings.find(
              (x) =>
                x._id === schedule.days.find((x) => x.day === dow).pacingsId
            ).name
          }
        </td>
        <td>
          {
            // nested find is grabbing the tablesId, used in the top level find to grab the name of the plan

            schedule.days.find((day) => day.day === dow).tablesIds.length > 1
              ? "Advanced"
              : tables.find(
                  (x) =>
                    x._id ===
                    schedule.days.find((y) => y.day === dow).tablesIds[0]
                      .tablesId
                ).name
          }
        </td>
      </tr>
    ));
    let duplicateSchedule = { ...schedule };
    duplicateSchedule._id = "duplicate";
    let output = (
      <div
        className="schedule-container"
        onClick={this.setSelectedSchedule.bind(this, schedule._id)}
        key={schedule._id}
      >
        <div className={soCss}>
          <table>
            <tbody>
              <tr>
                <td>{schedule.name}</td>
                <td>
                  {dateFormat(schedule.startDate, "dd mmm yy")} -{" "}
                  {dateFormat(schedule.lastDate, "dd mmm yy")}
                </td>
                <td className="small expand-arrow">{<ExpandMoreIcon />}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={swCss}>
          <table>
            <thead>
              <tr>
                <th></th>
                <th className="schedule-label">Opening Hours</th>
                <th className="schedule-label">Table Plan</th>
              </tr>
            </thead>
            <tbody>
              {daysMapped}
              <tr>
                <td colSpan="3">
                  <div
                    className="schedule-modify-button"
                    onClick={this.props.onEdit.bind(this, duplicateSchedule)}
                  >
                    <FileCopyOutlinedIcon />
                    duplicate
                  </div>{" "}
                  <div
                    className="schedule-modify-button"
                    onClick={this.props.onEdit.bind(this, schedule)}
                  >
                    <EditIcon />
                    edit
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>{" "}
      </div>
    );
    return output;
  };

  render() {
    let allSchedules = this.props.state.schedules.map((schedule) =>
      this.buildSchedule(schedule)
    );
    const newSchedule = {
      _id: "new",
      name: "Schedule Name",
      startDate: "Start Date",
      lastDate: "Last Date",
      length: null,
      days: [
        { day: 1, tablesId: null, pacingsId: null },
        { day: 2, tablesId: null, pacingsId: null },
        { day: 3, tablesId: null, pacingsId: null },
        { day: 4, tablesId: null, pacingsId: null },
        { day: 5, tablesId: null, pacingsId: null },
        { day: 6, tablesId: null, pacingsId: null },
        { day: 0, tablesId: null, pacingsId: null },
      ],
    };
    return (
      // title
      // list of schedules
      // tap one to
      <React.Fragment>
        <div className="options-title">Schedules</div>
        <div
          style={{
            display: "flex",
            width: "94%",
            justifyContent: "space-between",
          }}
        >
          <div className="schedule-new-button" onClick={this.props.onBack}>
            <ArrowBackRoundedIcon />
            back
          </div>
          <div
            className="schedule-new-button"
            onClick={this.props.onEdit.bind(null, newSchedule)}
          >
            <PostAddIcon />
            new schedule
          </div>
        </div>
        {allSchedules}
      </React.Fragment>
    );
  }
}

export default Schedules;
