import React, { Component } from 'react';
import NavBar from './components/navbar';
import BookingPage from './components/bookingpage';
import LoginPage from './components/loginpage';
import RestaurantSelector from './components/restaurantselector';
import Bookings from './components/bookings';
import Tables from './components/tables';
import Calendar from './components/calendar';
import TableBookings from './components/tablebookings';
import StatusSelector from './components/statusselector';
import SeatBooking from './components/seatbooking';
import Refresh from './components/refresh';
import Check from '@material-ui/icons/Check';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import BookingDetails from './components/bookingdetails';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import jwt from 'jsonwebtoken';
import { getAllBookings, postBooking, putBooking } from './api/bookings';
import {
  getAllPacingsSchedules,
  postPacingsSchedule,
  putPacingsSchedule,
} from './api/pacingsschedules';
import { getAllSchedules, postSchedule, putSchedule } from './api/schedules';
import { getAllStatuses } from './api/statuses';
import {
  getAllTablesSchedules,
  postTablesSchedule,
  putTablesSchedule,
} from './api/tablesschedules';
import { getAllTags } from './api/tags';
import {
  getAllPacingOverrides,
  postPacingOverride,
} from './api/pacingoverrides';
import { registerListener, getListenerChanges } from './api/listener';

import './App.css';
import './css/calendar.css';
import './css/settings.css';
import './css/mobile.css';
import { Book } from '@material-ui/icons';
import PrintableView from './components/printableview';

const clone = require('rfdc')();
/* 
To do:

- Schedules
-- have a default one (which simply links to default TablePlan and default Pacing, default Shifts)
-- start date / end date (therefore a length = number of days)
-- Pacings 
-- TablePlans
-- Dates have a Schedule. They may have many, so they select the one with the shortest length or random if equal (there's no reason to ever have 2 schedules of same length)

-- Editing the pacing for a day creates an override for that schedule (so the rest of the schedule can be changed, but that pacing will stay the same)

- TablePlans
-- Pretty simple.

- Pacings
-- Pretty simple.
-- Includes shifts.

- Shifts
-- array of objects (name, start, finish), (default, first pacing, last pacing)
-- must completely cover pacings?


- Calendar
-- add shifts + schedule || schedule only view (click to edit)

- add booking
-- include table from table plan or custom table
-- add booking with preselected time
- rebuild table popups so scrolling is fixed & not using buttons.

- BookingWidget
-- if not logged in see this instead.
-- should include custom html & css & logo (if exists) otherwise default layout

- Backend?
- AJAX


*/
const ogState = {
  jwt: null,
  restaurant: null,
  selectedService: '',
  calendar: 0,
  displayDate: new Date(),
  // will need to add table size availability to pacings for quick searches of multiple locatons.
  tablesViewState: ['tablescontainer'],
  tablesViewStateNext: ['tablescontainer opentableview'],
  statusSelector: 0,
  allPacingOverrides: [],
  pacingOverrides: [],
  tableSatTimes: [],
  // schedule length is calculated - and used to sort them. (shortest length is the one applied to the day). Setting the defauly
  schedules: [
    {
      _id: 1,
      name: 'Default',
      startDate: null,
      lastDate: null,
      length: null,
      days: [
        { day: 1, tablesId: 1, pacingsId: 1, statusesId: 1 },
        { day: 2, tablesId: 1, pacingsId: 1, statusesId: 1 },
        { day: 3, tablesId: 1, pacingsId: 1, statusesId: 1 },
        { day: 4, tablesId: 1, pacingsId: 1, statusesId: 1 },
        { day: 5, tablesId: 1, pacingsId: 1, statusesId: 1 },
        { day: 6, tablesId: 1, pacingsId: 1, statusesId: 1 },
        { day: 0, tablesId: 1, pacingsId: 1, statusesId: 1 },
      ],
    },
    {
      _id: 2,
      name: 'Demo Days',
      startDate: new Date(2021, 4, 1, 0, 0, 0, 0),
      lastDate: new Date(2021, 4, 30, 0, 0, 0, 0),
      length: 123,
      days: [
        { day: 1, tablesId: 2, pacingsId: 2, statusesId: 1 },
        { day: 2, tablesId: 2, pacingsId: 2, statusesId: 1 },
        { day: 3, tablesId: 2, pacingsId: 2, statusesId: 1 },
        { day: 4, tablesId: 2, pacingsId: 2, statusesId: 1 },
        { day: 5, tablesId: 2, pacingsId: 2, statusesId: 1 },
        { day: 6, tablesId: 2, pacingsId: 2, statusesId: 1 },
        { day: 0, tablesId: 2, pacingsId: 2, statusesId: 1 },
      ],
    },
    {
      _id: 3,
      name: 'St Chris Day',
      startDate: new Date(2020, 6, 10, 0, 0, 0, 0),
      lastDate: new Date(2020, 6, 10, 0, 0, 0, 0),
      length: 1,
      days: [
        { day: 1, tablesId: 3, pacingsId: 3, statusesId: 1 },
        { day: 2, tablesId: 3, pacingsId: 3, statusesId: 1 },
        { day: 3, tablesId: 3, pacingsId: 3, statusesId: 1 },
        { day: 4, tablesId: 3, pacingsId: 3, statusesId: 1 },
        { day: 5, tablesId: 3, pacingsId: 3, statusesId: 1 },
        { day: 6, tablesId: 3, pacingsId: 3, statusesId: 1 },
        { day: 0, tablesId: 3, pacingsId: 3, statusesId: 1 },
      ],
    },
    {
      _id: 4,
      name: 'Breakfast days',
      startDate: new Date(2020, 3, 26, 0, 0, 0, 0),
      lastDate: new Date(2020, 3, 30, 0, 0, 0, 0),
      length: 1,
      days: [
        { day: 1, tablesId: 3, pacingsId: 2, statusesId: 1 },
        { day: 2, tablesId: 3, pacingsId: 2, statusesId: 1 },
        { day: 3, tablesId: 3, pacingsId: 1, statusesId: 1 },
        { day: 4, tablesId: 3, pacingsId: 2, statusesId: 1 },
        { day: 5, tablesId: 3, pacingsId: 2, statusesId: 1 },
        { day: 6, tablesId: 3, pacingsId: 1, statusesId: 1 },
        { day: 0, tablesId: 3, pacingsId: 1, statusesId: 1 },
      ],
    },
  ],
  tablesSchedules: [
    {
      _id: 1,
      name: 'Default',
      tables: [
        { name: 'B1', covers: 2, online: false },
        { name: 'B2', covers: 2, online: false },
        { name: 'T1', covers: 2, online: true },
        { name: 'T2', covers: 2, online: true },
        { name: 'T4', covers: 2, online: true },
        { name: 'T5', covers: 2, online: true },
        { name: 'T8', covers: 2, online: true },
        { name: 'T3', covers: 4, online: true },
        { name: 'T6', covers: 6, online: true },
        { name: 'T7', covers: 4, online: true },
        { name: 'T9', covers: 4, online: true },
        { name: 'T10', covers: 8, online: true },
        { name: 'T11', covers: 4, online: true },
        { name: 'T12', covers: 6, online: true },
        { name: 'T13', covers: 4, online: true },
        { name: 'T14', covers: 4, online: true },
        { name: 'T15', covers: 8, online: true },
        { name: 'T16', covers: 2, online: true },
        { name: 'T17', covers: 2, online: true },
        { name: 'T18', covers: 2, online: true },
        { name: 'T19', covers: 4, online: true },
        { name: 'T20', covers: 2, online: true },
      ],
    },
    {
      _id: 2,
      name: 'Demo Seating',
      tables: [
        { name: 'T1', covers: 2, online: true },
        { name: 'T2', covers: 2, online: true },
        { name: 'T4', covers: 2, online: true },
        { name: 'T5', covers: 4, online: true },
      ],
    },
    {
      _id: 3,
      name: 'Twos',
      tables: [
        { name: 'T1', covers: 2, online: true },
        { name: 'T2', covers: 2, online: true },
        { name: 'T4', covers: 2, online: true },
        { name: 'T5', covers: 2, online: true },
        { name: 'T8', covers: 2, online: true },
        { name: 'T3', covers: 2, online: true },
        { name: 'T6', covers: 2, online: true },
        { name: 'T7', covers: 2, online: true },
        { name: 'T9', covers: 2, online: true },
        { name: 'T10', covers: 2, online: true },
        { name: 'T11', covers: 2, online: true },
        { name: 'T12', covers: 2, online: true },
        { name: 'T13', covers: 2, online: true },
        { name: 'T14', covers: 2, online: true },
        { name: 'T15', covers: 2, online: true },
        { name: 'T16', covers: 2, online: true },
        { name: 'T17', covers: 2, online: true },
        { name: 'T18', covers: 2, online: true },
        { name: 'T19', covers: 2, online: true },
        { name: 'T20', covers: 2, online: true },
      ],
    },
  ],
  // will need to expand to different schedules for days of week.
  pacingsSchedules: [
    {
      _id: 1,
      name: 'Default',
      servicesId: 1,
      maxPacing: 13,
      defaultPacing: 6,
      pacings: [
        { time: 1200, max: 8, booked: 0 },
        { time: 1230, max: 10, booked: 0 },
        { time: 1300, max: 6, booked: 0 },
        { time: 1330, max: 6, booked: 0 },
        { time: 1400, max: 6, booked: 0 },
        { time: 1430, max: 6, booked: 0 },
        { time: 1800, max: 6, booked: 0 },
        { time: 1830, max: 6, booked: 0 },
        { time: 1900, max: 6, booked: 0 },
        { time: 1930, max: 6, booked: 0 },
        { time: 2000, max: 6, booked: 0 },
        { time: 2030, max: 6, booked: 0 },
      ],
    },
    {
      _id: 2,
      name: 'Demo',
      servicesId: 2,
      maxPacing: 12,
      defaultPacing: 6,
      pacings: [
        { time: 1200, max: 6, booked: 0 },
        { time: 1230, max: 6, booked: 0 },
        { time: 1300, max: 6, booked: 0 },
        { time: 1330, max: 6, booked: 0 },
        { time: 1400, max: 6, booked: 0 },
        { time: 1430, max: 6, booked: 0 },
        { time: 1800, max: 6, booked: 0 },
        { time: 1830, max: 6, booked: 0 },
        { time: 1900, max: 6, booked: 0 },
        { time: 1930, max: 6, booked: 0 },
        { time: 2000, max: 6, booked: 0 },
        { time: 2030, max: 6, booked: 0 },
      ],
    },
    {
      _id: 3,
      name: 'Bank Holiday',
      servicesId: 3,
      maxPacing: 13,
      defaultPacing: 6,
      pacings: [
        { time: 1200, max: 8, booked: 0 },
        { time: 1230, max: 10, booked: 0 },
        { time: 1300, max: 6, booked: 0 },
        { time: 1330, max: 6, booked: 0 },
        { time: 1400, max: 6, booked: 0 },
        { time: 1430, max: 6, booked: 0 },
        { time: 1500, max: 6, booked: 0 },
        { time: 1530, max: 6, booked: 0 },
        { time: 1600, max: 6, booked: 0 },
        { time: 1630, max: 6, booked: 0 },
      ],
    },
  ],
  //for all services, there must be a null start time which will default to the first time. Each service has only a startTime, it's end time is calculated (last pacing before next service)

  // each schedule day of the week requires a default turn time + status (roll into one thing), however each booking can be manually assigned a different status id.
  // status can then hold the total turntime & advanced turn times.
  // So... the booking also
  // table size dictates turntimes and picks the table size =< it's size (so if 3 isnt defined it picks 2)
  tags: [
    { _id: 1, text: 'Allergy', color: 'rgb(255 0 0)' },
    { _id: 2, text: 'Birthday', color: 'rgb(0 0 255)' },
  ],
  statuses: [
    {
      _id: 1,
      name: 'default',
      useAdvancedTurns: true,
      turnTimeTotal: [
        { tableSize: 1, time: 90 },
        { tableSize: 6, time: 120 },
        { tableSize: 10, time: 180 },
      ],
      list: [
        {
          _id: 1,
          phase: 1,
          active: 1,
          name: 'booked',
          icon: <ImportContactsIcon />,
        },
        {
          _id: 2,
          phase: 1,
          active: 1,
          name: 'confirmed',
          icon: <MenuBookIcon />,
        },
        {
          _id: 3,
          phase: 1,
          active: 1,
          name: 'arrived',
          icon: <PersonPinIcon />,
        },
        {
          _id: 4,
          phase: 2,
          active: 1,
          name: 'sat',
          icon: 'Sat',
          timeLeft: [
            { tableSize: 1, time: 90 },
            { tableSize: 6, time: 120 },
            { tableSize: 10, time: 180 },
          ],
        },
        {
          _id: 6,
          phase: 2,
          active: 1,
          name: 'starters',
          icon: 'Str',
          timeLeft: [
            { tableSize: 1, time: 75 },
            { tableSize: 6, time: 100 },
            { tableSize: 10, time: 150 },
          ],
        },
        {
          _id: 7,
          phase: 2,
          active: 1,
          name: 'mains',
          icon: 'Mns',
          timeLeft: [
            { tableSize: 1, time: 60 },
            { tableSize: 6, time: 80 },
            { tableSize: 10, time: 110 },
          ],
        },
        {
          _id: 8,
          phase: 2,
          active: 1,
          name: 'desserts',
          icon: 'Des',
          timeLeft: [
            { tableSize: 1, time: 45 },
            { tableSize: 6, time: 50 },
            { tableSize: 10, time: 60 },
          ],
        },
        {
          _id: 9,
          phase: 2,
          active: 1,
          name: 'bill',
          icon: <ReceiptOutlinedIcon />,
          timeLeft: [
            { tableSize: 1, time: 30 },
            { tableSize: 6, time: 30 },
            { tableSize: 10, time: 30 },
          ],
        },
        {
          _id: 10,
          phase: 2,
          active: 1,
          name: 'paid',
          icon: <AttachMoneyOutlinedIcon />,
          timeLeft: [
            { tableSize: 1, time: 15 },
            { tableSize: 6, time: 15 },
            { tableSize: 10, time: 15 },
          ],
        },
        {
          _id: 11,
          phase: 3,
          active: 1,
          name: 'left',
          icon: <Check />,
        },
        {
          _id: 12,
          phase: 3,
          active: 1,
          name: 'cancelled',
          icon: <ClearRoundedIcon />,
        },
        {
          _id: 13,
          phase: 3,
          active: 1,
          name: 'no show',
          icon: '?',
        },
      ],
    },
  ],
  pacings: [],

  // end_time is calculated. turntime is calculated (from statuses + adjustments)
  allBookings: [
    {
      _id: 1,
      time: 1200,
      table: ['T8', 'T6'],
      phone: '07875292131',
      email: 'christopherhasalongemailaddress@gmail.com',
      name: 'Laura1',
      covers: 6,
      date: '',
      default_turntime: true,
      turntime: 90,
      end_time: 1400,
      projected_end_time: 1400,
      usable_end_time: 1415,
      manual_end_time: 1415,
      table_assigned: true,
      statusesId: 1,
      statusId: 1,
      phase: 1,
      statusesDefault: true,
      status_changed: false,
      description:
        "Can I grab a table by the window? It's my birthday. I'm allergic to fish.",
      tags: [1, 2],
      history: [{ statusId: 1, date: new Date() - 1 }],
    },
    {
      _id: 2,
      time: 1200,
      table: ['T18', 'T20'],
      phone: '07875292131',
      email: 'chris@gmail.com',
      name: 'James Jayson2',
      covers: 2,
      date: '',
      default_turntime: true,
      turntime: 90,
      end_time: 1330,
      projected_end_time: 1330,
      usable_end_time: 1330,
      manual_end_time: false,
      table_assigned: true,
      statusesId: 1,
      statusId: 1,
      phase: 1,
      statusesDefault: true,
      status_changed: false,
      description: '',
      tags: [],
      history: [{ statusId: 1, date: new Date() - 1 }],
    },
    {
      _id: 3343,
      time: 1200,
      table: ['C1'],
      phone: '07875292131',
      email: 'chris@gmail.com',
      name: 'Phil Potts3',
      covers: 2,
      date: '',
      default_turntime: true,
      turntime: 90,
      end_time: 1330,
      projected_end_time: 1330,
      usable_end_time: 1330,
      manual_end_time: false,
      table_assigned: true,
      statusesId: 1,
      statusId: 1,
      phase: 1,
      statusesDefault: true,
      status_changed: false,
      description:
        "Can I grab a table by the window? It's my birthday. I'm allergic to fish.",
      tags: [1, 2],
      history: [
        { statusId: 1, date: new Date(Date.now() - 24 * 60 * 60 * 1000) },
      ],
    },
    {
      _id: 4,
      time: 1215,
      table: ['T3'],
      phone: '07875292131',
      email: 'chris@gmail.com',
      name: 'Michael McIntyre4',
      covers: 3,
      date: '',
      default_turntime: true,
      turntime: 90,
      end_time: 1345,
      projected_end_time: 1345,
      usable_end_time: 1345,
      manual_end_time: false,
      table_assigned: false,
      statusesId: 1,
      statusId: 1,
      phase: 1,
      statusesDefault: true,
      status_changed: false,
      description: '',
      tags: [],
      history: [{ statusId: 1, date: new Date() - 1 }],
    },
    {
      _id: 5,
      time: 1300,
      table: ['T9'],
      phone: '07875292131',
      email: 'chris@gmail.com',
      name: 'Chris Charlick5',
      covers: 4,
      date: '',
      default_turntime: true,
      turntime: 90,
      end_time: 1430,
      projected_end_time: 1430,
      usable_end_time: 1430,
      manual_end_time: false,
      table_assigned: true,
      statusesId: 1,
      statusId: 1,
      phase: 1,
      statusesDefault: true,
      status_changed: false,
      description: '',
      tags: [],
      history: [{ statusId: 1, date: new Date() - 1 }],
    },
    {
      _id: 6,
      time: 1500,
      table: ['T5'],
      phone: '07875292131',
      email: 'chris@gmail.com',
      name: 'James Jayson6',
      covers: 2,
      date: '',
      default_turntime: true,
      turntime: 90,
      end_time: 1630,
      projected_end_time: 1630,
      usable_end_time: 1630,
      manual_end_time: false,
      table_assigned: false,
      statusesId: 1,
      statusId: 1,
      phase: 1,
      statusesDefault: true,
      status_changed: false,
      description: '',
      tags: [],
      history: [{ statusId: 1, date: new Date() - 1 }],
    },
    {
      _id: 7,
      time: 1330,
      table: ['T8'],
      phone: '07875292131',
      email: 'chris@gmail.com',
      name: 'Phil Potts',
      covers: 2,
      date: '',
      default_turntime: true,
      turntime: 90,
      end_time: 1500,
      projected_end_time: 1500,
      usable_end_time: 1500,
      manual_end_time: false,
      table_assigned: false,
      statusesId: 1,
      statusId: 1,
      phase: 1,
      statusesDefault: true,
      status_changed: false,
      description: '',
      tags: [],
      history: [{ statusId: 1, date: new Date() - 1 }],
    },
    {
      _id: 8,
      time: 1330,
      table: ['T18'],
      phone: '07875292131',
      email: 'chris@gmail.com',
      name: 'James Jayson7',
      covers: 2,
      date: '',
      default_turntime: true,
      turntime: 90,
      end_time: 1500,
      projected_end_time: 1500,
      usable_end_time: 1500,
      manual_end_time: false,
      table_assigned: true,
      statusesId: 1,
      statusId: 1,
      phase: 1,
      statusesDefault: true,
      status_changed: false,
      description: '',
      tags: [],
      history: [{ statusId: 1, date: new Date() - 1 }],
    },
    {
      _id: 9,
      time: 1330,
      table: ['T2'],
      phone: '07875292131',
      email: 'chris@gmail.com',
      name: 'Phil Potts8',
      covers: 2,
      date: '',
      default_turntime: true,
      turntime: 90,
      end_time: 1500,
      projected_end_time: 1500,
      usable_end_time: 1500,
      manual_end_time: false,
      table_assigned: false,
      statusesId: 1,
      statusId: 1,
      phase: 1,
      statusesDefault: true,
      status_changed: false,
      description: '',
      tags: [],
      history: [{ statusId: 1, date: new Date() - 1 }],
    },
    {
      _id: 10,
      time: 1400,
      table: ['T1'],
      phone: '07875292131',
      email: 'chris@gmail.com',
      name: 'Phil Potts9',
      covers: 2,
      date: '',
      default_turntime: true,
      turntime: 90,
      end_time: 1530,
      projected_end_time: 1530,
      usable_end_time: 1530,
      manual_end_time: false,
      table_assigned: false,
      statusesId: 1,
      statusId: 1,
      phase: 1,
      statusesDefault: true,
      status_changed: false,
      description: '',
      tags: [],
      history: [{ statusId: 1, date: new Date() - 1 }],
    },
  ],
  //bookings are those with display date. needs refactoring.
  bookings: [],
  maxPacing: 12,
  tableBookings: false,

  seatBooking: null,
  targetBooking: 1,
  targetTable: [''],
  selectingTable: false,
  selectedTables: [],
  selectedButton: 'Auto',
  persistentButton: 'Manual',
  tables: [],
  addBooking: false,
  addWalkIn: false,
  newBooking: { table: [] },
  loaded: false,
  listenerId: false,
  bundledPacings: [],
};
class App extends Component {
  state = ogState;

  async componentDidMount() {}

  refresh = () => {
    this.loadRestaurant(this.state.jwt);
  };

  handleEditBooking = (booking) => {
    this.setState({ editBooking: booking, newBooking: booking });
    this.handleToggleAddBooking();
  };

  handleContinuousUpdate = async (token) => {
    try {
      const changes = await getListenerChanges(token, this.state.listenerId);
      if (changes.refreshRequired) return this.refresh();

      if (changes.bookings.length > 0) {
        for (let i = 0; changes.bookings.length > i; i++) {
          changes.bookings[i].date = new Date(changes.bookings[i].date);
          const allBookings = this.state.allBookings.filter(
            (booking) => booking._id !== changes.bookings[i]._id
          );
          allBookings.push(changes.bookings[i]);
          this.setState({ allBookings }, () =>
            this.handleDateChange(this.state.displayDate)
          );
        }
      }
    } catch (err) {
      if (!(this.state.jwt && !this.state.restaurant))
        window.location.reload(false);
    }
  };

  continuousUpdate = (token) => {
    this.tableSatTimes();
    if (!this.checkForUpdates)
      this.checkForUpdates = setInterval(() => {
        //const now = new Date();
        //if(this.state.lastCheckIn) console.log("checked. Gap: ", now.valueOf() - this.state.lastCheckIn.valueOf());
        this.tableSatTimes();
        this.handleContinuousUpdate(token);
        //this.setState({lastCheckIn: now})
      }, 5000);
  };
  handleTogglePrintView = () => {
    this.setState({ printView: !this.state.printView });
  };
  tableSatTimes = () => {
    // grab all bookings in phase 2.
    // calculate sat time in rounded up minutes.
    // push to this.state.tableSatTimes for each table on the booking
    const tableSatTimes = [];
    const satBookings = this.state.bookings.filter(
      (booking) => booking.phase === 2
    );

    satBookings.forEach((booking) => {
      const history = booking.history.filter((status) => status.phase === 2);
      const timeSat = parseInt(
        (new Date() - new Date(history[0].date)) / 60000
      );
      booking.table.forEach((table) =>
        tableSatTimes.push({ name: table, minutes: timeSat })
      );
    });
    this.setState({ tableSatTimes });
  };

  componentWillUnmount = () => {
    clearInterval(this.checkForUpdates);
  };

  loadRestaurant = async (token) => {
    this.continuousUpdate(token);

    const allBookings = await getAllBookings(token);
    const pacingsSchedules = await getAllPacingsSchedules(token);
    const schedules = await getAllSchedules(token);
    const statuses = await getAllStatuses(token);
    const tablesSchedules = await getAllTablesSchedules(token);
    const tags = await getAllTags(token);
    const allPacingOverrides = await getAllPacingOverrides(token);

    allBookings.sort((a, b) => a.time - b.time);

    //// for demo purposes give every booking a date of today or tomorrow
    let today = new Date();
    today = new Date(today.setHours(0, 0, 0, 0));
    this.setState(
      {
        allBookings,
        pacingsSchedules,
        schedules,
        statuses,
        tablesSchedules,
        tags,
        allPacingOverrides,
        loaded: true,
      },
      () => {
        ////waits for setState to asynchronously update
        //// call handleDateChange to filter state.allBookings into state.bookings
        schedules.length > 0 &&
          this.handleDateChange(today) &&
          this.optimiseTables(today);
      }
    );
  };

  handleSetJWT = async (token) => {
    const { selectedRestaurant, level } = jwt.decode(token);
    this.setState({
      jwt: token,
      restaurant: selectedRestaurant,
      userLevel: level,
    });
    if (selectedRestaurant !== null) {
      let listenerId = await registerListener(token);

      if (listenerId.status === 200)
        listenerId = JSON.parse(await listenerId.text())._id;
      this.setState({ listenerId }, () => this.loadRestaurant(token));
    }
  };

  UUIDGenerator = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  };

  handleChangeRestaurant = () => {
    ogState.jwt = this.state.jwt;
    !ogState.firstRestaurant &&
      (ogState.firstRestaurant = this.state.restaurant._id) &&
      console.log('done');
    clearInterval(this.checkForUpdates);
    this.checkForUpdates = undefined;
    this.setState(ogState);
  };

  handleToggleSeatBooking = (id) => {
    this.setState({ seatBooking: id, statusSelector: 0 });
  };
  handleSeatBooking = () => {
    let allBookings = this.state.allBookings;

    let booking = allBookings.find(
      (booking) => booking._id === this.state.seatBooking
    );

    const statuses_list = this.state.statuses
      .find((statuses) => statuses._id === booking.statusesId)
      .list.filter((status) => status.phase === 2);

    //booking.phase = 2;
    //booking.statusId = statuses_list[0]._id;

    this.handleChangeStatus(statuses_list[0]._id, booking._id);

    //this.setState({ allBookings, seatBooking: null });
  };

  handleAddBooking = async (booking) => {
    if (this.state.addWalkIn) this.handleTableViewState();
    // convert booking.turntime from {hours: 1, mins: 30} to 90.  not really sure what turntime is used for..? For now grab it from statuses and use the object for other calcs first

    /* booking looks like:
    
    covers: 2
    default_turntime: true
    description: "notes"
    email: "chris@gmail.com"
    name: "Chris"
    phase: 1
    phone: "07875292131"
    statusId: 1
    status_changed: false
    statusesDefault: true
    statusesId: 1
    table: ["T16"]
    table_assigned: undefined
    tags: [1]
    time: 1200
    turntime: {hours: 1, mins: 30},
    manual_end_time: true*/

    /*  add:
     
     **** _id: 1,
    **** date: "",
    **** turntime: 90,
    **** end_time: 1400,
    **** projected_end_time: 1400,
    **** usable_end_time: 1415,
    **** manual_end_time: 1415,
    **** history: [{ statusId: 1, date: new Date() - 1 }] */
    delete booking.turn_time;
    booking.date = this.state.displayDate;
    // if it's an edit not an add do this...
    if (this.state.editBooking) {
      putBooking(this.state.jwt, this.state.listenerId, booking)
        .then((res) => {
          if (!res) throw new Error('edit booking failed');
          let allBookings = this.state.allBookings;
          allBookings = allBookings.filter((e) => e._id !== booking._id);
          allBookings.push(booking);
          this.setState(
            { editBooking: null, addBooking: false, allBookings },
            () => this.weirdTest(booking.date)
          );
        })
        .catch((err) => console.log(err));
      return;
    }

    const result = await postBooking(
      this.state.jwt,
      this.state.listenerId,
      booking
    );

    if (!result) return;

    let newBookings = this.state.allBookings;
    newBookings.push(result);

    // no failure handling. Needs adding.

    this.setState(
      {
        allBookings: newBookings,
        addBooking: false,
        addWalkIn: false,
      },
      () => this.weirdTest(booking.date)
    );
  };

  weirdTest = (date) => {
    this.optimiseTables(date);
    this.handleDateChange(date);
  };
  handleNewBookingDetails = (newBooking) => {
    this.setState({ newBooking, selectedTables: newBooking.table });
  };
  handleToggleAddBooking = () => {
    let addWalkIn = false;
    let addBooking = true;
    if (this.state.addBooking) {
      addBooking = false;
      this.setState({ editBooking: null, newBooking: { table: [] } });
    } else {
      JSON.stringify(this.state.tablesViewState) ===
        JSON.stringify(['tablescontainer opentableview']) &&
        this.handleTableViewState();
    }
    this.setState({ addBooking, addWalkIn, selectedTables: [] });
  };
  handleToggleAddWalkIn = () => {
    let addBooking = false;
    let addWalkIn = true;
    if (this.state.addWalkIn) {
      addWalkIn = false;
      this.handleTableViewState();
      this.setState({ editBooking: null, newBooking: { table: [] } });
    } else {
      this.handleTableViewState('new');
    }
    this.setState({ addBooking, addWalkIn, selectedTables: [] });
  };
  scoreTables = (tablesArr, bookings, booking) => {
    // returns a score per table of seat-minutes wasted if booking is sat here. Points are negative per seat minute wasted, posit
    // Fully wasted seats = -turn time. 15 min gap from previous booking = -seats * 15. Same for gap to next booking. If less than default turn time. For perfect start/end give +10 each (to favour these)
    // 0. Combine tables & bookings
    // 1. exclude unavailable tables (tables with a reservation where tableBooking.time <= booking.time && tableBooking.end_time > booking.time || tableBooking.time > booking.time && tableBooking.time < booking.end_time) & tables that are too small
    // 2. Find previous booking (highest value from list of end times <= start time). Score the gap (if less than turn time), minus the result. If perfect = +10.
    // 3. Find next booking (lowest value from list of start times >= end time). Score the gap (if less than turn time), minus the result. If perfect = +10.
    // 4. Score wasted covers from turn time. Minus the result.

    let tables = tablesArr.filter((tables) => tables.time <= booking.time);
    tables = tables[tables.length - 1].tables;

    const start = booking.time;
    const end = booking.usable_end_time;
    let useBookings = clone(bookings);
    useBookings = useBookings.filter(
      (b) => b.phase !== 3 && (!b._id || b._id !== booking._id)
    );

    let defaultTurntime = 90;
    // setting turntimes is not yet complete. for now use 90 minutes.
    // 0
    //const clone = require("rfdc")();
    let combinedTables = clone(tables);
    combinedTables.map(
      (table) =>
        (table.bookings = useBookings.filter((booking) =>
          booking.table.includes(table.name)
        ))
    );

    // 1
    combinedTables = combinedTables.filter(
      (table) =>
        table.bookings.filter(
          (tableBooking) =>
            !(tableBooking.usable_end_time <= start || tableBooking.time >= end)
        ).length === 0
    );

    combinedTables = combinedTables.filter(
      (table) => table.covers >= booking.covers
    );

    // 2 & 3
    var i;
    for (i = 0; combinedTables[i]; i++) {
      combinedTables[i].score = 0;

      let previous = [];
      combinedTables[i].bookings.forEach(
        (booking) =>
          booking.usable_end_time <= start &&
          previous.push(booking.usable_end_time)
      );
      previous = Math.max(...previous);

      let next = [];
      combinedTables[i].bookings.forEach(
        (booking) => booking.time >= end && next.push(booking.time)
      );
      next = Math.min(...next);

      //let next = Math.min(
      //  ...combinedTables[i].bookings
      //    .filter(booking => booking.time >= end)
      //    .map(next => next.time),
      //  0
      //);

      // previous and start are integers in the format 1030. Difference between 1100 and 1030 needs to be 30 minutes. find the difference in minutes.
      const previousMin =
        Number(previous.toString().slice(0, 2)) * 60 +
        Number(previous.toString().slice(2, 4));
      const startMin =
        Number(start.toString().slice(0, 2)) * 60 +
        Number(start.toString().slice(2, 4));
      const endMin =
        Number(end.toString().slice(0, 2)) * 60 +
        Number(end.toString().slice(2, 4));
      let nextMin =
        Number(next.toString().slice(0, 2)) * 60 +
        Number(next.toString().slice(2, 4));
      let prevDiff = startMin - previousMin;
      let nextDiff = nextMin - endMin;

      // for smart turn times we want to cause sub 15 minute switches to align. Sub 15 minutes cant happen from booking, so a diff of less than 15 MUST be because of smart turntimes.
      if (prevDiff < 15 && prevDiff > 0) {
        prevDiff = 0;
      }
      if (nextDiff < 15 && nextDiff > 0) {
        nextDiff = 0;
      }

      defaultTurntime = endMin - startMin;
      if (prevDiff === 0) {
        combinedTables[i].score += 10;
      } else if (prevDiff > 0 && prevDiff < defaultTurntime) {
        combinedTables[i].score -= prevDiff * combinedTables[i].covers;
      }

      if (nextDiff === 0) {
        combinedTables[i].score += 10;
      } else if (nextDiff > 0 && nextDiff < defaultTurntime) {
        combinedTables[i].score -= nextDiff * combinedTables[i].covers;
      }

      // 4
      combinedTables[i].score +=
        -(combinedTables[i].covers - booking.covers) * defaultTurntime;
    }
    const result = combinedTables.sort((a, b) => (a.score > b.score ? -1 : 1));
    return result[0];
  };

  optimiseTables = (date) => {
    //const clone = require("rfdc")();
    // 1. get all tables for date. Filter out offline tables.
    // 2. get all bookings for date
    // 3. strip all tables from bookings if not manually assigned (booking.table_assigned === false) and must be phase 1.
    // 4. for each booking:
    // 4a. get tables scores from this.scoreTables
    // 4b. assign to highest point value table
    // 1
    let tablesIds = this.handleGetSchedule(date).days.find(
      (day) => day.day === date.getDay()
    ).tablesIds;

    //returns array of objects [{time: , tablesId: }]

    let tables = [];

    tablesIds.forEach((obj) => {
      let onlineTables = this.state.tablesSchedules.find(
        (tables) => tables._id === obj.tablesId
      ).tables;
      onlineTables = onlineTables.filter((table) => table.online);
      tables.push({ time: obj.time, tables: onlineTables });
    });

    // 2
    let allBookings = clone(this.state.allBookings);
    let bookings = allBookings.filter(
      (booking) => booking.date.valueOf() === date.valueOf()
    );

    // 3
    // bookings.forEach(
    //   (booking) =>
    //     (booking.table =
    //       booking.table_assigned || booking.phase !== 1 ? booking.table : [])
    // );

    for (let i = 0; bookings.length > i; i++) {
      !bookings[i].table_assigned &&
        bookings[i].phase === 1 &&
        (bookings[i].table = []);
    }
    // 4
    //let tableScores = this.scoreTables(tables, bookings, bookings[1]);

    for (let i = 0; bookings.length > i; i++) {
      if (bookings[i].table.length === 0) {
        const best = this.scoreTables(tables, bookings, bookings[i]);
        bookings[i].table.push(best ? best.name : 'C1');
      }
    }
    this.setState({ bookings, allBookings });
    return true;
  };

  handleUpdateOpeningHours = async (pacingsSchedule) => {
    //handle
    let result = false;

    if (pacingsSchedule._id === 'new' || pacingsSchedule._id === 'duplicate')
      result = await postPacingsSchedule(this.state.jwt, pacingsSchedule);

    if (
      this.state.pacingsSchedules.find(
        (pacing) => pacing._id === pacingsSchedule._id
      )
    )
      result = await putPacingsSchedule(this.state.jwt, pacingsSchedule);

    if (!result) return console.log('pacingsSchedule failed');

    console.log('success');

    let pacingsSchedules = this.state.pacingsSchedules.filter(
      (x) => x._id !== pacingsSchedule._id
    );
    pacingsSchedules.push(pacingsSchedule);
    pacingsSchedules = pacingsSchedules.sort((a, b) =>
      a._id > b._id ? 1 : -1
    );

    this.setState({ pacingsSchedules }, () => {
      this.handleRecalculatePacings();
    });
  };

  handleGetSchedule = (date) => {
    //get schedule for a date
    // 1. Grab all schedules that apply to today. Select the one with shortest length. If none, select the default one.
    let schedules = [];
    let schedule = {};
    schedules = this.state.schedules.filter(
      (schedule) => schedule.startDate <= date && schedule.lastDate >= date
    );
    // if no schedules matched pick the first one (which will always be the default schedule)
    if (schedules.length === 0) {
      schedule = this.state.schedules[0];
    } else {
      // otherwise pick the one with the shortest length
      schedule = schedules.reduce(
        (min, p) => (p.length < min.length ? p : min),
        schedules[0]
      );
    }
    return schedule;
  };
  handleAdjustManualTurnTime = (bookingId, adjustment) => {
    let allBookings = this.state.allBookings;
    const oldBookings = clone(allBookings);
    let booking = allBookings.find((booking) => booking._id === bookingId);
    if (booking.manual_end_time === false) {
      booking.manual_end_time = booking.end_time;
    }
    let booking_end_mins =
      parseInt(booking.manual_end_time / 100) * 60 +
      (booking.manual_end_time % 100) +
      adjustment;
    let booking_new_manual_end_time =
      parseInt(booking_end_mins / 60) * 100 + (booking_end_mins % 60);

    booking.manual_end_time = booking_new_manual_end_time;
    booking.usable_end_time = booking.manual_end_time;

    this.setState(
      {
        allBookings,
      },
      () => {
        this.optimiseTables(booking.date);
        putBooking(this.state.jwt, this.state.listenerId, booking)
          .then((res) => {
            if (!res) {
              this.setState({ allBookings: oldBookings });
              console.log(
                'Failed to update booking on the server. Change rolled back.'
              );
              this.optimiseTables(booking.date);
            }
          })
          .catch((err) => {
            console.log(err);
            this.setState({ allBookings: oldBookings });
            this.optimiseTables(booking.date);
          });
      }
    );
  };

  handleGetServices = (date) => {
    //get a schedule, grab the pacing, then the services.
    // total all bookings from date on each service

    const schedule = this.handleGetSchedule(date);
    const day = date.getDay();
    const pacing = this.handleGetPacingsFromSchedule(schedule, day);

    const services = pacing;

    return services;
  };
  handleSaveTablePlanEdit = async (newTablePlan) => {
    let newTablesFormatted = [];
    for (let i = 0; i < newTablePlan.tables.length; i++) {
      const onlineTables = newTablePlan.tables[i].online.split(' ');
      for (let x = 0; x < onlineTables.length && onlineTables[x] !== ''; x++) {
        const obj = {
          name: onlineTables[x],
          covers: newTablePlan.tables[i].covers,
          online: true,
        };
        newTablesFormatted.push(obj);
      }
      const offlineTables = newTablePlan.tables[i].offline.split(' ');
      for (
        let x = 0;
        x < offlineTables.length && offlineTables[x] !== '';
        x++
      ) {
        const obj = {
          name: offlineTables[x],
          covers: newTablePlan.tables[i].covers,
          online: false,
        };
        newTablesFormatted.push(obj);
      }
    }
    newTablePlan.tables = newTablesFormatted;

    let result = false;

    if (newTablePlan._id === 'new') {
      result = await postTablesSchedule(this.state.jwt, newTablePlan);
    } else {
      result = await putTablesSchedule(this.state.jwt, newTablePlan);
    }

    if (!result) return console.log('TablePlan failed', newTablePlan);

    let oldTablePlan = this.state.tablesSchedules;
    let combined = oldTablePlan.filter((x) => {
      return x._id !== newTablePlan._id;
    });
    combined.push(result);
    console.log('success');
    this.setState({ tablesSchedules: combined });
  };

  handleSaveScheduleEdit = async (newSchedule) => {
    let result = false;
    if (newSchedule._id === 'new' || newSchedule._id === 'duplicate') {
      result = await postSchedule(this.state.jwt, newSchedule);
    } else {
      // edit schedule
      result = await putSchedule(this.state.jwt, newSchedule);
    }

    if (!result) return console.log('schedule failed');

    //cannot currently edit statuses, so just add that in.

    let oldSchedule = this.state.schedules;
    let combined = oldSchedule.filter((x) => {
      return x._id !== newSchedule._id;
    });
    combined.push(newSchedule);
    this.setState({ schedules: combined });
  };
  handleGetCoversPerService = (date) => {
    let servicesArray = this.handleGetServices(date);
    servicesArray = servicesArray.services;
    let bookings = this.state.allBookings.filter((obj) => {
      return (
        obj.date.valueOf() === date.valueOf() &&
        (obj.phase !== 3 ||
          this.state.statuses
            .find((statuses) => statuses._id === obj.statusesId)
            .list.find((status) => status._id === obj.statusId).name === 'left')
      );
    });

    // services [{name: xx, time: xx}] null 1200 1800
    //services must be sorted by time
    const numServices = servicesArray.length;
    let i = 0;
    let newServices = [];
    while (i < numServices) {
      const serviceBookings = bookings.filter((obj) => {
        return (
          obj.time >= servicesArray[i].time &&
          (i + 1 === numServices || obj.time < servicesArray[i + 1].time)
        );
      });
      const totalCovers = serviceBookings.reduce(
        (accum, item) => accum + item.covers,
        0
      );
      let newObj = {};
      newObj.name = servicesArray[i].name;
      newObj.time = servicesArray[i].time;
      newObj.covers = totalCovers;
      newServices.push(newObj);
      i++;
    }
    return newServices;
  };

  handleGetPacingsFromSchedule = (schedule, day) => {
    let pacingId = schedule.days.filter((obj) => {
      return obj.day === day;
    });
    pacingId = pacingId[0].pacingsId;
    let pacingFromSchedule = this.state.pacingsSchedules.filter((obj) => {
      return obj._id === pacingId;
    });
    pacingFromSchedule = pacingFromSchedule[0];
    return pacingFromSchedule;
  };
  handleToggleManualTurnTime = (bookingId) => {
    let bookings = this.state.bookings;
    let booking = bookings.find((booking) => booking._id === bookingId);

    if (booking.manual_end_time === false) {
      booking.manual_end_time = booking.end_time;
      booking.usable_end_time = booking.end_time;
    } else {
      booking.manual_end_time = false;
      booking.usable_end_time = Math.min(
        booking.projected_end_time,
        booking.end_time
      );
    }

    this.setState({ bookings }, this.optimiseTables(booking.date));
  };

  handleRecalculatePacings = (bookings = this.state.bookings) => {
    // when this.state.bookings changes, pacings need to recalculate. Perhaps will be backend and become redundant?
    //for every pacing, sum it's bookings`
    // get schedule
    // 1. Grab all schedules that apply to today. Select the one with shortest length. If none, select the default one.
    let schedule = this.handleGetSchedule(this.state.displayDate);

    //build pacings
    // grab the pacingsSchedules [{id, name, services, pacings[]}] using schedule.pacingsSchedulesId
    let dayNumber = this.state.displayDate.getDay();
    let pacingFromSchedule = this.handleGetPacingsFromSchedule(
      schedule,
      dayNumber
    );

    // set the maxPacing of today as defined in the schedule refactored to the bottom

    //build table plan

    let pacings = [];
    for (const value of pacingFromSchedule.pacings) {
      let covers = bookings.filter(
        (booking) =>
          booking.phase !== 3 &&
          booking.time >= value.time &&
          booking.time <= value.time + 29
      );
      if (covers.length === 0) {
        covers = 0;
      } else {
        covers = covers
          .map((item) => item.covers)
          .reduce((prev, next) => prev + next);
      }
      value.booked = covers;
      pacings.push(value);
    }

    // remove any pacingOverrides which do not have corresponding pacing

    const pacingOverrides = this.state.pacingOverrides.filter((pacing) =>
      pacings.find((p) => p.time === pacing.time)
    );

    this.setState({
      pacings,
      maxPacing: pacingFromSchedule.maxPacing,
      pacingOverrides,
    });
  };

  handleClearSelectedTables = () => {
    this.setState({
      selectedTables: [],
      selectedButton: 'Auto',
      persistentButton: 'Manual',
    });
  };

  handleInitializeTableSelectButtons = (
    selectedButton,
    persistentButton,
    fullTargetBooking,
    selectedTables
  ) => {
    this.setState({ selectedButton, persistentButton }, () => {
      if (
        fullTargetBooking.table_assigned === true &&
        persistentButton !== 'Custom'
      ) {
        fullTargetBooking.table.forEach((table) =>
          this.handleToggleSelectTable(table)
        );
      } else if (persistentButton === 'Custom') {
        this.setState({ selectedTables });
      }
    });
  };

  handleChangePersistentButton = (newState) => {
    let selectedTables = this.state.selectedTables;

    if (newState === 'Auto') {
      selectedTables = [];
    } else if (newState === 'Manual' && selectedTables.length > 1) {
      selectedTables.length = 1;
    } else if (newState === 'Custom') {
      selectedTables.length = 0;
    }

    if (this.state.persistentButton === 'Custom' && newState !== 'Custom') {
      selectedTables = [];
    }

    this.setState({
      persistentButton: newState,
      selectedButton: newState,
      selectedTables,
    });
  };

  handleToggleSelectTable = (tableName, table = false) => {
    let selectedButton = this.state.selectedButton;
    let persistentButton = this.state.persistentButton;

    if (this.state.addWalkIn) {
      selectedButton = 'Multi';
      persistentButton = 'Multi';
    }

    let covers;

    if (this.state.addBooking || this.state.addWalkIn) {
      covers = this.state.newBooking.covers;
    } else {
      covers = this.state.bookings.find(
        (booking) => booking._id === this.state.targetBooking
      ).covers;
    }

    let selectedTables = this.state.selectedTables;
    if (selectedTables.includes(tableName)) {
      selectedTables = selectedTables.filter(
        (selectedTable) => selectedTable !== tableName
      );
    } else if (this.state.persistentButton === 'Manual') {
      selectedTables = [tableName];
      if (table && covers > table.covers) {
        selectedButton = 'Multi';
        persistentButton = 'Multi';
      }
    } else {
      selectedTables.push(tableName);
    }

    // logic for changing which button is showing as selected after selections change

    if (selectedButton === 'Auto' && selectedTables.length > 0) {
      selectedButton = persistentButton;
    }

    this.setState({ selectedButton, persistentButton, selectedTables });
  };

  handleTableViewState = (sourceBooking = null) => {
    // if (sourceBooking === "new") {
    //   this.setState({ addBooking: true });
    // }

    const current = this.state.tablesViewState;
    const next = this.state.tablesViewStateNext;

    let selectingTable = false;
    sourceBooking !== null && (selectingTable = true);
    this.setState({
      tablesViewState: next,
      tablesViewStateNext: current,
      targetBooking: sourceBooking,
      selectingTable,
    });
  };

  logout = () => {
    window.location.reload(false);
  };

  handleBookingTable = () => {
    if (this.state.addBooking) {
      let newBooking = this.state.newBooking;
      newBooking.table = this.state.selectedTables;
      this.setState({ newBooking });
    } else {
      let table = this.state.selectedTables;
      let allBookings = this.state.allBookings;
      const oldBookings = clone(allBookings);
      const targetBooking = this.state.targetBooking;

      let booking = allBookings.find(
        (booking) => booking._id === targetBooking
      );

      booking.table = table;

      let assigned = true;
      if (this.state.persistentButton === 'Auto' || table.length === 0) {
        assigned = false;
      }

      booking.table_assigned = assigned;

      this.setState(
        {
          allBookings,
          targetBooking: '',
          targetTable: table,
          selectingTable: false,
          selectedTables: [],
        },
        () => {
          this.optimiseTables(booking.date);
          putBooking(this.state.jwt, this.state.listenerId, booking)
            .then((res) => {
              if (!res) {
                this.setState({ allBookings: oldBookings });
                console.log(
                  'Failed to update booking on the server. Change rolled back.'
                );
                this.optimiseTables(booking.date);
              }
            })
            .catch((err) => {
              console.log(err);
              this.setState({ allBookings: oldBookings });
              this.optimiseTables(booking.date);
            });
        }
      );

      // this.setState(
      //   {
      //     allBookings,
      //     targetBooking: "",
      //     targetTable: table,
      //     selectingTable: false,
      //     selectedTables: [],
      //   },
      //   () => {
      //     const optimiseDate = this.state.bookings.find(
      //       (booking) => booking._id === targetBooking
      //     ).date;
      //     this.optimiseTables(optimiseDate);
      //   }
      // );
    }
    this.handleTableViewState();
  };

  handlePacingChange = (pacing) => {
    // changing the value of pacing via the slider, not updating the pacing after new booking
    let pacings = this.state.pacings;
    //pacings.find(v => v.time === pacing.name).max = parseInt(pacing.value, 10);

    pacings.forEach((element) => {
      if (element.time === parseInt(pacing.target.name)) {
        element.max = pacing.target.value;
      }
    });
    this.setState({ pacings });
  };

  bundlePacingOverride = () => {
    if (this.bundleWaiting === true) return;

    this.bundleWaiting = true;
    setTimeout(() => {
      this.bundleWaiting = false;

      const pacings = this.state.bundledPacings.reverse();
      const filteredPacings = [];

      pacings.forEach(
        (pacing) =>
          filteredPacings.find(
            (fp) =>
              fp.time === pacing.time &&
              fp.date.valueOf() === pacing.date.valueOf()
          ) === undefined && filteredPacings.push(pacing)
      );

      this.setState({ bundledPacings: [] });

      filteredPacings.forEach((pacing) => {
        postPacingOverride(this.state.jwt, pacing);
      });
    }, 10000);
  };

  handlePacingOverride = (e) => {
    const newPacing = {
      date: this.state.displayDate,
      time: parseInt(e.target.name),
      max: parseInt(e.target.value),
    };
    // pacing = {date: xx, time:1200, max: 3 }
    const allPacingOverrides = this.state.allPacingOverrides;
    const oldPacing = allPacingOverrides.find(
      (pacing) =>
        pacing.date.valueOf() === newPacing.date.valueOf() &&
        pacing.time === newPacing.time
    );

    if (oldPacing) {
      const i = allPacingOverrides.indexOf(oldPacing);
      allPacingOverrides.splice(i, 1);
    }

    if (
      !this.state.pacings.find(
        (pacing) =>
          pacing.time === newPacing.time && pacing.max === newPacing.max
      )
    ) {
      allPacingOverrides.push(newPacing);
    }
    const pacingOverrides = allPacingOverrides.filter((pacing) => {
      return (
        new Date(pacing.date).setHours(0, 0, 0, 0).valueOf() ===
        this.state.displayDate.valueOf()
      );
    });

    this.bundlePacingOverride();
    const bundledPacings = clone(this.state.bundledPacings);
    bundledPacings.push(newPacing);
    this.setState({
      bundledPacings,
      pacingOverrides,
      allPacingOverrides,
    });
  };
  handleToggleCalendar = () => {
    const calendar = this.state.calendar === 0 ? 1 : 0;
    this.setState({ calendar });
  };
  handleToggleStatusSelector = () => {
    const statusSelector = this.state.statusSelector === 0 ? 1 : 0;
    this.setState({ statusSelector });
  };
  onChangeStatus = (bookingId) => {
    this.setState({ targetBooking: bookingId });
    this.handleToggleStatusSelector();
  };
  handleChangeStatus = (newStatusId, bookingId = this.state.targetBooking) => {
    let allBookings = this.state.allBookings;
    const oldBookings = clone(allBookings);

    let booking = allBookings.find((booking) => booking._id === bookingId);
    booking.statusId = newStatusId;

    const status = this.state.statuses
      .find((statuses) => statuses._id === booking.statusesId)
      .list.find((status) => status._id === booking.statusId);
    booking.phase = status.phase;
    //if phase 1 set projected end time to end time and it's not manually overwritten...
    if (status.phase === 1 && !booking.manual_end_time) {
      booking.projected_end_time = booking.end_time;
    }
    // if phase 2, add up remaining time in minutes, add this onto status_changed, update projected_end_time
    if (status.phase === 2 && !booking.manual_end_time) {
      let date = new Date();
      let status_changed_mins = date.getHours() * 60 + date.getMinutes();
      booking.status_changed = date;
      // Get the set of statuses, get the status, get all timeLefts where covers <= booking covers, get the highest cover result.

      const cover_times = status.timeLeft.filter(
        (timeLeft) => timeLeft.tableSize <= booking.covers
      );
      const time_left = cover_times.reduce(
        (max, cover_time) => (cover_time.time > max ? cover_time.time : max),
        cover_times[0].time
      );
      //("00" + date.getMinutes().toString()).slice(-2)

      //TURN OFF SMART TURNS
      // const projected_end_time = parseInt(
      //   parseInt((time_left + status_changed_mins) / 60).toString() +
      //     ("00" + ((time_left + status_changed_mins) % 60)).slice(-2)
      // );
      //booking.projected_end_time = projected_end_time;

      //status_changed is the time that the status was set. time_left is the additional time from phase2 statuses. so projected_end_time = time_left + status_changed
      // except these are integers not times... so convert both to hours and minutes, and recombine.
      const projected_end_min =
        parseInt(booking.projected_end_time / 100) * 60 +
        (booking.projected_end_time % 100);
      const end_min =
        parseInt(booking.end_time / 100) * 60 + (booking.end_time % 100);
      booking.phase = status.phase;
      booking.usable_end_time =
        projected_end_min + 14 < end_min
          ? booking.projected_end_time
          : booking.end_time;
    }

    let history = booking.history.filter(
      (local_status) => local_status.statusId < status._id
    );

    history.push({
      statusId: status._id,
      date: new Date(),
      phase: status.phase,
    });
    booking.history = history;

    //this.handleToggleStatusSelector();

    this.setState({ allBookings, statusSelector: 0 }, () => {
      this.handleRecalculatePacings();
      this.optimiseTables(booking.date);
      putBooking(this.state.jwt, this.state.listenerId, booking)
        .then((res) => {
          if (!res) {
            this.setState({ allBookings: oldBookings });
            console.log(
              'Failed to update booking on the server. Change rolled back.'
            );
            this.handleRecalculatePacings();
            this.optimiseTables(booking.date);
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            allBookings: oldBookings,
            targetBooking: booking._id,
          });
          this.handleRecalculatePacings();
          this.optimiseTables(booking.date);
        });
    });
  };
  handleSetSelectedService = (name) => {
    this.setState({ selectedService: name });
  };
  handleDateChange = (displayDate) => {
    // regrab all bookings for the new date

    const bookings = this.state.allBookings.filter((booking) => {
      return new Date(booking.date).valueOf() === displayDate.valueOf();
    });
    const pacingOverrides = this.state.allPacingOverrides.filter((pacing) => {
      return (
        new Date(pacing.date).setHours(0, 0, 0, 0).valueOf() ===
        displayDate.valueOf()
      );
    });

    // pacing overrides without the time still available (made before opening hours changes) cause crashes. Remove them.

    this.setState(
      { pacingOverrides, bookings, selectedService: '', displayDate },
      () => {
        //waits for setState to asynchronously update
        // call handleDateChange to filter state.allBookings into state.bookings
        this.handleRecalculatePacings();
        this.optimiseTables(displayDate);
      }
    );
  };
  handleCustomChange = (e) => {
    this.setState({ selectedTables: [e.target.value] });
  };

  handleToggleTableBookingView = () => {
    {
      const tableBookings = this.state.tableBookings === false ? true : false;
      this.setState({ tableBookings });
    }
  };

  handleTableBookingView = (targetTable, targetBooking = false) => {
    this.setState({ targetBooking, targetTable });
    this.handleToggleTableBookingView();
  };

  handleChangeTarget = (targetTable, targetBooking) => {
    this.setState({ targetBooking, targetTable });
  };

  render() {
    const pathname = window.location.pathname.split('/');
    if (pathname[1] === 'book') {
      // simplyserve.com/book/the-nut-tree
      return (
        <BookingPage onScoreTables={this.scoreTables} state={this.state} />
      );
    }

    if (!this.state.jwt) return <LoginPage onSetJWT={this.handleSetJWT} />;
    if (!this.state.restaurant)
      return (
        <RestaurantSelector
          onSelectRestaurant={this.handleSelectRestaurant}
          token={this.state.jwt}
          onSetJWT={this.handleSetJWT}
        />
      );
    if (!this.state.loaded)
      return (
        <div className="restaurant-selector-wrapper loading">
          <div className="window">
            <div>Loading restaurant data</div>
            <div className="blob" />
          </div>
        </div>
      );

    let calendar = '';
    if (this.state.calendar) {
      calendar = (
        <Calendar
          state={this.state}
          onGetCoversPerService={this.handleGetCoversPerService}
          onToggleCalendar={this.handleToggleCalendar}
          onGetSchedule={this.handleGetSchedule}
          onDateChange={this.handleDateChange}
        />
      );
    }
    let statusSelector = '';
    if (this.state.statusSelector) {
      statusSelector = (
        <StatusSelector
          state={this.state}
          onToggleStatusSelector={this.handleToggleStatusSelector}
          onChangeStatus={this.handleChangeStatus}
          onToggleSeatBooking={this.handleToggleSeatBooking}
          onUndoStatus={this.handleChangeStatus}
        />
      );
    }

    let tableBookings = '';
    if (this.state.tableBookings) {
      tableBookings = (
        <TableBookings
          onChangeStatus={this.onChangeStatus}
          onChangeTarget={this.handleChangeTarget}
          state={this.state}
          table={this.state.targetTable}
          booking_id={this.state.targetBooking}
          onToggleTableBookingView={this.handleToggleTableBookingView}
          onTableViewState={this.handleTableViewState}
          onToggleManualTurnTime={this.handleToggleManualTurnTime}
          onAdjustManualTurnTime={this.handleAdjustManualTurnTime}
          onEditBooking={this.handleEditBooking}
        />
      );
    }

    let seatBooking = '';

    if (this.state.seatBooking !== null) {
      seatBooking = (
        <SeatBooking
          bookingId={this.state.seatBooking}
          state={this.state}
          onToggleSeatBooking={this.handleToggleSeatBooking}
          onSeatBooking={this.handleSeatBooking}
        />
      );
    }

    // allBookings,
    //     pacingsSchedules,
    //     schedules,
    //     statuses,
    //     tablesSchedules,
    //     tags,
    //     allPacingOverrides,

    let tables = '';
    if (this.state.schedules.length > 0) {
      tables = (
        <Tables
          state={this.state}
          onCustomChange={this.handleCustomChange}
          onGetSchedule={this.handleGetSchedule}
          onBookingTable={this.handleBookingTable}
          onBookingManualTable={this.handleBookingManualTable}
          onTableBookingView={this.handleTableBookingView}
          onTableViewState={this.handleTableViewState}
          onToggleSelectTable={this.handleToggleSelectTable}
          onClearSelectedTables={this.handleClearSelectedTables}
          onNewBookingDetails={this.handleNewBookingDetails}
          onChangePersistentButton={this.handleChangePersistentButton}
          onInitializeTableSelectButtons={
            this.handleInitializeTableSelectButtons
          }
        />
      );
    }
    let bookings = '';
    if (this.state.schedules.length > 0) {
      bookings = (
        <Bookings
          state={this.state}
          onTogglePrintView={this.handleTogglePrintView}
          onToggleSeatBooking={this.handleToggleSeatBooking}
          onAddBooking={this.handleAddBooking}
          onTableBookingView={this.handleTableBookingView}
          onChangeStatus={this.onChangeStatus}
          onQuickChangeStatus={this.handleChangeStatus}
          onSetSelectedService={this.handleSetSelectedService}
          onGetServices={this.handleGetServices}
          onPacingChange={this.handlePacingOverride}
          onTableViewState={this.handleTableViewState}
          onScoreTables={this.scoreTables}
          onGetSchedule={this.handleGetSchedule}
          onToggleAddBooking={this.handleToggleAddBooking}
          onToggleAddWalkIn={this.handleToggleAddWalkIn}
          onNewBookingDetails={this.handleNewBookingDetails}
          onUndoStatus={this.handleChangeStatus}
        />
      );
    }
    return (
      <React.Fragment>
        <Refresh refresh={this.refresh} />
        <NavBar
          state={this.state}
          onChangeRestaurant={this.handleChangeRestaurant}
          onResetDate={this.handleDateChange}
          onToggleCalendar={this.handleToggleCalendar}
          onSaveScheduleEdit={this.handleSaveScheduleEdit}
          onSaveTablePlanEdit={this.handleSaveTablePlanEdit}
          onSaveOpeningHours={this.handleUpdateOpeningHours}
          onToggleAddBooking={this.handleToggleAddBooking}
          onToggleAddWalkIn={this.handleToggleAddWalkIn}
          logout={this.logout}
        />
        {bookings}
        {tables}
        {calendar}
        {tableBookings}
        {statusSelector}
        {seatBooking}
        <PrintableView
          bookings={this.state.bookings}
          show={this.state.printView}
          toggleVisible={this.handleTogglePrintView}
          tags={this.state.tags}
          services={this.handleGetServices(this.state.displayDate).services}
        />

        <div id="modal-root"></div>
      </React.Fragment>
    );
  }
}

export default App;
