import React, { Component } from 'react';
import Booking from './booking';
import AddBooking from './addbooking';
import AddWalkIn from './addwalkin';
import Person from '@material-ui/icons/Person';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import './../css/bookings.css';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import PrintRoundedIcon from '@material-ui/icons/PrintRounded';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';

const clone = require('rfdc')();

// Page renders all pacing slots, and a booking object from the booking module per booking in each pacing slot.
// A pacing slot is 30 minutes, so all bookings at 12:00 and 12:15 will be in the 12:00 pacing

class Bookings extends Component {
  state = {
    minimize: ['Completed', 'Seated'],
    service_selector: 0,
    showBookings: true,
    editPacings: false,
  };

  toggleShowBookings = () => {
    if (this.state.showBookings) return this.setState({ showBookings: false });
    this.setState({ showBookings: true });
  };

  toggleEditPacings = () => {
    if (this.state.editPacings) return this.setState({ editPacings: false });
    this.setState({ editPacings: true });
  };

  toggleServiceSelector = () => {
    let service_selector = 1;
    this.state.service_selector === 1 && (service_selector = 0);

    this.setState({ service_selector });
  };
  toggleMinimize = (section) => {
    let minimize = this.state.minimize;
    if (minimize.includes(section)) {
      minimize = minimize.filter((value) => value !== section);
    } else {
      minimize.push(section);
    }
    this.setState({ minimize });
  };

  handleGetBookingSection = (servicePacingsWithName) => {
    const bookings = this.props.state.bookings;
    const pacings = clone(servicePacingsWithName.pacings);
    const pacingOverrides = this.props.state.pacingOverrides;
    pacingOverrides.forEach((el) => {
      const pacing = pacings.find(
        (pacing) => pacing.time === el.time && pacing.max !== el.max
      );
      if (pacing) {
        pacing.oMax = el.max;
        pacing.override = true;
      }
    });
    const serviceName = servicePacingsWithName.name;
    const bookingSection = (
      <div
        className={
          this.state.minimize.includes(serviceName)
            ? 'bookings-main minimize'
            : 'bookings-main'
        }
        key={serviceName}
      >
        <div className="bookings-service-header">
          {serviceName}
          <div className="buttons-wrapper">
            <button
              className="unselected"
              onClick={this.props.onTogglePrintView}
            >
              <PrintRoundedIcon />
            </button>
            <button
              className={this.state.editPacings ? 'selected' : 'unselected'}
              onClick={this.toggleEditPacings}
            >
              <Person />
            </button>
            <button
              className={this.state.showBookings ? 'selected' : 'unselected'}
              onClick={this.toggleShowBookings}
            >
              <ImportContactsIcon />
            </button>
          </div>
        </div>
        {pacings.map((pacing) => (
          <div key={pacing.time} className="fullWidth">
            <table className="pacing">
              <tbody>
                <tr>
                  <td className="timediv">{pacing.time}</td>

                  <td className="pacingcovers">{pacing.booked}</td>
                  <td className="personicon">
                    <Person />
                  </td>
                  <td className="slidecontainer">
                    <div
                      className={
                        'additionalpacing' +
                        (this.state.editPacings ? '' : ' lock')
                      }
                    >
                      <div
                        className="bookedpacing"
                        style={{
                          width:
                            (100 / this.props.state.maxPacing) * pacing.booked +
                            '%',
                          maxWidth: '100%',
                        }}
                      >
                        &nbsp;
                      </div>
                    </div>
                    <input
                      type="range"
                      min="0"
                      max={this.props.state.maxPacing}
                      value={
                        pacing.oMax === undefined ? pacing.max : pacing.oMax
                      }
                      name={pacing.time}
                      className={
                        'slider' + (this.state.editPacings ? '' : ' lock')
                      }
                      id="myRange"
                      onChange={(pacing) => this.props.onPacingChange(pacing)}
                    />
                    <div
                      className={
                        (pacing.override
                          ? 'sliderlabel custom'
                          : 'sliderlabel') +
                        (this.state.editPacings ? '' : ' lock')
                      }
                      style={{
                        left: this.state.editPacings
                          ? 'calc(((' +
                            (100 / this.props.state.maxPacing) *
                              (pacing.oMax === undefined
                                ? pacing.max
                                : pacing.oMax) +
                            '% - ' +
                            ((pacing.oMax === undefined
                              ? pacing.max
                              : pacing.oMax) /
                              this.props.state.maxPacing) *
                              20 +
                            'px)) - ' +
                            ((pacing.oMax === undefined
                              ? pacing.max
                              : pacing.oMax) /
                              this.props.state.maxPacing) *
                              10 +
                            'px)'
                          : (100 / this.props.state.maxPacing) *
                              (pacing.oMax === undefined
                                ? pacing.max
                                : pacing.oMax) +
                            '%',
                      }}
                    >
                      {pacing.oMax === undefined ? pacing.max : pacing.oMax}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <ul
              className={
                'fullWidth bookingUL' + (this.state.showBookings ? '' : ' hide')
              }
            >
              {bookings
                .filter(function (booking) {
                  const slot = pacing.time;
                  return (
                    booking.phase === 1 &&
                    booking.time >= parseInt(slot, 10) &&
                    booking.time <= parseInt(slot, 10) + 29 &&
                    booking.name &&
                    booking._id
                  );
                })
                .map((booking) => (
                  <Booking
                    onQuickChangeStatus={this.props.onQuickChangeStatus}
                    onToggleSeatBooking={this.props.onToggleSeatBooking}
                    onTableBookingView={this.props.onTableBookingView}
                    state={this.props.state}
                    onTableViewState={this.props.onTableViewState}
                    onChangeStatus={this.props.onChangeStatus}
                    booking={booking}
                    key={booking._id}
                    onUndoStatus={this.props.onUndoStatus}
                  />
                ))}
            </ul>
          </div>
        ))}
      </div>
    );

    return bookingSection;
  };

  getServiceSelector = () => {
    // grab all services. If > 1, create service bar, otherwise blank JSX.
    let serviceSelector = '';
    let services = this.props.onGetServices(
      this.props.state.displayDate
    ).services;

    const completed_bookings = this.props.state.bookings.filter(
      (booking) => booking.phase > 2
    ).length;
    const sat_bookings = this.props.state.bookings.filter(
      (booking) => booking.phase === 2
    ).length;
    // service bar is an all button (which sets this.state.selectedService="") followed by a button for each service.
    if (services.length > 1 || completed_bookings > 0 || sat_bookings > 0) {
      let add_space = '';
      this.props.state.addBooking && (add_space = ' add-space');
      this.props.state.addWalkIn && (add_space = ' add-space');

      serviceSelector = (
        <div className={'service-selector-wrapper' + add_space}>
          <div
            className={
              this.state.service_selector === 1
                ? 'service-selector'
                : 'service-selector hidden'
            }
          >
            {completed_bookings > 0 && (
              <div
                className={
                  this.state.minimize.includes('Completed')
                    ? 'service-selector-button'
                    : 'service-selector-button ssb-selected'
                }
                onClick={() => this.toggleMinimize('Completed')}
              >
                Com
              </div>
            )}
            {sat_bookings > 0 && (
              <div
                className={
                  this.state.minimize.includes('Seated')
                    ? 'service-selector-button'
                    : 'service-selector-button ssb-selected'
                }
                onClick={() => this.toggleMinimize('Seated')}
              >
                Sat
              </div>
            )}
            {services.map((service) => (
              <div
                key={service.name}
                className={
                  this.state.minimize.includes(service.name)
                    ? 'service-selector-button'
                    : 'service-selector-button ssb-selected'
                }
                onClick={() => this.toggleMinimize(service.name)}
              >
                {service.name.substring(0, 3)}
              </div>
            ))}
          </div>
          <div
            className={
              this.state.service_selector === 1
                ? 'expand-contract'
                : 'expand-contract hidden'
            }
            onClick={this.toggleServiceSelector}
          >
            <ArrowRightAltIcon />
          </div>
        </div>
      );
    }
    // this.state.selectedService when blank means all is selected.
    return serviceSelector;
  };

  handleDisplayBookingsByService = () => {
    let services = this.props.onGetServices(this.props.state.displayDate);
    services = services.services;
    //filter by this.state.selectedService

    const pacings = this.props.state.pacings;

    let returnBookingServices = [];
    const numServices = services.length;
    for (let i = 0; i < numServices; i++) {
      let servicePacings = [];
      servicePacings = pacings.filter((obj) => {
        return (
          obj.time >= services[i].time &&
          (i + 1 === numServices || obj.time < services[i + 1].time)
        );
      });
      let hide = false;
      if (
        this.props.state.selectedService !== '' &&
        this.props.state.selectedService !== services[i].name
      ) {
        hide = true;
      }
      const servicePacingsWithName = {
        name: services[i].name,
        pacings: servicePacings,
        hide,
      };
      returnBookingServices.push(
        this.handleGetBookingSection(servicePacingsWithName)
      );
    }
    return returnBookingServices;
  };

  // grab all services. If > 1, create service bar, otherwise blank JSX.
  // service bar is an all button (which sets this.state.selectedService="") followed by a button for each service.
  // this.state.selectedService when blank means all is selected.

  render() {
    let add_booking_window = '';
    if (this.props.state.addBooking) {
      add_booking_window = (
        <AddBooking
          state={this.props.state}
          onAddBooking={this.props.onAddBooking}
          onScoreTables={this.props.onScoreTables}
          onGetSchedule={this.props.onGetSchedule}
          onToggleAddBooking={this.props.onToggleAddBooking}
          onTableViewState={this.props.onTableViewState}
          onNewBookingDetails={this.props.onNewBookingDetails}
        />
      );
    }
    let add_walkin_window = '';
    if (this.props.state.addWalkIn) {
      add_walkin_window = (
        <AddWalkIn
          state={this.props.state}
          onAddBooking={this.props.onAddBooking}
          onGetSchedule={this.props.onGetSchedule}
          onToggleAddWalkIn={this.props.onToggleAddWalkIn}
          onNewBookingDetails={this.props.onNewBookingDetails}
        />
      );
    }

    let spacer = 'spacer';
    this.props.state.addBooking && (spacer = 'spacer big');
    this.props.state.addWalkIn && (spacer = 'spacer big');

    const sat_bookings = this.props.state.bookings.filter(
      (booking) => booking.phase === 2
    );

    let sat_section = '';
    if (sat_bookings.length > 0) {
      sat_section = (
        <div
          className={
            this.state.minimize.includes('Seated')
              ? 'bookings-main minimize'
              : 'bookings-main'
          }
        >
          <div className="bookings-service-header">Currently Seated</div>
          <div className="fullWidth">
            <ul className="fullWidth bookingUL">
              {sat_bookings.map((booking) => (
                <Booking
                  onQuickChangeStatus={this.props.onQuickChangeStatus}
                  onToggleSeatBooking={this.props.onToggleSeatBooking}
                  onTableBookingView={this.props.onTableBookingView}
                  state={this.props.state}
                  onTableViewState={this.props.onTableViewState}
                  onChangeStatus={this.props.onChangeStatus}
                  booking={booking}
                  key={booking._id}
                  onUndoStatus={this.props.onUndoStatus}
                />
              ))}
            </ul>
          </div>
        </div>
      );
    }

    const completed_bookings = this.props.state.bookings.filter(
      (booking) => booking.phase > 2
    );

    let completed_section = '';
    if (completed_bookings.length > 0) {
      completed_section = (
        <div
          className={
            this.state.minimize.includes('Completed')
              ? 'bookings-main minimize'
              : 'bookings-main'
          }
        >
          <div className="bookings-service-header">Completed Bookings</div>
          <div className="fullWidth">
            <ul className="fullWidth bookingUL">
              {completed_bookings.map((booking) => (
                <Booking
                  onQuickChangeStatus={this.props.onQuickChangeStatus}
                  onToggleSeatBooking={this.props.onToggleSeatBooking}
                  onTableBookingView={this.props.onTableBookingView}
                  state={this.props.state}
                  onTableViewState={this.props.onTableViewState}
                  onChangeStatus={this.props.onChangeStatus}
                  booking={booking}
                  key={booking['id']}
                  onUndoStatus={this.props.onUndoStatus}
                />
              ))}
            </ul>
          </div>
        </div>
      );
    }

    return (
      <React.Fragment>
        {this.getServiceSelector()}

        <div className={spacer}></div>
        <div className="bookings-wrapper">
          <div
            className={
              this.props.state.addBooking
                ? 'add-booking-holder-holder'
                : 'add-booking-holder-holder hide'
            }
          >
            {add_booking_window}
          </div>
          <div
            className={
              this.props.state.addWalkIn
                ? 'add-booking-holder-holder walk-in'
                : 'add-booking-holder-holder walk-in hide'
            }
          >
            {add_walkin_window}
          </div>
          {completed_section}
          {sat_section}
          {this.handleDisplayBookingsByService()}
        </div>
      </React.Fragment>
    );
  }
}

export default Bookings;
