import { CollectionsTwoTone } from '@material-ui/icons';
import React, { Component } from 'react';

function displayTime(num) {
  return num + ':00';
}

class TimePicker extends Component {
  constructor(props) {
    super(props);

    console.log('time', this.props.time);

    let minutes = this.props.time
      ? String(this.props.time % 100 || '00')
      : '00';
    console.log('set mins', minutes);
    let hours = this.props.time ? Math.floor(this.props.time / 100) : 12;
    let am = hours > 11 ? false : true;

    this.state = { hours, minutes, am };
  }

  selectHours = (hours) => {
    this.setState({ hours });
  };
  selectMinutes = (minutes) => {
    this.setState({ minutes });
  };
  selectAm = () => {
    if (this.state.am) return;
    let hours = this.state.hours - 12;
    this.setState({ hours, am: true });
  };
  selectPm = () => {
    if (!this.state.am) return;
    let hours = this.state.hours + 12;
    this.setState({ hours, am: false });
  };

  submit = () => {
    let value = this.state.hours * 100 + parseInt(this.state.minutes);

    this.props.onSubmit(value);
  };

  render() {
    console.log('mins', this.state.minutes);
    let selected = new Array(24);
    selected[this.state.hours] = 'selected';

    let hours = [];
    let am = this.state.am;

    for (let i = am ? 0 : 12; i <= (am ? 11 : 23); i++) {
      hours.push(
        <div
          key={i}
          className={selected[i]}
          onClick={this.selectHours.bind(null, i)}
        >
          {displayTime(i)}
        </div>
      );
    }

    const timePicker = (
      <div className="calendar-overlay date-picker-container blackout">
        <div className="date-picker">
          <div className="time-picker">
            <div className="state-hours">
              {this.state.hours}:
              {this.state.minutes == 0 ? '00' : this.state.minutes}
            </div>
            <div className="hours am-pm">
              <div
                className={this.state.am ? 'selected' : undefined}
                onClick={this.selectAm}
              >
                am
              </div>
              <div
                className={this.state.am ? undefined : 'selected'}
                onClick={this.selectPm}
              >
                pm
              </div>
            </div>
            <div className="hours">{hours}</div>
            <div className="options">
              <div className="hours minutes">
                <div
                  onClick={this.selectMinutes.bind(null, '00')}
                  className={this.state.minutes === '00' ? 'selected' : ''}
                >
                  00
                </div>
                <div
                  onClick={this.selectMinutes.bind(null, '15')}
                  className={this.state.minutes === '15' ? 'selected' : ''}
                >
                  15
                </div>
                <div
                  onClick={this.selectMinutes.bind(null, '30')}
                  className={this.state.minutes === '30' ? 'selected' : ''}
                >
                  30
                </div>
                <div
                  onClick={this.selectMinutes.bind(null, '45')}
                  className={this.state.minutes === '45' ? 'selected' : ''}
                >
                  45
                </div>
              </div>
              <div className="submit-time-picker" onClick={this.submit}>
                Done
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    return timePicker;
  }
}

export default TimePicker;
