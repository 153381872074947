import React, { Component } from 'react';
import jwt from 'jsonwebtoken';
import '../css/restaurantselector.css';
import { selectRestaurant } from '../api/auth';
import getRestaurants from '../api/restaurants';

class RestaurantSelector extends Component {
  state = { restaurants: [] };

  componentWillMount() {
    const { restaurants, level } = jwt.decode(this.props.token);

    if (level === 'super_admin') {
      getRestaurants(this.props.token)
        .then((response) => response.text())
        .then((data) => this.setState({ restaurants: JSON.parse(data) }));
    } else {
      this.setState({ restaurants });
    }
  }

  clickRestaurant = async (restaurant) => {
    const response = await selectRestaurant(restaurant._id, this.props.token);

    if (response.status === 200)
      return response.text().then((jwt) => {
        this.props.onSetJWT(jwt);
      });

    console.log('Server error');
  };

  render() {
    const list = this.state.restaurants.map((restaurant) => (
      <div
        key={restaurant._id}
        className="restaurant-name"
        onClick={() => this.clickRestaurant(restaurant)}
      >
        {restaurant.name}
      </div>
    ));

    return (
      <div className="restaurant-selector-wrapper">
        <div className="window">
          <p>Choose one of your restaurants.</p>
          <div className="restaurant-selector">{list}</div>
        </div>
      </div>
    );
  }
}

export default RestaurantSelector;
