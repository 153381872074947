async function registerListener(token) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", "x-auth-token": token },
  };

  return await fetch(
    "https://evening-eyrie-53360.herokuapp.com/api/listeners",
    requestOptions
  );
}

async function getListenerChanges(token, listenerId) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
      "x-listener-id": listenerId,
    },
  };

  let response = await fetch(
    "https://evening-eyrie-53360.herokuapp.com/api/listeners",
    requestOptions
  );
  response = JSON.parse(await response.text());

  return response;
}

const _registerListener = registerListener;
export { _registerListener as registerListener };

const _getListenerChanges = getListenerChanges;
export { _getListenerChanges as getListenerChanges };
