import React, { Component } from "react";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import CheckIcon from "@material-ui/icons/Check";
import StatusButton from "./statusbutton";

const clone = require("rfdc")();

class Table extends Component {
  deleteEvent = (e) => {
    e.stopPropagation();
  };

  render() {
    // if the expanded table view give the on click event to submit a new table to move someone onto.
    let click_event = "";
    if (this.props.view === "selectTable") {
      click_event = () =>
        this.props.onToggleSelectTable(this.props.table.name, this.props.table);
    } else {
      click_event = () =>
        this.props.onTableBookingView([this.props.table.name]);
    }

    if (this.props.state.persistentButton === "Custom") {
      click_event = this.deleteEvent;
    }

    // all the bookings on this table
    let this_tables_bookings = this.props.state.bookings.filter((booking) =>
      booking.table.includes(this.props.table.name)
    );
    //and sort them into time ascending order
    this_tables_bookings.sort((a, b) => a.time - b.time);

    // find and add the booking's phase to each booking object in this_tables_bookings list
    const statuses = this.props.state.statuses;
    this_tables_bookings.forEach((booking) => {
      const status_list = statuses.find(
        (statuses) => statuses._id === booking.statusesId
      ).list;
      booking.phase = status_list.find(
        (status) => status._id === booking.statusId
      ).phase;
    });

    // create the list of all phase 1 bookings for the table. Map it to a time to display on the table.
    let this_tables_bookings_list = this_tables_bookings
      .filter((booking) => booking.phase === 1)
      .map((booking) => (
        <p
          key={booking._id}
          className={booking.table_assigned ? "assigned" : "unassigned"}
        >
          {booking.table.length > 1 ? booking.time + "+" : booking.time}
        </p>
      ));

    let table_info = this_tables_bookings_list;
    table_info.length = 3;

    const currently_sat = this_tables_bookings.find(
      (booking) => booking.phase === 2
    );
    if (currently_sat) {
      table_info.length = 2;

      // const status_button = (
      //   <StatusButton
      //     key={currently_sat._id}
      //     bookingId={currently_sat._id}
      //     table={[this.props.table.name]}
      //     state={this.props.state}
      //   />
      // );
      let css = "status-button";

      let currentMins = this.props.state.tableSatTimes.find(
        (table) => table.name === this.props.table.name
      );
      let minutes = currentMins;
      if (minutes) {
        let turntime =
          parseInt(currently_sat.usable_end_time / 100) * 60 +
          (currently_sat.usable_end_time % 100) -
          parseInt(currently_sat.time / 100) * 60 +
          (currently_sat.time % 100);
        turntime - currentMins.minutes < 16 && (css = "status-button near");

        turntime - currentMins.minutes < 1 && (css = "status-button over");
        minutes = minutes.minutes;
        if (minutes > 59) {
          minutes = parseInt(minutes / 60) + "h" + (minutes % 60) + "m";
        } else {
          minutes = minutes + "m";
        }
      } else {
        minutes = "0m";
      }

      const status_button = <div className={css}>{minutes && minutes}</div>;

      table_info.unshift(status_button);
    }
    let selectedTable = "";

    let count = 0;
    let totalAvailable = [];

    if (this.props.selectedTables) {
      count = this.props.selectedTables.filter((t) =>
        this.props.tables.find(
          (tab) => tab.name === t && tab.online === this.props.table.online
        )
      ).length;
      totalAvailable = this.props.totalAvailable.filter(
        (t) => t.online === this.props.table.online
      );
    }

    if (
      this.props.view === "selectTable" &&
      this.props.state.selectedTables.includes(this.props.table.name)
    ) {
      selectedTable = (
        <div
          className={
            totalAvailable.length - count < 0 && this.props.state.addWalkIn
              ? "table-selected warning"
              : "table-selected"
          }
        >
          <CheckIcon />
        </div>
      );
    }

    return (
      <td className={this.props.tableClass} onClick={click_event}>
        <p className={this.props.table.online ? "regular" : "red"}>
          {this.props.table.name}
          {!this.props.table.online && <FlashOnIcon />}
        </p>

        <div className="list-holder">{this_tables_bookings_list}</div>
        {selectedTable}
      </td>
    );
  }
}

export default Table;
