import { useEffect } from 'react';
import { useState } from 'react';
import '../css/bookingdetails.css';

export function BookingDetails({
  booking,
  onAddBooking,
  togglePart,
  part,
  tags,
  templateInitials,
  displayDate,
}) {
  const [visible, setVisible] = useState(false);
  const [name, setName] = useState(booking.name);
  const [phone, setPhone] = useState(booking.phone);
  const [email, setEmail] = useState(booking.email);
  const [description, setDescription] = useState(booking.description);
  const [initials, setInitials] = useState(booking.initials);
  const [selectedTags, setSelectedTags] = useState(booking.tags);

  useEffect(() => {
    part === 2 ? setVisible(true) : setVisible(false);
  }, [part]);

  const dateFormat = require('dateformat');

  return (
    <div
      className={visible ? 'booking-modal' : 'booking-modal invisible'}
      id="booking-modal"
    >
      <div className="booking-details-holder">
        <span style={{ textAlign: 'center', width: '100%', marginBottom: 30 }}>
          Booking for <b> {booking.covers} people </b> at{' '}
          <b>
            {' '}
            {booking.time.toString().length === 3
              ? `0${booking.time}`
              : booking.time}{' '}
          </b>{' '}
          on <b> {dateFormat(displayDate, 'ddd dd mmm')} </b>
        </span>
        <div className="booking-details">
          <input
            name="name"
            className="mandatory"
            placeholder="Name"
            autocomplete="off"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            name="phone"
            className="mandatory"
            placeholder="Phone Number"
            autocomplete="off"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <input
            name="email"
            className=""
            placeholder="Email Address"
            autocomplete="off"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <div className="horizontal">
            <textarea
              name="notes"
              placeholder="additional notes"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <div className="tags">
              {tags.map((t) => {
                console.log(t._id, selectedTags);
                return (
                  <div
                    className={
                      selectedTags.includes(t._id)
                        ? 'detail tag selected'
                        : 'detail tag deselected'
                    }
                    key={t._id}
                    onClick={() => {
                      if (selectedTags.includes(t._id)) {
                        setSelectedTags(
                          selectedTags.filter((selected) => selected !== t._id)
                        );
                      } else {
                        const newSelected = [...selectedTags];
                        newSelected.push(t._id);
                        setSelectedTags(newSelected);
                      }
                    }}
                  >
                    <div
                      className="circle"
                      style={{ backgroundColor: t.color }}
                    ></div>
                    {t.text}
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className="initials-holder"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <input
              style={{
                width: 100,
                marginRight: 10,
                marginBottom: 0,
              }}
              name="name"
              className="mandatory"
              placeholder="Initials"
              autocomplete="off"
              value={initials}
              onChange={(e) => {
                setInitials(e.target.value.toUpperCase());
              }}
            />
            <div className="initials-options">
              {templateInitials.map((t) => (
                <div
                  key={t}
                  style={{
                    border:
                      initials === t
                        ? '2px solid #47aecc'
                        : '2px solid transparent',
                  }}
                  onClick={() => setInitials(t)}
                >
                  {t}
                </div>
              ))}
            </div>
          </div>
        </div>{' '}
        <div className="bottom-buttons">
          <div style={{ marginRight: 10 }} onClick={() => togglePart()}>
            Back
          </div>
          <div
            style={{ marginLeft: 10 }}
            className={!name || !phone || !initials ? 'deactivated' : undefined}
            onClick={() => {
              booking.name = name;
              booking.email = email;
              booking.phone = phone;
              booking.description = description;
              booking.initials = initials;
              booking.tags = selectedTags;
              console.log(booking);
              onAddBooking(booking);
            }}
          >
            Add Booking
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingDetails;
