async function getPublicRestaurant(slug) {
  const requestOptions = {
    method: "GET",
  };
  let data = await fetch(
    "https://evening-eyrie-53360.herokuapp.com/api/restaurants/public/" + slug,
    requestOptions
  );

  let result = JSON.parse(await data.text());

  return result;
}

async function getPublicSchedules(restId) {
  const requestOptions = {
    method: "GET",
  };

  let data = await fetch(
    "https://evening-eyrie-53360.herokuapp.com/api/schedules/public/" + restId,
    requestOptions
  );

  let result = JSON.parse(await data.text());

  for (let i = 0; result.length > i; i++) {
    result[i].startDate !== null &&
      (result[i].startDate = new Date(result[i].startDate));
    result[i].lastDate !== null &&
      (result[i].lastDate = new Date(result[i].lastDate));
  }

  return result;
}

async function getPublicTables(restId) {
  const requestOptions = {
    method: "GET",
  };

  let data = await fetch(
    "https://evening-eyrie-53360.herokuapp.com/api/tablesschedules/public/" +
      restId,
    requestOptions
  );

  let result = JSON.parse(await data.text());

  return result;
}

async function getPublicBookings(restId) {
  const requestOptions = {
    method: "GET",
  };

  let data = await fetch(
    "https://evening-eyrie-53360.herokuapp.com/api/bookings/public/" + restId,
    requestOptions
  );

  let result = JSON.parse(await data.text());

  for (let i = 0; result.length > i; i++) {
    result[i].date = new Date(result[i].date);
  }

  return result;
}

async function getPublicPacings(restId) {
  const requestOptions = {
    method: "GET",
  };

  let data = await fetch(
    "https://evening-eyrie-53360.herokuapp.com/api/pacingsschedules/public/" +
      restId,
    requestOptions
  );

  let result = JSON.parse(await data.text());

  return result;
}

async function getPublicStatuses(restId) {
  const requestOptions = {
    method: "GET",
  };

  let data = await fetch(
    "https://evening-eyrie-53360.herokuapp.com/api/statuses/public/" + restId,
    requestOptions
  );

  let result = JSON.parse(await data.text());

  return result;
}

async function postPublicBooking(booking) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(booking),
  };

  let data = await fetch(
    "https://evening-eyrie-53360.herokuapp.com/api/bookings/public/",
    requestOptions
  );

  return data;
}

// get rest from id
// get all schedules, table plans, bookings, pacings from id. No PII.

const _getPublicRestaurant = getPublicRestaurant;
export { _getPublicRestaurant as getPublicRestaurant };

const _getPublicSchedules = getPublicSchedules;
export { _getPublicSchedules as getPublicSchedules };

const _getPublicTables = getPublicTables;
export { _getPublicTables as getPublicTables };

const _getPublicBookings = getPublicBookings;
export { _getPublicBookings as getPublicBookings };

const _getPublicPacings = getPublicPacings;
export { _getPublicPacings as getPublicPacings };

const _getPublicStatuses = getPublicStatuses;
export { _getPublicStatuses as getPublicStatuses };

const _postPublicBooking = postPublicBooking;
export { _postPublicBooking as postPublicBooking };
