import React, { Component } from "react";

class SeatBooking extends Component {
  render() {
    const booking = this.props.state.bookings.find(
      (booking) => booking._id === this.props.bookingId
    );
    return (
      <div
        className="calendar-overlay seat-booking-container blackout"
        onClick={() => this.props.onToggleSeatBooking(null)}
      >
        <div className="seat-booking">
          <div className="header">
            Seat {booking.name} on {booking.table.join(", ")}?
          </div>
          <div className="buttons">
            <div>Change Table</div>
            <div className="submit-new" onClick={this.props.onSeatBooking}>
              Yes
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SeatBooking;
