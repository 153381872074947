import React, { Component } from "react";
import TableSection from "./tablesection";
import TableSelectControls from "./tableselectcontrols";

class Tables extends Component {
  deleteEvent = (e) => {
    e.stopPropagation();
  };

  renderTime = () => {
    // if editing return editing time
    // if booking return booking time
    // if today return 1200 (curr time)
    // if not today, return 0,
    try {
      if (this.props.state.addBooking && this.props.state.newBooking.time)
        return this.props.state.newBooking.time;
      if (this.props.state.editBooking && this.props.state.newBooking.time)
        return this.props.state.newBooking.time;
      const today = new Date();
      const displayDate = this.props.state.displayDate;
      if (
        displayDate.getDate() == today.getDate() &&
        displayDate.getMonth() == today.getMonth() &&
        displayDate.getYear() == today.getYear()
      )
        return today.getHours() * 100 + today.getMinutes();
    } catch (err) {
      return 0;
    }
    return 0;
  };

  render() {
    // Grab schedule
    const schedule = this.props.onGetSchedule(this.props.state.displayDate);
    const day = this.props.state.displayDate.getDay();
    let tablesIds = schedule.days.find((obj) => obj.day === day).tablesIds;

    const renderTime = this.renderTime();
    let tablesId = tablesIds.filter((obj) => obj.time <= renderTime);
    tablesId = tablesId[tablesId.length - 1].tablesId;

    let tables = this.props.state.tablesSchedules.find(
      (tables) => tables._id === tablesId
    ).tables;
    if (tables === undefined) {
      tables = [{ name: "t1", covers: "2" }];
    }

    const distinctTableSizes = [
      ...new Set(tables.map((tableSize) => tableSize.covers)),
    ];
    distinctTableSizes.unshift("C");
    let fullTargetBooking = this.props.state.bookings.find(
      (booking) => booking._id === this.props.state.targetBooking
    );

    let top_panel = "";

    if (this.props.state.selectingTable && !this.props.state.addWalkIn) {
      top_panel = (
        <TableSelectControls
          onCustomChange={this.props.onCustomChange}
          fullTargetBooking={fullTargetBooking}
          state={this.props.state}
          onGetSchedule={this.props.onGetSchedule}
          onBookingTable={this.props.onBookingTable}
          onBookingManualTable={this.props.onBookingManualTable}
          onToggleSelectTable={this.props.onToggleSelectTable}
          onClearSelectedTables={this.props.onClearSelectedTables}
          onChangePersistentButton={this.props.onChangePersistentButton}
          onInitializeTableSelectButtons={
            this.props.onInitializeTableSelectButtons
          }
        />
      );
    }

    let expanded_tables = distinctTableSizes.map((tableSize) => (
      <TableSection
        key={tableSize}
        sectionsize={tableSize}
        state={this.props.state}
        tables={tables}
        onTableBookingView={this.props.onTableBookingView}
        view="page"
        onToggleSelectTable={this.props.onToggleSelectTable}
        onNewBookingDetails={this.props.onNewBookingDetails}
      />
    ));

    let horizonatal_tables = (
      <table className="tables">
        <tbody>
          <tr>
            {distinctTableSizes.map((tableSize) => (
              <TableSection
                onTableBookingView={this.props.onTableBookingView}
                key={tableSize}
                sectionsize={tableSize}
                state={this.props.state}
                tables={tables}
                view="horizontal"
              />
            ))}
          </tr>
        </tbody>
      </table>
    );

    let combined = (
      <div
        className={
          this.props.state.tablesViewState[0] ===
            "tablescontainer opentableview" && !this.props.state.addWalkIn
            ? "blackout"
            : ""
        }
        style={{ zIndex: "1000" }}
        onClick={this.props.onTableViewState}
      >
        {this.props.state.targetBooking &&
          this.props.state.tablesViewState[0] ===
            "tablescontainer opentableview" &&
          top_panel}
        <div
          className={this.props.state.tablesViewState}
          onClick={this.deleteEvent}
        >
          {expanded_tables}
          {horizonatal_tables}
        </div>
      </div>
    );

    return combined;
  }
}

export default Tables;
