async function getAllTablesSchedules(token) {
  const requestOptions = {
    method: "GET",
    headers: { "x-auth-token": token },
  };

  let data = await fetch(
    "https://evening-eyrie-53360.herokuapp.com/api/tablesschedules",
    requestOptions
  );

  return JSON.parse(await data.text());
}

async function putTablesSchedule(token, tablesSchedule) {
  try {
    const requestOptions = {
      method: "PUT",
      headers: { "x-auth-token": token, "Content-Type": "application/json" },
      body: JSON.stringify(tablesSchedule),
    };

    let data = await fetch(
      "https://evening-eyrie-53360.herokuapp.com/api/tablesschedules/" +
        tablesSchedule._id,
      requestOptions
    );

    if (!data.ok) {
      data.text().then((res) => {
        console.log(data.status + " " + data.statusText + " " + res);
      });

      console.log("fail");
      return false;
    }

    let result = JSON.parse(await data.text());

    console.log("success!");
    return result;
  } catch (err) {
    console.log(err.message);

    console.log("fail");
    return false;
  }
}

async function postTablesSchedule(token, tablesSchedule) {
  try {
    const requestOptions = {
      method: "POST",
      headers: { "x-auth-token": token, "Content-Type": "application/json" },
      body: JSON.stringify(tablesSchedule),
    };

    let data = await fetch(
      "https://evening-eyrie-53360.herokuapp.com/api/tablesschedules",
      requestOptions
    );

    if (!data.ok) {
      data.text().then((res) => {
        console.log(data.status + " " + data.statusText + " " + res);
      });
      return false;
    }

    let result = JSON.parse(await data.text());

    // convert all the dates into JS Date() objects...

    return result;
  } catch (err) {
    console.log(err.message);
    return false;
  }
}

const _getAllTablesSchedules = getAllTablesSchedules;
export { _getAllTablesSchedules as getAllTablesSchedules };

const _postTablesSchedule = postTablesSchedule;
export { _postTablesSchedule as postTablesSchedule };

const _putTablesSchedule = putTablesSchedule;
export { _putTablesSchedule as putTablesSchedule };
