async function getRestaurants(token) {
  const requestOptions = {
    method: "GET",
    headers: { "x-auth-token": token },
  };

  const restaurants = await fetch(
    "https://evening-eyrie-53360.herokuapp.com/api/restaurants/",
    requestOptions
  );

  return restaurants;
}

export default getRestaurants;
