import React, { Component } from "react";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import NumPad from "./numpad";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import AirlineSeatReclineNormalIcon from "@material-ui/icons/AirlineSeatReclineNormal";
import HourglassEmptyRoundedIcon from "@material-ui/icons/HourglassEmptyRounded";

const clone = require("rfdc")();

class AddWalkIn extends Component {
  state = {
    show_numpad: false,
    turn_time: { hours: 1, mins: 30 },
    schedule_day: null,
    focus: "",

    quickOverwrite: false,
    timeOverwrite: false,

    booking: {
      covers: false,
      time: false,
      table: false,
      name: "Walk-In",
      phone: "",
      email: "",
      tags: [],
      description: "",
      default_turntime: true,
      statusesId: 0,
      statusId: null,
      phase: 2,
      statusesDefault: true,
      status_changed: null,
      table_assigned: false,
      history: [{ statusId: 1, date: new Date(), phase: 1 }],
      walkIn: true,
      // **** turntime: 90,
      // **** end_time: 1400,
      // **** projected_end_time: 1400,
      // **** usable_end_time: 1415,
      // **** manual_end_time: 1415,
    },
  };

  deleteEvent = (e) => {
    e.stopPropagation();
  };
  componentWillMount = () => {
    const date = this.props.state.displayDate;
    const schedule = this.props.onGetSchedule(date);
    const schedule_day = schedule.days.find((day) => day.day === date.getDay());
    const booking = this.state.booking;
    booking.statusesId = schedule_day.statusesId;
    booking.date = date;
    this.setState({ schedule_day, booking });
  };

  componentDidUpdate = () => {
    if (
      this.props.state.newBooking.quickOverwrite &&
      this.props.state.newBooking.quickOverwrite !== this.state.quickOverwrite
    ) {
      let turn_time = {
        hours: parseInt(this.props.state.newBooking.quick / 60),
        mins: this.props.state.newBooking.quick % 60,
      };
      this.setState({
        turn_time,
        quickOverwrite: this.props.state.newBooking.quickOverwrite,
      });
    }

    if (
      this.props.state.newBooking.quickOverwrite === undefined &&
      this.state.quickOverwrite
    ) {
      const quickOverwrite = false;
      const mins = this.state.booking.turntime;
      const turn_time = { hours: parseInt(mins / 60), mins: mins % 60 };

      this.setState({ quickOverwrite, turn_time });
    }
  };

  componentDidMount = () => {
    this.props.onNewBookingDetails({ table: [] });
  };

  buildBooking = () => {
    let booking = this.state.booking;
    let newBooking = this.props.state.newBooking;
    booking.table = this.props.state.selectedTables;
    booking.table_assigned = true;

    if (newBooking.option === "quick") {
      booking.turntime = newBooking.quick;
      booking.description =
        "Given " +
        newBooking.quick +
        "mins at " +
        newBooking.time +
        ", vacate by " +
        newBooking.quickOverwrite +
        ". " +
        booking.description;
      booking.end_time =
        booking.projected_end_time =
        booking.usable_end_time =
        booking.manual_end_time =
          newBooking.quickOverwrite;
    }

    if (newBooking.option === "wait") {
      const endMins =
        parseInt(newBooking.timeOverwrite / 100) * 60 +
        (newBooking.timeOverwrite % 100) +
        booking.turntime;
      const end = parseInt(endMins / 60) * 100 + (endMins % 60);

      booking.time = newBooking.timeOverwrite;
      booking.table_assigned = false;
      booking.description =
        "Quoted " +
        newBooking.wait +
        "mins at " +
        newBooking.time +
        ". " +
        booking.description;
      booking.end_time =
        booking.projected_end_time =
        booking.usable_end_time =
        booking.manual_end_time =
          end;
      booking.phase = 1;
      booking.history.length = 1;
      booking.statusId = booking.history[0].statusId;
      if (newBooking.table.length > 0) {
        booking.table = newBooking.table;
        booking.table_assigned = true;
      }
    }
    this.props.onAddBooking(booking);
  };

  handleQuickOverwrite = (turn_time) => {
    let quick_overwrite = true;
    turn_time = { hours: turn_time.hours, mins: turn_time.mins };
    const booking = this.state.booking;
    booking.turntime = turn_time.hours * 60 + turn_time.mins;
    booking.default_turntime = false;
    booking.manual_end_time =
      booking.time + turn_time.hours * 100 + turn_time.mins;
    booking.usable_end_time = booking.manual_end_time;
    this.setState({ turn_time, quick_overwrite, booking });
  };
  handleTimeOverwrite = (time) => {
    let time_overwrite = true;
    const booking = this.state.booking;
    booking.time = time;
    const turn_time = this.state.turn_time;
    const end_time = booking.time + turn_time.hours * 100 + turn_time.mins;

    booking.end_time = end_time;
    booking.projected_end_time = end_time;
    booking.usable_end_time = end_time;
    booking.manual_end_time = false;

    this.setState({ booking, time_overwrite });
  };

  handleSelectAuto = () => {
    const booking = this.state.booking;
    booking.table_assigned = false;
    this.setState({ booking });
  };

  handleCovers = (covers) => {
    const booking = this.state.booking;
    booking.covers = covers;
    booking.default_turntime = true;
    const date = this.props.state.displayDate;

    const schedule_day = this.props
      .onGetSchedule(date)
      .days.find((day) => day.day === date.getDay());

    let statuses = this.props.state.statuses.find(
      (status) => status._id === schedule_day.statusesId
    );

    const sat = statuses.list.filter(status => status.phase === 2);

    booking.statusId = sat[0]._id;
    booking.history[0].statusId = statuses.list[0]._id;

    booking.history.push({
      statusId: sat[0]._id,
      date: new Date(),
      phase: 2,
    });

    const turnTimeMins = statuses.turnTimeTotal
      .filter((total) => total.tableSize <= covers)
      .sort((a, b) => (a.tableSize > b.tableSize ? -1 : 1))[0].time;

    booking.turntime = turnTimeMins;
    let turn_time = {
      hours: parseInt(turnTimeMins / 60),
      mins: turnTimeMins % 60,
    };

    // duplicated logic on covers and time select

    const now = new Date();
    booking.time = now.getHours() * 100 + now.getMinutes();

    let end_time =
      parseInt(booking.time / 100) * 60 +
      (booking.time % 100) +
      turn_time.hours * 60 +
      turn_time.mins;
    end_time = parseInt(end_time / 60) * 100 + (end_time % 60);

    booking.end_time = end_time;
    booking.projected_end_time = end_time;
    booking.usable_end_time = end_time;
    booking.manual_end_time = false;

    let newBooking = this.props.state.newBooking;
    newBooking.covers = covers;
    newBooking.turntime = turnTimeMins;
    newBooking.usable_end_time = end_time;
    newBooking.time = booking.time;
    newBooking.table = [];
    this.props.onNewBookingDetails(newBooking);

    this.setState({
      booking,
      show_numpad: false,
      turn_time,
      schedule_day,
    });
  };
  handleNumpad = () => {
    this.setState({ show_numpad: true });
  };
  handleTime = (time) => {
    let newBooking = this.props.state.newBooking;
    newBooking.time = time;
    this.props.onNewBookingDetails(newBooking);

    const booking = this.state.booking;
    booking.time = time;
    const turn_time = this.state.turn_time;
    let end_time = booking.time + turn_time.hours * 100 + turn_time.mins;

    end_time = parseInt(end_time / 100) * 60 + (end_time % 100);
    end_time = parseInt(end_time / 60) * 100 + (end_time % 60);

    booking.end_time = end_time;
    booking.projected_end_time = end_time;
    booking.usable_end_time = end_time;
    booking.manual_end_time = false;

    this.setState({ booking, show_timepicker: false, time_overwrite: false });
  };

  setFocus = (focus) => {
    this.setState({ focus });
  };

  changeNotes = (e) => {
    const booking = this.state.booking;
    booking.description = e.target.value;
    this.setState({ booking });
  };

  render() {
    let manual_table = [];
    if (this.state.booking.table === "manual") {
      manual_table = this.props.state.newBooking.table;
    }

    let notes_value = this.state.booking.description;
    if (notes_value === "" && this.state.focus !== "notes") {
      notes_value = "Additional Notes";
    }

    let notes = (
      <React.Fragment>
        <div className="notes selected">
          <textarea
            name="notes"
            value={notes_value}
            onFocus={() => this.setFocus("notes")}
            onBlur={() => this.setFocus("")}
            onChange={(e) => this.changeNotes(e)}
            className={
              this.state.booking.description === "" ? "italic" : "filled"
            }
          ></textarea>
        </div>
      </React.Fragment>
    );

    let covers = <GroupAddIcon />;
    if (this.state.booking.covers > 0) {
      covers = (
        <div className={this.state.quick_overwrite ? "covers quick" : "covers"}>
          {this.state.booking.covers}
          <p>
            {this.state.turn_time.hours +
              "hr " +
              this.state.turn_time.mins +
              "m"}
          </p>
        </div>
      );
    }
    let time = "";
    if (this.state.timeOverwrite > 0) {
      time = this.state.timeOverwrite;
    }
    let table = false;
    this.state.booking.table_assigned !== null && (table = true);

    let numpad = "";
    if (this.state.show_numpad) {
      numpad = <NumPad maxDigits={2} number={1} onNumber={this.handleCovers} />;
    }

    let tableOption = (
      <React.Fragment>
        <AirlineSeatReclineNormalIcon /> <DirectionsRunIcon />
        <HourglassEmptyRoundedIcon />
      </React.Fragment>
    );

    if (this.props.state.newBooking.option === "seat") {
      let selectedTables = "Select Table";
      if (this.props.state.selectedTables.length > 0)
        selectedTables = this.props.state.selectedTables.join(", ");
      tableOption = (
        <React.Fragment>
          <AirlineSeatReclineNormalIcon />
          <p>{selectedTables}</p>
        </React.Fragment>
      );
    }

    if (this.props.state.newBooking.option === "wait") {
      const selectedTables = this.props.state.selectedTables.join(", ");
      const on = (
        <React.Fragment>
          <span> on </span>
          {selectedTables}
        </React.Fragment>
      );
      tableOption = (
        <React.Fragment>
          <HourglassEmptyRoundedIcon />
          <p>
            {this.props.state.newBooking.wait}m{selectedTables ? on : ""}
          </p>
          <p>
            <span>Ready by</span> {this.props.state.newBooking.timeOverwrite}
          </p>
        </React.Fragment>
      );
    }

    if (this.props.state.newBooking.option === "quick") {
      const selectedTables = this.props.state.selectedTables.join(", ");
      const on = (
        <React.Fragment>
          <span> on </span>
          {selectedTables}
        </React.Fragment>
      );
      tableOption = (
        <React.Fragment>
          <DirectionsRunIcon />
          <p>
            {this.props.state.newBooking.quick}m{selectedTables ? on : ""}
          </p>
          <p>
            <span>Vacate by</span> {this.props.state.newBooking.quickOverwrite}
          </p>
        </React.Fragment>
      );
    }

    let saveButtonText = "Seat";
    let saveReady = false;

    if (
      (this.props.state.newBooking.option === "seat" &&
        this.props.state.selectedTables.length > 0) ||
      this.props.state.newBooking.option === "wait" ||
      (this.props.state.newBooking.option === "quick" &&
        this.props.state.selectedTables.length > 0)
    )
      saveReady = true;

    this.props.state.newBooking.option === "wait" && (saveButtonText = "Queue");

    return (
      <React.Fragment>
        <div className="add-booking-holder">
          <div className="wrapper">
            <div
              className={
                this.state.booking.covers ? "covers selected" : "covers"
              }
            >
              <div className="content" onClick={this.handleNumpad}>
                {covers}
              </div>
            </div>
            <div className={saveReady ? "time selected" : "time"}>
              <div
                className={
                  (this.state.booking.timeOverwrite
                    ? "content overwrite"
                    : "content") +
                  (this.props.state.newBooking.option ? " option-selected" : "")
                }
              >
                {tableOption}
              </div>
            </div>
            {notes}
            <div className="next">
              <div
                className={saveReady ? "" : "grey"}
                onClick={this.buildBooking}
              >
                {saveButtonText}
              </div>
              <div onClick={this.props.onToggleAddWalkIn}>Cancel</div>
            </div>
          </div>
        </div>
        {numpad}
      </React.Fragment>
    );
  }
}

export default AddWalkIn;
