import React, { Component } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import "./../css/newtableplan.css";

class EditTablePlan extends Component {
  state = {
    tables: null,
    _id: 0,
    name: "",
    display: [],
    newSizeOther: "other",
    selectedNewSizes: [],
    showAddTables: false,
    greySave: "schedule-new-button grey-out",
  };
  constructor(props) {
    super(props);

    if (this.props.tablePlan._id === "new") {
      this.state = {
        _id: "new",
        name: "Table Plan Name",
        tables: [],
        display: [],
        newSizeOther: "other",
        selectedNewSizes: [],
        showAddTables: false,
        greySave: "schedule-new-button grey-out",
      };
    } else if (this.props.tablePlan._id === "duplicate") {
      let copyOfTablePlan = this.props.tablePlan;
      copyOfTablePlan._id = "new";
      copyOfTablePlan.name = "Copy of " + copyOfTablePlan.name;
      const duplicateState = this.setTablePlan(copyOfTablePlan);
      this.state = {
        _id: duplicateState._id,
        name: duplicateState.name,
        tables: duplicateState.tables,
        display: [],
        newSizeOther: "other",
        selectedNewSizes: [],
        showAddTables: false,
        greySave: "schedule-new-button grey-out",
      };
    } else {
      const editState = this.setTablePlan(this.props.tablePlan);
      this.state = {
        _id: editState._id,
        name: editState.name,
        tables: editState.tables,
        display: [],
        newSizeOther: "other",
        selectedNewSizes: [],
        showAddTables: false,
        greySave: "schedule-new-button grey-out",
      };
    }
  }
  componentDidUpdate = () => {
    if (this.state.greySave !== "schedule-new-button") {
      this.setState({ greySave: "schedule-new-button" });
    }
  };
  /*componentDidMount = () => {
    let nextId = this.props.state.tablesSchedules.reduce((max, tablesId) =>
      max._id > tablesId._id ? max : tablesId
    );
    nextId = nextId._id + 1;
    if (this.props.tablePlan._id === "new") {
      this.setState({
        id: nextId,
        name: "Table Plan Name",
        tables: []
      });
    } else if (this.props.tablePlan._id === "duplicate") {
      let copyOfTablePlan = this.props.tablePlan;
      copyOfTablePlan._id = nextId;
      copyOfTablePlan.name = "Copy of " + copyOfTablePlan.name;
      this.setTablePlan(copyOfTablePlan);
    } else {
      this.setTablePlan(this.props.tablePlan);
    }
  };*/
  addTables = () => {
    let newSizes = [...this.state.selectedNewSizes];
    if (this.state.newSizeOther !== "other") {
      newSizes.push(parseInt(this.state.newSizeOther));
    }
    newSizes = newSizes.filter(
      (newSize) => !this.state.tables.find((x) => x.covers === newSize)
    );
    let tables = this.state.tables;
    for (let i = 0; i < newSizes.length; i++) {
      const obj = { covers: newSizes[i], online: "", offline: "" };
      tables.push(obj);
    }
    const showAddTables = false;
    const newSizeOther = "other";
    const selectedNewSizes = [];
    tables = tables.sort((a, b) => (a.covers > b.covers ? 1 : -1));
    this.setState({ tables, showAddTables, newSizeOther, selectedNewSizes });
  };
  showAddTables = () => {
    this.setState({ showAddTables: true });
  };
  selectNewSize = (size) => {
    let newSizes = this.state.selectedNewSizes;
    if (newSizes.includes(size)) {
      newSizes = newSizes.filter((oldSize) => {
        return oldSize !== size;
      });
      this.setState({ selectedNewSizes: newSizes });
    } else {
      newSizes.push(size);
      this.setState({ selectedNewSizes: newSizes });
    }
  };
  newSizeOtherChange = (e) => {
    this.setState({ newSizeOther: e.target.value });
  };
  newSizeOtherClick = () => {
    if (this.state.newSizeOther === "other") {
      this.setState({ newSizeOther: "" });
    }
  };
  newSizeOtherBlur = () => {
    if (this.state.newSizeOther === "") {
      this.setState({ newSizeOther: "other" });
    }
  };
  setTablePlan = (tablePlan) => {
    const tableSizes = [
      ...new Set(tablePlan.tables.map((table) => table.covers)),
    ];
    let tables = [];
    for (let i = 0; i < tableSizes.length; i++) {
      const online = tablePlan.tables
        .filter((table) => {
          return table.covers === tableSizes[i] && table.online === true;
        })
        .map((tables) => tables.name)
        .join(" ");
      const offline = tablePlan.tables
        .filter((table) => {
          return table.covers === tableSizes[i] && table.online === false;
        })
        .map((tables) => tables.name)
        .join(" ");

      const obj = { covers: tableSizes[i], online: online, offline: offline };
      tables.push(obj);
    }

    return {
      tables: tables,
      _id: tablePlan._id,
      name: tablePlan.name,
    };
  };
  handleTablesChange = (tableSize, online, e) => {
    let newTables = this.state.tables;

    if (online) {
      newTables.find((x) => x.covers === tableSize).online = e.target.value;
    } else {
      newTables.find((x) => x.covers === tableSize).offline = e.target.value;
    }
    this.setState({ tables: newTables });
  };
  handleNameChange = (e) => {
    this.setState({ name: e.target.value });
  };

  handleSubmit = () => {
    return null;
  };

  render() {
    const newTablePlan = {
      _id: this.state._id,
      name: this.state.name,
      tables: this.state.tables,
    };

    let tableTextAreas = "";
    if (newTablePlan.tables !== null && newTablePlan.tables !== []) {
      tableTextAreas = newTablePlan.tables.map((tablesBySize) => (
        <div className="double-row labeled">
          <p>{tablesBySize.covers}s</p>
          <textarea
            rows="3"
            value={tablesBySize.online}
            onChange={this.handleTablesChange.bind(
              this,
              tablesBySize.covers,
              true
            )}
          ></textarea>
          <textarea
            rows="3"
            value={tablesBySize.offline}
            onChange={this.handleTablesChange.bind(
              this,
              tablesBySize.covers,
              false
            )}
          ></textarea>
        </div>
      ));
    }

    const selectedCss = [
      "button",
      "button",
      "button",
      "button",
      "button",
      "button",
      "button",
      "button",
      "other",
      "button tick",
    ];
    if (this.state.newSizeOther !== "" && this.state.newSizeOther !== "other") {
      selectedCss[8] = "other selected";
      selectedCss[9] = "button tick active";
    }
    for (let i = 0; i < this.state.selectedNewSizes.length; i++) {
      selectedCss[this.state.selectedNewSizes[i] - 1] = "button selected";

      selectedCss[9] = "button tick active";
    }
    let newTableSize = (
      <div className="schedule-modify-button" onClick={this.showAddTables}>
        <AddCircleIcon />
        new table size
      </div>
    );
    if (this.state.showAddTables) {
      newTableSize = (
        <div className="new-table-size">
          <div
            className={selectedCss[0]}
            onClick={this.selectNewSize.bind(null, 1)}
          >
            1
          </div>
          <div
            className={selectedCss[1]}
            onClick={this.selectNewSize.bind(null, 2)}
          >
            2
          </div>
          <div
            className={selectedCss[2]}
            onClick={this.selectNewSize.bind(null, 3)}
          >
            3
          </div>
          <div
            className={selectedCss[3]}
            onClick={this.selectNewSize.bind(null, 4)}
          >
            4
          </div>
          <div
            className={selectedCss[4]}
            onClick={this.selectNewSize.bind(null, 5)}
          >
            5
          </div>
          <div
            className={selectedCss[5]}
            onClick={this.selectNewSize.bind(null, 6)}
          >
            6
          </div>
          <div
            className={selectedCss[6]}
            onClick={this.selectNewSize.bind(null, 7)}
          >
            7
          </div>
          <div
            className={selectedCss[7]}
            onClick={this.selectNewSize.bind(null, 8)}
          >
            8
          </div>
          <div className={selectedCss[8]}>
            <input
              value={this.state.newSizeOther}
              onClick={this.newSizeOtherClick}
              onBlur={this.newSizeOtherBlur}
              onChange={this.newSizeOtherChange}
            />
          </div>
          <div className={selectedCss[9]} onClick={this.addTables}>
            <CheckRoundedIcon />
          </div>
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className="options-title">Edit Table Plan</div>
        <div style={{ display: "flex" }}>
          <div
            className={this.state.greySave}
            onClick={this.props.onSave.bind(null, newTablePlan)}
          >
            <CheckCircleRoundedIcon />
            Save
          </div>
          <div className="schedule-new-button" onClick={this.props.onCancel}>
            <CancelRoundedIcon />
            Cancel
          </div>
        </div>
        <form onSubmit={this.handleSubmit} className="edit-table-plans">
          <input
            type="text"
            value={this.state.name}
            onChange={this.handleNameChange}
          />

          <div className="edit-schedule-pt">
            <div className="label-bookable">Bookable</div>
            <div className="label-offline">Offline</div>
            {tableTextAreas}
            {newTableSize}
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default EditTablePlan;
