import React, { Component } from "react";

class NumPad extends Component {
  constructor(props) {
    super(props);

    const number = this.props.number;
    const maxDigits = this.props.maxDigits;
    this.state = { number, maxDigits, firstPress: true };
  }

  addNumber = (number) => {
    if (String(this.state.number).length < this.state.maxDigits) {
      if (this.state.firstPress) {
        this.setState({ firstPress: false, number });
      } else {
        number = parseInt(String(this.state.number) + String(number));
        this.setState({ number });
      }
    }
  };
  delete = () => {
    this.setState({ number: 0 });
  };
  submit = () => {};

  render() {
    let keys = [];

    for (let i = 1; i <= 9; i += 1) {
      keys.push(
        <div key={i} onClick={this.addNumber.bind(null, i)}>
          {i}
        </div>
      );
    }

    keys.push(
      <div key="0" onClick={this.addNumber.bind(null, 0)}>
        {0}
      </div>
    );

    const timePicker = (
      <div className="calendar-overlay date-picker-container blackout">
        <div className="keypad">
          <div className="time-picker">
            <div className="state-hours">{this.state.number}</div>
            <div className="numbers">{keys}</div>
            <div className="options">
              <div className="hours minutes" onClick={this.delete}>
                <div>clear</div>
              </div>
              <div
                className="submit-time-picker"
                onClick={this.props.onNumber.bind(null, this.state.number)}
              >
                Done
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    return timePicker;
  }
}

export default NumPad;
