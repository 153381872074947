import React, { Component } from "react";
import Table from "./table";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import AirlineSeatReclineNormalIcon from "@material-ui/icons/AirlineSeatReclineNormal";
import HourglassEmptyRoundedIcon from "@material-ui/icons/HourglassEmptyRounded";
import EmojiObjectsOutlinedIcon from "@material-ui/icons/EmojiObjectsOutlined";
import { CodeOutlined, CollectionsBookmarkOutlined } from "@material-ui/icons";

const clone = require("rfdc")();

class TableSection extends Component {
  state = {
    totalAvailable: [],
    allWaitTimes: [],
    allQuickTimes: [],
  };

  setWalkInOption = (option) => {
    //option = {option: "quick", value: 93}

    const booking = this.props.state.newBooking;
    booking.table = [];

    if (
      booking.option === option.option &&
      (booking.option === "seat" ||
        (booking.wait && booking.wait === option.value) ||
        (booking.quick && booking.quick === option.value))
    ) {
      delete booking.option;
      delete booking.timeOverwrite;
      delete booking.quickOverwrite;
      delete booking.quick;
      delete booking.wait;
      return this.props.onNewBookingDetails(booking);
    }
    delete booking.timeOverwrite;
    delete booking.quickOverwrite;
    delete booking.quick;
    delete booking.wait;

    booking.option = option.option;

    if (booking.option === "wait") {
      let timeOverwrite =
        parseInt(booking.time / 100) * 60 + (booking.time % 100) + option.value;
      timeOverwrite = parseInt(timeOverwrite / 60) * 100 + (timeOverwrite % 60);
      booking.timeOverwrite = timeOverwrite;
      booking.wait = option.value;
    }

    if (booking.option === "quick") {
      let quickOverwrite =
        parseInt(booking.time / 100) * 60 + (booking.time % 100) + option.value;
      quickOverwrite =
        parseInt(quickOverwrite / 60) * 100 + (quickOverwrite % 60);
      booking.quickOverwrite = quickOverwrite;
      booking.quick = option.value;
      booking.table = [option.table];
    }

    this.props.onNewBookingDetails(booking);
  };

  getWaitTime = (booking, this_tables_bookings) => {
    const table_bookings = this_tables_bookings.filter((b) => b.phase !== 3);
    const bookingTimeMins =
      parseInt(booking.time / 100) * 60 + (booking.time % 100);
    const bookingEndMins =
      parseInt(booking.usable_end_time / 100) * 60 +
      (booking.usable_end_time % 100);
    const turnTime = bookingEndMins - bookingTimeMins;
    for (let i = 0; table_bookings.length > i; i++) {
      let next = table_bookings[i].usable_end_time;
      let nextTimeMins = parseInt(next / 100) * 60 + (next % 100);
      let usable_end_time = nextTimeMins + turnTime;

      usable_end_time =
        parseInt(usable_end_time / 60) * 100 + (usable_end_time % 60);

      const tryBooking = clone(booking);
      tryBooking.time = next;
      tryBooking.usable_end_time = usable_end_time;

      let new_bookings = clone(table_bookings);
      new_bookings = new_bookings.filter(
        (booking) => booking.usable_end_time > next
      );
      if (!new_bookings)
        return { status: "wait", value: nextTimeMins - bookingTimeMins };

      const result = this.canSeat(tryBooking, new_bookings)[0];

      if (result.status === "available" || result.status === "ideal")
        return { status: "wait", value: nextTimeMins - bookingTimeMins };
    }
    return { status: "wait", value: null };
  };

  canSeat = (booking, this_tables_bookings, clear = false) => {
    const currently_sat = this_tables_bookings.filter(
      (b) =>
        b.phase === 2 ||
        (b.phase === 1 &&
          b.time <= booking.time &&
          b.usable_end_time > booking.time)
    );
    // function calculates if this table can sit the booking.
    // clear is used to clear phase 1 auto assigned bookings.
    // If it can be sat return either [available, ideal, avoid]
    // If it cannot be sat: return either [quickTurnTime, waitTime]
    if (currently_sat.length > 0)
      return [this.getWaitTime(booking, this_tables_bookings)];

    const this_tables_bookings_list = this_tables_bookings.filter(
      (booking) => booking.phase === 1
    );

    if (this_tables_bookings_list.length === 0)
      return [{ status: "available" }];
    if (this_tables_bookings_list[0].time <= booking.time)
      return [this.getWaitTime(booking, this_tables_bookings)];

    const bookingTimeMins =
      parseInt(booking.time / 100) * 60 + (booking.time % 100);
    const bookingEndMins =
      parseInt(booking.usable_end_time / 100) * 60 +
      (booking.usable_end_time % 100);
    const nextTimeMins =
      parseInt(this_tables_bookings_list[0].time / 100) * 60 +
      (this_tables_bookings_list[0].time % 100);

    if (this_tables_bookings_list[0].time < booking.usable_end_time) {
      const wait = this.getWaitTime(booking, this_tables_bookings);
      const quick = { status: "quick", value: nextTimeMins - bookingTimeMins };
      return [quick, wait];
    }
    if (this_tables_bookings_list[0].time === booking.usable_end_time)
      return [{ status: "ideal" }];

    // otherwise [0].time > booking.usable_end_time, so there's a gap. if bigger than turn time its av, if less it's avoid.

    const defaultTurnTime = 90;
    const gap = nextTimeMins - bookingEndMins;

    if (gap < defaultTurnTime) return [{ status: "avoid", value: gap }];

    return [{ status: "available" }];
  };

  handleTableStatus = () => {
    const tables = this.props.tables.filter(
      (table) => table.covers === this.props.sectionsize
    );

    const totalAvailable = [];
    const allWaitTimes = [];
    const allQuickTimes = [];

    const booking = clone(this.props.state.newBooking);
    this.setState({ booking });
    if (!booking || !booking.usable_end_time)
      return this.setState({ totalAvailable, allWaitTimes, allQuickTimes });

    tables.forEach((table) => {
      const this_tables_bookings = this.props.state.bookings.filter((booking) =>
        booking.table.includes(table.name)
      );

      const result = this.canSeat(booking, this_tables_bookings, false);
      result.forEach((result) => {
        const status = result.status;
        const value = result.value;

        if (
          status === "ideal" ||
          status === "available" ||
          status === "avoid"
        ) {
          totalAvailable.push(table);
        }

        if (status === "wait" && value < 1) {
          totalAvailable.push(table);
        } else if (status === "wait") {
          allWaitTimes.push({ table, value });
        }

        if (status === "quick") {
          allQuickTimes.push({ table, value });
          if (booking.quick <= value) totalAvailable.push(table);
        }
      });
    });

    allWaitTimes.sort((a, b) => (a.value > b.value ? 1 : -1));
    allQuickTimes.sort((a, b) => (a.value > b.value ? -1 : 1));
    this.setState({ totalAvailable, allWaitTimes, allQuickTimes });
  };

  componentDidUpdate = (prevProps) => {
    const booking = this.props.state.newBooking;
    if (
      booking.time !== this.state.booking.time ||
      booking.usable_end_time !== this.state.booking.usable_end_time ||
      booking.table.join() !== this.state.booking.table.join()
    ) {
      this.handleTableStatus();
    }

    const bookings = JSON.stringify(this.props.state.bookings);
    const prevBookings = JSON.stringify(prevProps.state.bookings);

    if (prevBookings !== bookings) {
      this.handleTableStatus();
    }
  };

  componentDidMount = () => {
    this.handleTableStatus();
  };

  render() {
    let tables = this.props.tables;
    const bookings = this.props.state.bookings.filter(
      (booking) => booking.phase < 3
    );
    let customTables = []; // {covers: 4, name: "C1", online: false, custom: true}

    bookings.forEach((booking) =>
      booking.table.forEach(
        (table) =>
          customTables.find((ct) => ct.name === table) === undefined &&
          customTables.push({ covers: 0, name: table, online: false })
      )
    );

    customTables = Array.from(new Set(customTables));

    customTables = customTables.filter(
      (custom) => !tables.find((table) => table.name === custom.name)
    );

    if (tables === undefined) {
      tables = [{ name: "t1", covers: "2" }];
    }

    let count = 0;
    this.props.state.selectedTables.forEach(
      (table) =>
        tables.find(
          (obj) => obj.covers === this.props.sectionsize && obj.name === table
        ) && count++
    );
    const totalAvailable = this.state.totalAvailable.length - count;

    if (this.props.view === "horizontal") {
      return (
        <React.Fragment>
          <td
            className={
              customTables.length === 0 && this.props.sectionsize === "C"
                ? "tablesection hidden"
                : "tablesection"
            }
          >
            {this.props.sectionsize}
          </td>
          {this.props.sectionsize === "C" &&
            customTables.map((table) => (
              <Table
                key={table.name}
                state={this.props.state}
                table={table}
                tableClass="table"
                onTableBookingView={this.props.onTableBookingView}
                onBookingManualTable={this.props.onBookingManualTable}
                view="horizontal"
              />
            ))}
          {}
          {tables
            .filter((table) => table.covers === this.props.sectionsize)
            .map((table) => (
              <Table
                key={table.name}
                state={this.props.state}
                table={table}
                tableClass="table"
                onTableBookingView={this.props.onTableBookingView}
                onBookingManualTable={this.props.onBookingManualTable}
                view="horizontal"
              />
            ))}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <table className="fulltables">
            <tbody>
              <tr>
                <td
                  className={
                    customTables.length === 0 && this.props.sectionsize === "C"
                      ? "horizontaltableheader hidden"
                      : "horizontaltableheader"
                  }
                >
                  <div
                    className={
                      totalAvailable < 2
                        ? totalAvailable < 1
                          ? "available red"
                          : "available orange"
                        : "available"
                    }
                    style={
                      this.props.state.addWalkIn === true
                        ? { display: "none" }
                        : { display: "none" }
                    }
                  >
                    {totalAvailable}
                  </div>
                  <div
                    className="buttons"
                    style={
                      this.props.state.addWalkIn === true
                        ? { display: "flex" }
                        : { display: "none" }
                    }
                  >
                    <button
                      className={
                        (this.state.totalAvailable.length !== 0
                          ? "yes"
                          : "no") +
                        (this.props.state.newBooking.option === "seat"
                          ? " selected"
                          : "")
                      }
                      onClick={() => this.setWalkInOption({ option: "seat" })}
                    >
                      <AirlineSeatReclineNormalIcon />
                      Seat
                    </button>
                    <button
                      className={
                        (this.state.allQuickTimes[0] ? "yes" : "no") +
                        (this.props.state.newBooking.option === "quick" &&
                        this.state.allQuickTimes[0] &&
                        this.props.state.newBooking.quick ===
                          this.state.allQuickTimes[0].value
                          ? " selected"
                          : "")
                      }
                      onClick={() =>
                        this.setWalkInOption({
                          option: "quick",
                          value: this.state.allQuickTimes[0].value,
                          table: this.state.allQuickTimes[0].table.name,
                        })
                      }
                    >
                      <DirectionsRunIcon />
                      {this.state.allQuickTimes[0] &&
                        this.state.allQuickTimes[0].value + "m"}
                    </button>
                    <button
                      className={
                        (this.state.allWaitTimes[0] ? "yes" : "no") +
                        (this.props.state.newBooking.option === "wait" &&
                        this.state.allWaitTimes[0] &&
                        this.props.state.newBooking.wait ===
                          this.state.allWaitTimes[0].value
                          ? " selected"
                          : "")
                      }
                      onClick={() =>
                        this.setWalkInOption({
                          option: "wait",
                          value: this.state.allWaitTimes[0].value,
                        })
                      }
                    >
                      <HourglassEmptyRoundedIcon />
                      {this.state.allWaitTimes[0] &&
                        this.state.allWaitTimes[0].value + "m"}
                    </button>
                  </div>
                  Tables of <b>{this.props.sectionsize}</b>
                </td>
              </tr>
              <tr>
                <td>
                  <table style={{ borderSpacing: 5 }}>
                    <tbody>
                      <tr>
                        {this.props.sectionsize === "C" &&
                          customTables.map((table) => (
                            <Table
                              totalAvailable={this.state.totalAvailable}
                              selectedTables={this.props.state.selectedTables}
                              tables={this.props.tables}
                              key={table.name}
                              state={this.props.state}
                              table={table}
                              tableClass="table"
                              onTableBookingView={this.props.onTableBookingView}
                              onBookingManualTable={
                                this.props.onBookingManualTable
                              }
                              view="horizontal"
                            />
                          ))}
                        {tables
                          .filter(
                            (table) => table.covers === this.props.sectionsize
                          )
                          .map((table) => (
                            <Table
                              totalAvailable={this.state.totalAvailable}
                              selectedTables={this.props.state.selectedTables}
                              tables={this.props.tables}
                              key={table.name}
                              onTableBookingView={this.props.onTableBookingView}
                              state={this.props.state}
                              table={table}
                              tableClass="table"
                              onToggleSelectTable={
                                this.props.onToggleSelectTable
                              }
                              view="selectTable"
                            />
                          ))}
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </React.Fragment>
      );
    }
  }
}

export default TableSection;
