import React, { Component } from 'react';
import Book from '@material-ui/icons/MenuBook';
import CreateIcon from '@material-ui/icons/Create';
import EmojiPeopleRoundedIcon from '@material-ui/icons/EmojiPeopleRounded';
import SettingsBackupRestoreRoundedIcon from '@material-ui/icons/SettingsBackupRestoreRounded';
import Options from './options';
import ReportRoundedIcon from '@material-ui/icons/ReportRounded';
import './../css/navbar.css';

class NavBar extends Component {
  render() {
    const dateFormat = require('dateformat');
    const dayOfWeek = dateFormat(this.props.state.displayDate, 'dddd');
    const fullDate = dateFormat(this.props.state.displayDate, 'dd mmm yy');

    const displayDate = new Date(this.props.state.displayDate);
    let today = new Date();
    today = new Date(today.setHours(0, 0, 0, 0));
    let resetButton = '';
    if (displayDate.valueOf() !== today.valueOf()) {
      resetButton = (
        <button
          className="navbar-today"
          onClick={this.props.onResetDate.bind(null, today)}
        >
          <SettingsBackupRestoreRoundedIcon />
        </button>
      );
    }
    return (
      <nav className="navbar">
        <table>
          <tbody>
            <tr>
              <td className="icon" style={{ position: 'relative' }}>
                <Options
                  state={this.props.state}
                  onSaveScheduleEdit={this.props.onSaveScheduleEdit}
                  onSaveTablePlanEdit={this.props.onSaveTablePlanEdit}
                  onSaveOpeningHours={this.props.onSaveOpeningHours}
                  onChangeRestaurant={this.props.onChangeRestaurant}
                  logout={this.props.logout}
                />
              </td>
              <td className="navmiddle">
                <button
                  className="calendarbutton"
                  onClick={this.props.onToggleCalendar}
                >
                  <p className="dayofweek">{dayOfWeek}</p>
                  <p>{fullDate}</p>
                </button>
                {resetButton}
              </td>
              <td className="icon">
                <div
                  className={
                    this.props.state.addWalkIn
                      ? 'add-booking selected'
                      : 'add-booking'
                  }
                >
                  <EmojiPeopleRoundedIcon
                    className="icon"
                    onClick={this.props.onToggleAddWalkIn}
                  />
                </div>
                <div
                  className={
                    this.props.state.addBooking
                      ? 'add-booking selected'
                      : 'add-booking'
                  }
                >
                  {this.props.state.editBooking ? (
                    <CreateIcon
                      className="icon"
                      onClick={this.props.onToggleAddBooking}
                    />
                  ) : (
                    <Book
                      className="icon"
                      onClick={this.props.onToggleAddBooking}
                    />
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          {this.props.state.firstRestaurant &&
            this.props.state.restaurant._id !==
              this.props.state.firstRestaurant && (
              <div
                className="nav-warning"
                onClick={this.props.onChangeRestaurant}
              >
                <ReportRoundedIcon />{' '}
                <span className="long">
                  Showing {this.props.state.restaurant.name}, tap here to
                  change.
                </span>
                <span className="short">
                  {this.props.state.restaurant.name}
                </span>
              </div>
            )}
        </div>
      </nav>
    );
  }
}

export default NavBar;
