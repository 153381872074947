import React, { Component } from "react";
import PostAddIcon from "@material-ui/icons/PostAdd";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";

class OpeningHours extends Component {
  state = { selectedPacing: null };
  setSelectedPacing = (selectedPacing, e) => {
    if (this.state.selectedPacing === selectedPacing) {
      this.setState({ selectedPacing: null });
    } else {
      this.setState({ selectedPacing });
    }
    e.stopPropagation();
  };
  buildPacing = (pacing) => {
    let output = (
      <div className="schedule-container table-plan" key={pacing._id}>
        <div className="schedule-overview">
          <table>
            <tbody>
              <tr>
                <td>{pacing.name}</td>
                <td>
                  <div
                    className="schedule-modify-button"
                    onClick={this.props.onEdit.bind(this, {
                      _id: pacing._id,
                      duplicate: true,
                    })}
                  >
                    <FileCopyOutlinedIcon />
                    duplicate
                  </div>{" "}
                  <div
                    className="schedule-modify-button"
                    onClick={this.props.onEdit.bind(this, {
                      _id: pacing._id,
                      duplicate: false,
                    })}
                  >
                    <EditIcon />
                    edit
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
    return output;
  };
  render() {
    return (
      <React.Fragment>
        <div className="options-title">Opening Hours</div>
        <div
          style={{
            display: "flex",
            width: "94%",
            justifyContent: "space-between",
          }}
        >
          <div className="schedule-new-button" onClick={this.props.onBack}>
            <ArrowBackRoundedIcon />
            back
          </div>
          <div
            className="schedule-new-button"
            onClick={this.props.onEdit.bind(this, {
              _id: "new",
              duplicate: false,
            })}
          >
            <PostAddIcon />
            new opening hours
          </div>
        </div>
        {this.props.state.pacingsSchedules.map((pacing) =>
          this.buildPacing(pacing)
        )}
      </React.Fragment>
    );
  }
}

export default OpeningHours;
