import { useState } from 'react';
import styles from '../css/PrintableView.module.css';

export default function PrintableView({
  show,
  bookings,
  tags,
  services,
  toggleVisible,
}) {
  const [hiddenServices, setHiddenServices] = useState([]);
  if (!show) return null;

  const printableServices = services.filter(
    (s) => !hiddenServices.includes(s.name)
  );

  return (
    <div className={`${styles.blackout} printable`}>
      <div className={`${styles.container} printable`}>
        <button onClick={() => toggleVisible()}>Close</button>
        <button onClick={() => window.print()} style={{ marginRight: 30 }}>
          Print
        </button>
        {services.map((service) => {
          const selected = !hiddenServices.includes(service.name);
          return (
            <button
              key={service.name}
              className={selected ? undefined : styles.deselected}
              onClick={() =>
                selected
                  ? setHiddenServices([service.name, ...hiddenServices])
                  : setHiddenServices(
                      hiddenServices.filter((s) => s !== service.name)
                    )
              }
            >
              {service.name}
            </button>
          );
        })}
        <p>
          <b>Top tip</b>
          <br /> Booking notes are greyed out and will not print. Simply tap on
          a note to turn it black & have it print.
        </p>
        {printableServices.map((service) => (
          <Service
            key={service.name}
            service={service}
            services={services}
            bookings={bookings}
            tags={tags}
          />
        ))}
      </div>
    </div>
  );
}

const Service = ({ service, services, bookings, tags }) => {
  const [visibleNotes, setVisibleNotes] = useState([]);
  const start = service.time ?? 0;
  const next = services.indexOf(service) + 1;
  const end = services[next] ? services[next].time : 9999;
  const filteredBookings = bookings.filter(
    (b) => b.time >= start && b.time < end
  );

  return (
    <div className="printable" style={{ marginBottom: 10 }}>
      {service.name}
      {filteredBookings.map((b) => (
        <Booking
          key={b._id}
          b={b}
          tags={tags}
          visibleNotes={visibleNotes}
          setVisibleNotes={setVisibleNotes}
        />
      ))}
      {filteredBookings.length === 0 && (
        <>
          <br className="printable" />
          <i className="printable">No bookings</i>
        </>
      )}
    </div>
  );
};

const Tag = ({ t, tags }) => {
  return (
    <span className={`${styles.tag} printable`}>
      {tags.find((tag) => tag._id === t).text}
    </span>
  );
};

const Booking = ({ b, tags, visibleNotes, setVisibleNotes }) => {
  const visible = visibleNotes.includes(b._id);
  if (b.phase > 1) return null;
  return (
    <div key={b._id} className={`${styles.booking} printable`}>
      <span className={`${styles.time} printable`}>{b.time}</span>
      <span className={styles.covers}>{b.covers}</span>
      <span className={styles.details}>
        {b.name}
        <div
          className={
            visible
              ? `${styles.notes} printable`
              : `${styles.hiddenNotes} ${styles.notes}`
          }
          onClick={() =>
            setVisibleNotes(
              visible
                ? visibleNotes.filter((n) => n !== b._id)
                : [...visibleNotes, b._id]
            )
          }
        >
          {b.tags.map((t) => (
            <>
              <Tag t={t} tags={tags} key={t._id} />
            </>
          ))}
          {b.description}
        </div>
      </span>
    </div>
  );
};
