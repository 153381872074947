import React, { Component } from "react";
import PostAddIcon from "@material-ui/icons/PostAdd";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";

class TablePlans extends Component {
  state = { selectedTablePlan: null };
  setSelectedTablePlan = (selectedTablePlan, e) => {
    if (this.state.selectedTablePlan === selectedTablePlan) {
      this.setState({ selectedTablePlan: null });
    } else {
      this.setState({ selectedTablePlan });
    }
    e.stopPropagation();
  };
  buildTablePlan = (tablePlan) => {
    const clone = require("rfdc")();
    let duplicate = clone(tablePlan);
    duplicate._id = "duplicate";
    let output = (
      <div className="schedule-container table-plan" key={tablePlan._id}>
        <div className="schedule-overview">
          <table>
            <tbody>
              <tr>
                <td>{tablePlan.name}</td>
                <td>
                  <div
                    className="schedule-modify-button"
                    onClick={this.props.onEdit.bind(this, duplicate)}
                  >
                    <FileCopyOutlinedIcon />
                    duplicate
                  </div>{" "}
                  <div
                    className="schedule-modify-button"
                    onClick={this.props.onEdit.bind(this, tablePlan)}
                  >
                    <EditIcon />
                    edit
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
    return output;
  };
  render() {
    return (
      <React.Fragment>
        <div className="options-title">Table Plans</div>
        <div
          style={{
            display: "flex",
            width: "94%",
            justifyContent: "space-between",
          }}
        >
          <div className="schedule-new-button" onClick={this.props.onBack}>
            <ArrowBackRoundedIcon />
            back
          </div>
          <div
            className="schedule-new-button"
            onClick={this.props.onEdit.bind(this, { _id: "new" })}
          >
            <PostAddIcon />
            new table plan
          </div>
        </div>
        {this.props.state.tablesSchedules.map((tablePlan) =>
          this.buildTablePlan(tablePlan)
        )}
      </React.Fragment>
    );
  }
}

export default TablePlans;
